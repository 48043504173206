import React from 'react';
import {
  PageHeroContainer,
  PageHeroMask,
  PageHeroTitle,
} from './PageStyles';


class PageHero extends React.Component {
  render() {
    return (
      <PageHeroContainer style={{backgroundImage: `url(${this.props.hero})`}}>
        <PageHeroMask opacity={10} />
        <PageHeroTitle>{this.props.title}</PageHeroTitle>
      </PageHeroContainer>
    )
  }
}

export default PageHero;
