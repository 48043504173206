export const colors = {
  dark: '#121318',
  light: '#FDFAFF',
  midgray: '#646871',
  lightgray: '#f1f1f1',
  purple: '#6C52FB',
  purple2: '#7B72A3',
  purple3: '#684DB9',
  purple4: '#33209A',
  lilac: '#FAF1FC',
  red: '#E13B45'
}

export const gradients = {
  soft: 'linear-gradient(45deg, #EFC1F3, #96D0E2)',
  heavy: 'linear-gradient(45deg, #725ADD, #5AB6DD)',
  midnight: 'linear-gradient(45deg, #655B8F, #31255A)',
  purple: 'linear-gradient(rgba(108, 82, 251, 1), rgba(108, 82, 251, 0.5))',
  purpleBlue: 'linear-gradient(to right, #7761FF 0 20%, #8C78FF 60%, #83CBFF)'
}
