import React, { useEffect, useState } from 'react'
import { client, linkResolver } from '../prismic-configuration'
import * as QueryString from "query-string";

import Embed from '../components/nft/Embed';

const NftEmbedDemo = ({ location }) => {
  return (
    <div style={{
      'padding': 40
    }}>
      <p>
        <p style={{marginTop: 40}}>
          NFT has not been offset:<br/>
          <div style={{
            fontFamily: 'Source Code Pro',
            background: '#FAF1FC',
            padding: 4,
            borderRadius: 4,
            color: '#6C52FB',
            fontSize: 12,
            display: 'inline-block',
            margin: '8px 0 16px'
          }}>
          {`<iframe src="https://www.aerial.is/nft/embed?address=0x1D9B23e9A2d1185429206E2eB572bd70ED8dca60">`}
          </div>
          <Embed address="0x1D9B23e9A2d1185429206E2eB572bd70ED8dca60" />
        </p>
        NFT has been offset:<br/>
        <div style={{
          fontFamily: 'Source Code Pro',
          background: '#FAF1FC',
          padding: 4,
          borderRadius: 4,
          color: '#6C52FB',
          fontSize: 12,
          display: 'inline-block',
          margin: '8px 0 16px'
        }}>
        {`<iframe src="https://www.aerial.is/nft/embed?address=0x3e88721fa41d5e102d54b4a04e550222efdd234d">`}
        </div>
        <Embed address="0x3e88721fa41d5e102d54b4a04e550222efdd234d" />
      </p>
      <p style={{marginTop: 40}}>
        NFT has not been offset (dark theme):<br/>
        <div style={{
          fontFamily: 'Source Code Pro',
          background: '#FAF1FC',
          padding: 4,
          borderRadius: 4,
          color: '#6C52FB',
          fontSize: 12,
          display: 'inline-block',
          margin: '8px 0 16px'
        }}>
        {`<iframe src="https://www.aerial.is/nft/embed?address=0x1D9B23e9A2d1185429206E2eB572bd70ED8dca60&theme=dark">`}
        </div>
        <Embed address="0x1D9B23e9A2d1185429206E2eB572bd70ED8dca60" theme="dark" />
      </p>
      <p style={{marginTop: 40}}>
        NFT has been offset (dark theme):<br/>
        <div style={{
          fontFamily: 'Source Code Pro',
          background: '#FAF1FC',
          padding: 4,
          borderRadius: 4,
          color: '#6C52FB',
          fontSize: 12,
          display: 'inline-block',
          margin: '8px 0 16px'
        }}>
        {`<iframe src="https://www.aerial.is/nft/embed?address=0x3e88721fa41d5e102d54b4a04e550222efdd234d&theme=dark">`}
        </div>
        <Embed address="0x3e88721fa41d5e102d54b4a04e550222efdd234d" theme="dark" />
      </p>
    </div>
  )
}

export default NftEmbedDemo
