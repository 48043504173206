import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H3, H4, H5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import economist from '../../assets/images/economist.svg';


function template(i, items, duration) {
  return `
      &:nth-child(${i + 1}) {
        transition-delay: ${`${(duration / items) * i}s`};
       }
    `
}

function getDelays(items, duration) {
  let str = ''
  for (let i = 0; i < items; i += 1) {
    str += template(i, items, duration)
  }
  return str
}


export const PressContainer = styled.div`
  overflow: hidden;
  transition: opacity .3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px 0;
  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }
  @media ${breakpoints.iPhone} {
    padding: 64px 0 32px 0;
  }
`;

export const PressTitle = styled(H5)`
  color: ${colors.midgray};
  position: relative;
  z-index: 2;

  opacity: 0;
  transform: translate(0, 10%);
  transition: opacity 1s ease, transform 1s ease;

  ${PressContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const PressLogos = styled.div`
  display: flex;
  margin-top: 32px;
  justify-content: center;

  @media ${breakpoints.laptop} {
    flex-wrap: wrap;
    padding: 0 40px;
  }

  @media ${breakpoints.iPadW} {
    flex-wrap: wrap;
    padding: 0;
  }
`;

export const PressLogo = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 110px;
  height: 45px;
  margin: 0 16px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  ${getDelays(12, 1)};

  ${PressContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.laptop} {
    margin: 8px;

  }

  @media ${breakpoints.iPadW} {
    width: 110px;
    height: 40px;
    margin: 8px;
  }

  @media ${breakpoints.iPadH} {
    width: 90px;
    height: 30px;
    margin: 8px;
  }

  @media ${breakpoints.iPhone} {
    width: 100px;
    height: 40px;
    margin: 8px 16px;
  }
`;
