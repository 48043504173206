import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { GoogleOAuthProvider } from '@react-oauth/google';

ReactDOM.render(
  <GoogleOAuthProvider clientId="441800615668-b2ncc39s9m2g0t5dufqgt1bsmn2n1uq9.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,
  document.getElementById('root')
)
