import React from 'react';

// @function  UserContext
const TeamContext = React.createContext({
  id: null,
  name: "",
  admin: false,
  admins: 0,
  shortcode: "",
  members: [],
  credits: 0
});

export default TeamContext;
