import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import VisibilitySensor from "react-visibility-sensor";
import Tilt from 'react-tilt';
import {
  ProjectContainer,
  ProjectIntro,
  ProjectOuter,
  ProjectInner,
  ProjectLeft,
  ProjectAssetContainer,
  ProjectVideo,
  ProjectPicture,
  ProjectLabel,
  ProjectParallax,
  ProjectInfo,
  ProjectContent,
  ProjectContentInner,
  ProjectTitle,
  ProjectHeader,
  ProjectSubtitle,
  ProjectHeader2,
  ProjectCopy,
  ProjectLocation,
  ProjectDescription,
  ProjectVerifications,
  ProjectVerification
} from './ProjectStyles';


class Project extends React.Component {
  state = {
    appear: false
  }

  render() {
    return (
      <VisibilitySensor
        key={'app'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/4}}
        onChange={isVisible => isVisible && setTimeout(() => {this.setState({appear: true})}, this.props.delay || 0)}>
        <ProjectContainer className={(this.state.appear ? 'appear' : '') + (this.props.home ? ' home': '')} name="project">
            <ProjectIntro>
              <ProjectHeader>Verified Projects</ProjectHeader>
              <ProjectSubtitle>Aerial works with verified environmental efforts globally to remove carbon emissions.</ProjectSubtitle>
            </ProjectIntro>
            <ProjectOuter>
              {this.props.projects && this.props.projects.map((project, index) => {
                return (
                  <ProjectInner>
                    <ProjectLeft>
                      <ProjectAssetContainer>
                        <ProjectParallax y={[10, -10]}>
                          <ProjectPicture style={{backgroundImage: `url(${project.project_image.url})`}} />
                        </ProjectParallax>
                        <ProjectLabel>{PrismicReact.RichText.asText(project.project_type)}</ProjectLabel>
                      </ProjectAssetContainer>
                    </ProjectLeft>
                    <ProjectContent>
                      <ProjectContentInner>
                        <ProjectHeader2>{PrismicReact.RichText.asText(project.project_title)}</ProjectHeader2>
                        <ProjectLocation>{PrismicReact.RichText.asText(project.project_location)}</ProjectLocation>
                        <ProjectCopy>{PrismicReact.RichText.asText(project.project_description)}</ProjectCopy>
                      </ProjectContentInner>
                    </ProjectContent>
                  </ProjectInner>
                  );
                })}
            </ProjectOuter>
            <ProjectVerifications>
              <ProjectVerification className="goals" />
              <ProjectVerification className="acr" />
              <ProjectVerification className="verra" />
              <ProjectVerification className="car" />
            </ProjectVerifications>
        </ProjectContainer>
      </VisibilitySensor>
    )
  }
}

export default Project;
