import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import VisibilitySensor from "react-visibility-sensor";
import {
  SignupContainer,
  SignupInner,
  SignupRight,
  SignupHeader,
  SignupInputContainer,
  SignupInput,
  SignupAssetContainer,
  SignupParallax,
  SignupVideo,
  SignupButton
} from './SignupStyles';


class Signup extends React.Component {
  state = {
    appear: false,
    phone: "",
    submitted: false
  }

  submit() {
    fetch('/_sendInvite?phone=' + this.state.phone, {
      method: 'POST'
    })
    this.setState({
      phone: 'Sent!',
      submitted: true
    })
  }

  render() {
    return (
      <VisibilitySensor
        key={'signup'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/4}}
        onChange={isVisible => isVisible && this.setState({appear: true})}>
        <SignupContainer className={this.state.appear ? 'appear' : ''} name="project">
            <SignupInner>
                <SignupAssetContainer>
                  <SignupParallax y={[10, -10]}>
                    <SignupVideo className="desktop" playsinline={true} muted={true} playing={true} loop url="https://storage.googleapis.com/aerial-app.appspot.com/aerial_illustration_illu_landscape.mp4" />
                    <SignupVideo className="mobile" playsinline={true} muted={true} playing={true} loop url="https://storage.googleapis.com/aerial-app.appspot.com/aerial_illustration_illu_landscape_compress.mp4" />
                  </SignupParallax>
                </SignupAssetContainer>
                <SignupRight>
                  <SignupHeader>Get the App</SignupHeader>
                  <SignupInputContainer className={this.state.submitted ? 'submitted' : ''}>
                      <SignupInput
                        placeholder={this.state.submitted ? "Sent!" : "Your Phone Number"}
                        defaultCountry="US"
                        value={this.state.phone}
                        onChange={phone => this.setState({ phone })}
                        disabled={this.state.submitted} />
                    <SignupButton onClick={this.submit.bind(this)}></SignupButton>
                  </SignupInputContainer>
                </SignupRight>
            </SignupInner>
        </SignupContainer>
      </VisibilitySensor>
    )
  }
}

export default Signup;
