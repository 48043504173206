import React, { useEffect, useState, useContext } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link, Redirect } from 'react-router-dom';
import UserContext from '../../UserContext';
import TeamContext from '../../TeamContext';
import {
  HeaderUserContainer,
  HeaderUserInfo,
  HeaderUserName,
  HeaderUserEmail,
  HeaderUserPicture,
  HeaderUserMenu,
  HeaderUserMenuItems,
  HeaderUserMenuItem
} from './HeaderStyles';


const HeaderUser = () => {
  const { user, logout, showSettings } = useContext(UserContext);
  const { team } = useContext(TeamContext);
  const [opened, setOpened] = useState(false)

  return user.loggedIn && (
    <HeaderUserContainer className={opened ? "opened" : ""}>
      <HeaderUserMenu>
        <HeaderUserMenuItems>
          {!!team.name && team.admin &&
            <HeaderUserMenuItem to="/teams">Team Dashboard</HeaderUserMenuItem>
          }
          <HeaderUserMenuItem to="/footprint">My Footprint</HeaderUserMenuItem>
          <HeaderUserMenuItem onClick={() => {setOpened(false); showSettings(true)}}>Settings</HeaderUserMenuItem>
          <HeaderUserMenuItem onClick={() => {setOpened(false); logout()}}>Log Out</HeaderUserMenuItem>
        </HeaderUserMenuItems>
      </HeaderUserMenu>
      <HeaderUserInfo onClick={() => setOpened(!opened)}>
        <HeaderUserPicture style={{backgroundImage: `url(${user.picture})`}} />
        <HeaderUserName>
          {user.name}
          <HeaderUserEmail>
            {user.email}
          </HeaderUserEmail>
        </HeaderUserName>
      </HeaderUserInfo>
    </HeaderUserContainer>
  )
}

export default HeaderUser;
