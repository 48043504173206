import React, { useEffect, useState, useCallback } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';

import {useAppBridge} from '@shopify/app-bridge-react';
import {Redirect} from '@shopify/app-bridge/actions';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  NftContainer,
  NftSteps,
  NftResultContainer,
  NftStep,
  NftInner,
  NftTitleContainer,
  NftTitle,
  NftAddress,
  NftStats,
  NftStat,
  NftStatNumber,
  NftStatLabel,
  NftStatUnit,
  NftLeft,
  NftRight,
  NftOffsetContainer,
  NftOffsetInner,
  NftOffsetCredits,
  NftOffsetLabel,
  NftOffsetCopy,
  NftPaymentOptions,
  NftPaymentOption,
  NftPaymentCost,
  NftPaymentMethod,
  NftCheckoutButton,
  NftCryptoButton,
  NftMethod,
  NftMethodTitle,
  NftMethodCopy
} from './NftStyles';

const fetchEmissions = async (host, shop) => {
    return fetch('/_shopifyEmissions' + window.location.search)
      .then(res => res.json())
}

const createCharge = async (host, shop, credits) => {
    return fetch('/_shopifyOffset' + window.location.search + '&credits=' + credits, { method: 'POST' })
      .then(res => res.json())
}

const NftShopify = ({ location }) => {
  const app = useAppBridge();
  const redirect = Redirect.create(app);

  const urlParams = new URLSearchParams(location.search);
  const [appear, setAppear] = useState(false)
  const [success, setSuccess] = useState(null)
  const [creditsRequired, setCreditsRequired] = useState(0)
  const [data, setData] = useState({
    co2: 0,
    gas: 0,
    transactions: 0,
    credits_purchased: 0,
    credits: 0
  })

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchEmissions(urlParams.get('host'), urlParams.get('shop'));
      setData(data)
      setSuccess(data.credits == data.credits_purchased)
      setCreditsRequired(data.credits - data.credits_purchased)
      setAppear(true)
    }
    fetchData()
  }, [])

  const checkout = useCallback(async () => {
    const { confirmation_url } = await createCharge(urlParams.get('host'), urlParams.get('shop'), creditsRequired)

    redirect.dispatch(Redirect.Action.REMOTE, confirmation_url);
  })

  return <NftContainer className={(appear ? 'appear' : '') + ' result'}>
      <NftSteps>
        <NftStep className="input"></NftStep>
        <NftStep className="result">
          <NftInner className={appear ? 'appear' : ''}>
            <NftResultContainer>
              <NftLeft>
                <NftTitleContainer>
                {success == true && data.credits_purchased > 0 &&
                  <NftTitle>
                    {isMobile ?
                      <ByWord text="Successfully Offset!" />
                    : <ByLetter text="Successfully Offset!" />}
                  </NftTitle>}
                {(success != null && (success == false || data.credits == 0)) &&
                  <NftTitle>
                    {isMobile ?
                      <ByWord text="Your NFT Footprint" />
                    : <ByLetter text="Your NFT Footprint" />}
                  </NftTitle>
                }
                </NftTitleContainer>
                <NftStats>
                  <NftStat>
                    {data.co2 > 1000 ?
                      <NftStatNumber>
                        <CountUp end={data.co2/1000} duration={1} />K
                      </NftStatNumber> :
                      <NftStatNumber>
                        <CountUp end={data.co2} duration={1} />
                      </NftStatNumber>
                    }
                    <NftStatLabel>CO2 Emissions</NftStatLabel>
                    <NftStatUnit>KG</NftStatUnit>
                  </NftStat>
                  <NftStat>
                      {data.gas > 1000000 ?
                        <NftStatNumber>
                          <CountUp start={data.gas/1000000 - 20} end={data.gas/1000000} duration={2} />M
                        </NftStatNumber> :
                        <NftStatNumber>
                          <CountUp end={data.gas/1000} duration={2} />K
                        </NftStatNumber>
                      }
                    <NftStatLabel>Gas Used</NftStatLabel>
                    <NftStatUnit>Units</NftStatUnit>
                  </NftStat>
                  <NftStat>
                    <NftStatNumber><CountUp end={data.transactions} duration={3} /></NftStatNumber>
                    <NftStatLabel>Transactions</NftStatLabel>
                  </NftStat>
                </NftStats>
                <NftMethod>
                  <NftMethodTitle>
                    How is this calculated?
                  </NftMethodTitle>
                  <NftMethodCopy>
                    Emissions are estimated based on the gas used for Ethereum and BSC transactions. For inquiries please contact us at <a href="mailto:nft@aerial.is">nft@aerial.is</a>.
                  </NftMethodCopy>
                </NftMethod>
              </NftLeft>
              <NftRight>
                <NftOffsetContainer className={appear ? 'appear' : ''}>
                  {success && data.credits_purchased > 0 ?
                    <NftOffsetInner className="success">
                      <NftOffsetCredits>{data.credits_purchased}</NftOffsetCredits>
                      <NftOffsetLabel>Carbon Credits Purchased</NftOffsetLabel>
                    </NftOffsetInner> :
                    <NftOffsetInner>
                      <NftOffsetCredits>{creditsRequired}</NftOffsetCredits>
                      <NftOffsetLabel>Carbon Credits Required</NftOffsetLabel>
                      <NftOffsetCopy>Securely purchase verified carbon credits to offset your total NFT footprint.</NftOffsetCopy>
                      {data.credits > 0 &&
                        <div>
                        <NftPaymentOptions>
                          <NftPaymentOption style={{'width': '100%', 'margin': 0}}>
                            <NftPaymentCost>${creditsRequired * 11}</NftPaymentCost>
                            <NftPaymentMethod>Pay with Shopify</NftPaymentMethod>
                          </NftPaymentOption>
                        </NftPaymentOptions>
                        <NftCheckoutButton onClick={checkout}>
                          Offset
                        </NftCheckoutButton>
                        </div>
                      }
                    </NftOffsetInner>
                  }
                </NftOffsetContainer>
                {data.credits_purchased > 0 && success == false &&
                  <NftOffsetContainer className={(appear && data.credits_purchased > 0 ? "appear" : "") + ' purchased'} style={{'margin-top': 40}}>
                      <NftOffsetInner className="success">
                        <NftOffsetCredits>{data.credits_purchased}</NftOffsetCredits>
                        <NftOffsetLabel>Carbon Credits Purchased</NftOffsetLabel>
                      </NftOffsetInner>
                  </NftOffsetContainer>
                }
              </NftRight>
            </NftResultContainer>
          </NftInner>
        </NftStep>
      </NftSteps>
    </NftContainer>
}



export default withRouter(NftShopify);
