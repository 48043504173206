import React from 'react';
import { CSSTransition } from 'react-transition-group';
import {
  BlobContainer,
  BlobShape
} from './BlobStyles';


class Blob extends React.Component {
  state = {
    appear: true
  }

  render() {
    return (
      <CSSTransition in={this.state.appear} timeout={200} classNames="onload" unmountOnExit appear>
        <BlobContainer>
          <BlobShape/>
        </BlobContainer>
      </CSSTransition>
    )
  }
}

export default Blob;
