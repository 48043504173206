import styled from "styled-components";
import { colors, gradients } from "../../styles/Colors";
import {
  fonts,
  fontWeights,
  H4,
  h2,
  H3,
  h3,
  H5,
  P,
} from "../../styles/Typography";
import { breakpoints } from "../../styles/Breakpoints";
import { panBackground } from "../../styles/Animations";
import clouds from "../../assets/images/clouds.png";
import gradient from "../../assets/images/gradient.png";
import screenshot1 from "../../assets/images/screenshots/1.png";
import screenshot2 from "../../assets/images/screenshots/2.png";
import screenshot3 from "../../assets/images/screenshots/3.png";
import appstorePurple from "../../assets/images/appstore_purple.svg";
import googleplayPurple from "../../assets/images/google-play-outline-purple.svg";

export const AppContainer = styled.div`
  transition: opacity 0.3s ease;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  background: ${gradients.soft};
  /* background-image: url(${gradient}); */
  background-size: contain;
  padding-top: 120px;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      rgba(253, 250, 255, 0.65),
      rgba(253, 250, 255, 0)
    );
    z-index: 0;
  }

  @media ${breakpoints.iPadW} {
    padding-top: 80px;
  }

  @media ${breakpoints.iPhone} {
    padding-top: 32px;
  }
`;

export const AppContent = styled.div`
  max-width: 600px;
  z-index: 2;
  align-items: center;
  text-align: center;

  @media ${breakpoints.wide} {
    padding-bottom: 32px;
  }
  @media ${breakpoints.iPhone} {
    padding: 64px 32px;
  }
`;

export const AppTour = styled.div`
  display: flex;
  height: 200vh;
  width: 100%;
  max-width: 900px;
  perspective: 1000px;
  z-index: 2;

  @media ${breakpoints.iPadW} {
    max-width: 700px;
  }

  @media ${breakpoints.iPhone} {
    flex-direction: column;
    min-width: 0;
    height: 250vh;
  }
`;

export const AppTourDots = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  left: -40px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0;

  ${AppContainer}.appear & {
    opacity: 1;
  }

  @media ${breakpoints.iPadH} {
    left: -20px;
  }

  @media ${breakpoints.iPhone} {
    left: 24px;
  }
`;

export const AppTourDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 6px;
  margin: 6px;
  background: ${colors.purple};
  opacity: 0.5;

  &.active {
    opacity: 1;
  }
`;

export const AppCloudsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const AppClouds = styled.div`
  position: relative;
  position: sticky;
  z-index: 2;
  top: 0;
  width: 100%;
  height: 100vh;
  background-image: url(${clouds});
  background-size: cover;

  animation: ${panBackground} 60s linear infinite;
  pointer-events: none;

  @media ${breakpoints.iPhone} {
    animation: ${panBackground} 30s linear infinite;
  }
`;

export const AppPhoneContainer = styled.div`
  display: flex;
  width: 50%;
  height: calc(100vh - 120px);
  z-index: 2;
  position: relative;
  position: sticky;
  top: 100px;
  margin-bottom: 80px;
  margin-right: 64px;

  opacity: 0;
  transform: translate(0, 10%);
  transition:
    opacity 1s ease,
    transform 1s ease;
  transition-delay: 0.3s;

  ${AppContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadW} {
    margin-right: 16px;
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
    height: 65vh;
    top: 80px;
    z-index: 3;
    margin-bottom: 15vh;
  }
`;

export const AppPhone = styled.div`
  position: relative;
  width: 100%;
  padding-top: 100%;
`;

export const AppPhoneScreenshot = styled.div`
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity .3s ease;

  &.slide-1 {
    background-image: url(${screenshot1});
  }
  &.slide-2 {
    background-image: url(${screenshot2});
  }
  &.slide-3 {
    background-image: url(${screenshot3});
  }

  ${AppPhoneContainer}.slide-1 & {
    &.slide-1 {
      opacity: 1;
    }
  }
  ${AppPhoneContainer}.slide-2 & {
    &.slide-2 {
      opacity: 1;
    }
    @media ${breakpoints.iPhone} {
      &.slide-1 {
        opacity: 1;
      }
    }
  }
  ${AppPhoneContainer}.slide-3 & {
    &.slide-3 {
      opacity: 1;
    }
    @media ${breakpoints.iPhone} {
      &.slide-1 {
        opacity: 1;
      }
      &.slide-2 {
        opacity: 1;
      }
    }
  }
}
`;

export const AppPhoneContent = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 2;

  @media ${breakpoints.iPhone} {
    width: 100%;
  }
`;

export const AppTitle = styled(H5)`
  color: ${colors.purple2};
  position: relative;
  z-index: 2;

  opacity: 0;
  transform: translate(0, 10%);
  transition:
    opacity 1s ease,
    transform 1s ease;

  ${AppContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const AppHeader = styled.div`
  position: relative;
  div {
    ${h2}
    span {
      color: ${colors.purple3};
    }
    @media ${breakpoints.iPadW} {
      ${h3}
    }
    @media ${breakpoints.iPhone} {
      ${h3}
    }
  }
`;

export const AppCopy = styled(P)`
  color: ${colors.purple2};

  opacity: 0;
  transform: translate(0, 10%);
  transition:
    opacity 1s ease,
    transform 1s ease;
  transition-delay: 0.2s;

  ${AppContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const AppSlideContent = styled.div`
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  transform: scale(0.95) perspective(1000px) rotateX(20deg)
    translate3d(0, 0, -100px);
  transition: transform 1s ease;

  &.appear {
    transform: scale(1) rotate(0) translate3d(0, 0, 0);
  }

  @media ${breakpoints.iPhone} {
    margin: 0 40px;
    justify-content: flex-start;
    transform: none;
    transition: none;
  }
`;

export const AppSlideTitle = styled(H3)`
  color: ${colors.purple3};

  opacity: 0;
  transform: translate(0, 10%);
  transition: all 0.3s ease;

  ${AppSlideContent}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    transform: translate(0, 0);
    transition: opacity 0.1s ease;
    position: sticky;
    top: calc(75vh + 16px);
    text-align: center;
    margin: 0 40px;
    margin-bottom: 32px;

    ${AppSlideContent}.appear + div & {
      opacity: 1;
    }

    ${AppSlideContent}:last-child & {
      opacity: 1;
    }
  }
`;

export const AppSlideCopy = styled(P)`
  color: ${colors.purple2};
  font-size: 18px;
  opacity: 0;
  transform: translate(0, 10%);
  transition: all 0.3s ease;
  transition-delay: 0.1s;

  ${AppSlideContent}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
`;

export const AppStoreLinks = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;

  opacity: 0;
  transform: translate(0, -10%);
  transition:
    opacity 1s ease,
    transform 1s ease;
  transition-delay: 0.4s;

  ${AppContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const AppStoreLink = styled.a``;

export const AppStoreIcon = styled.div`
  background-image: url(${appstorePurple});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 40px;
  transition: background 0.3s ease;

  @media ${breakpoints.iPhone} {
    width: 100px;
    height: 36px;
  }
`;

export const AndroidLink = styled.a`
  margin-left: 16px;

  @media ${breakpoints.iPhone} {
    margin-left: 8px;
  }
`;

export const AndroidIcon = styled.div`
  background-image: url(${googleplayPurple});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 135px;
  height: 40px;
  transition: background 0.3s ease;

  @media ${breakpoints.iPhone} {
    width: 110px;
    height: 36px;
  }
`;
