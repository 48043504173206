import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  CryptoResultContainer,
  CryptoStep,
  CryptoInner,
  CryptoTitleContainer,
  CryptoTitle,
  CryptoAddress,
  CryptoStats,
  CryptoStat,
  CryptoStatNumber,
  CryptoStatLabel,
  CryptoStatUnit,
  CryptoLeft,
  CryptoRight,
  CryptoOffsetContainer,
  CryptoOffsetInner,
  CryptoOffsetCredits,
  CryptoOffsetLabel,
  CryptoOffsetCopy,
  CryptoPaymentOptions,
  CryptoPaymentOption,
  CryptoPaymentCost,
  CryptoPaymentMethod,
  CryptoCheckoutButton,
  CryptoCryptoButton,
  CryptoMethod,
  CryptoMethodTitle,
  CryptoMethodCopy,
  CryptoError,
  CryptoStartOver,
  CryptoStartOverIcon
} from './CryptoStyles';


const fetchCheckoutSession = async ({ credits, currency }) => {
  return fetch('/_cryptoCheckoutSession', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      credits,
      currency
    }),
  }).then((res) => res.json());
};

const fetchCoinbaseCheckout = async ({ credits, currency }) => {
  return fetch('https://api.commerce.coinbase.com/charges', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CC-Api-Key': '5b8d4d50-e9f1-4b81-a5b0-b9e6439f1845',
      'X-CC-Version': '2018-03-22'
    },
    body: JSON.stringify({
      'name': credits + ' Carbon Credits',
      'description': 'Each carbon credit offsets 1 tonne of CO2 emissions.',
      'local_price': {
             'amount': credits * 11.0,
             'currency': 'USD'
       },
       'pricing_type': 'fixed_price',
       "redirect_url": "https://aerial.is/crypto?success=1&credits=" + credits,
       "cancel_url": window.location.url,
       "metadata": {
         "currency": currency
       },
    }),
  }).then((res) => res.json());
};

const fetchEmissions = async (code) => {
    return fetch('/_coinbaseConnect?code=' + code, { method: 'POST' })
      .then(res => res.json())
      .catch(function(error) {                        // catch
        window.location = '/crypto';
      });
}


class CryptoResult extends React.Component {
  urlParams = new URLSearchParams(this.props.location.search);

  state = {
    payment_option: 0,
    error: null,
    stripe: null,
    emissions: 0,
    address: null,
    coinbaseUrl: null,
    ethToUsd: null,
    success: this.urlParams.get("success") == 1,
    code: this.urlParams.get("code"),
    credits: this.urlParams.get("credits") || 0
  }

  async componentDidMount() {
    if (this.state.success) {
      return
    }

    const { currency, currency_name, address, emissions, error } = await fetchEmissions(this.state.code)
    this.setState({
      currency: currency,
      currencyName: currency_name,
      address: address,
      emissions: Math.ceil(emissions),
      credits: Math.ceil(emissions/1000),
      error: error
    });

    const { data } = await fetchCoinbaseCheckout({
      credits: Math.ceil(this.state.credits),
      currency: this.state.currency
    });

    this.setState({
      coinbaseUrl: data.hosted_url,
      ethToUsd: data.exchange_rates['ETH-USD'],
      btcToUsd: data.exchange_rates['BTC-USD']
    });
  }

  async checkout() {
    if (this.state.payment_option == 0) {
      this.setState({
        stripe: await loadStripe(
          window.location.hostname.includes('aerial.is') ?
            'pk_live_r2fvaquN6YUB67ACgeiFutfx00iQUZ3rEQ' :
            'pk_test_HfkHaG4z1zrTdfSaytysNbk300HLJW5cz6')
      });

      const { sessionId } = await fetchCheckoutSession({
        credits: Math.ceil(this.state.credits),
        currency: this.state.currency
      });

      const { error } = await this.state.stripe.redirectToCheckout({
        sessionId,
      });
    } else {
      window.location.href = this.state.coinbaseUrl
    }

    window.gtag('event', 'checkout', {
      'event_category': 'crypto'
    });
  }

  headerCopy() {
    return this.state.success ?
      "Successfully Offset!" :
      'Your ' + this.state.currency + ' Footprint'
  }

  render() {
    return (
        <CryptoStep className="result">
          <CryptoInner className={this.state.emissions != null ? "appear" : ""}>
            <CryptoResultContainer>
              <CryptoLeft className={this.state.error ? 'error' : ''}>
                <CryptoTitleContainer>
                {this.state.success == true &&
                  <CryptoTitle>
                    {isMobile ?
                      <ByWord text="Successfully Offset!" />
                    : <ByLetter text="Successfully Offset!" />}
                  </CryptoTitle>}
                {this.state.currencyName != null && this.state.success == false &&
                  <CryptoTitle>
                    {isMobile ?
                      <ByWord text={'Your ' + this.state.currencyName + ' Footprint'} />
                    : <ByLetter text={'Your ' + this.state.currencyName + ' Footprint'} />}
                  </CryptoTitle>
                }
                </CryptoTitleContainer>
                {this.state.success == false && this.state.address &&
                  <CryptoAddress>{this.state.address}</CryptoAddress>
                }
                {!this.state.error && !this.state.success &&
                  <CryptoStats>
                    <CryptoStat>
                      {this.state.emissions > 1000 ?
                        <CryptoStatNumber>
                          <CountUp end={this.state.emissions/1000} duration={1} />K
                        </CryptoStatNumber> :
                        <CryptoStatNumber>
                          <CountUp end={this.state.emissions} duration={1} />
                        </CryptoStatNumber>
                      }
                      <CryptoStatLabel>CO2 Emissions</CryptoStatLabel>
                      <CryptoStatUnit>KG</CryptoStatUnit>
                    </CryptoStat>
                  </CryptoStats>
                }
                {this.state.error == 'unsupported' &&
                  <div>
                    <CryptoError>Sorry, at this time we only support Bitcoin and Ethereum.</CryptoError>
                  </div>
                }
                {!this.state.success && !this.state.error &&
                  <CryptoMethod>
                    <CryptoMethodTitle>
                      How is this calculated?
                    </CryptoMethodTitle>
                    <CryptoMethodCopy>
                      Emissions are estimated based on your holdings, the number of transactions{this.state.currency == 'ETH' && ' and the gas used'}. For inquiries please contact us at <a href="mailto:crypto@aerial.is">crypto@aerial.is</a>.
                    </CryptoMethodCopy>
                    <CryptoMethodTitle>
                      How do I log out?
                    </CryptoMethodTitle>
                    <CryptoMethodCopy>
                      We don't store your login or account info. There is no need to log out.
                    </CryptoMethodCopy>
                  </CryptoMethod>
                }
                {!isMobile &&
                  <CryptoStartOver onClick={() => window.location='/crypto'}><CryptoStartOverIcon />Start Over</CryptoStartOver>
                }
              </CryptoLeft>
              {!this.state.error  &&
                <CryptoRight>
                  <CryptoOffsetContainer className={this.state.success != null ? "appear" : ""}>
                    {this.state.success ?
                      <CryptoOffsetInner className="success">
                        <CryptoOffsetCredits>{this.state.creditsPurchased || this.state.credits}</CryptoOffsetCredits>
                        <CryptoOffsetLabel>Carbon Credits Purchased</CryptoOffsetLabel>
                      </CryptoOffsetInner> :
                      <CryptoOffsetInner>
                        <CryptoOffsetCredits>{Math.ceil(this.state.emissions/1000)}</CryptoOffsetCredits>
                        <CryptoOffsetLabel>Carbon Credits Required</CryptoOffsetLabel>
                        {this.state.credits > 0 &&
                          <CryptoOffsetCopy>Securely purchase verified carbon credits to offset this wallet's footprint.</CryptoOffsetCopy>
                        }
                        {this.state.credits > 0 &&
                          <CryptoPaymentOptions>
                            <CryptoPaymentOption onClick={() => this.setState({payment_option: 0})} className={this.state.payment_option == 0 ? 'active' : ''}>
                              <CryptoPaymentCost>${Math.ceil(this.state.emissions/1000) * 11}</CryptoPaymentCost>
                              <CryptoPaymentMethod>Pay with Stripe</CryptoPaymentMethod>
                            </CryptoPaymentOption>
                            <CryptoPaymentOption onClick={() => this.setState({payment_option: 1})} className={this.state.payment_option == 1 ? 'active' : ''}>
                              { this.state.currency == 'BTC' ?
                                <CryptoPaymentCost>{this.state.btcToUsd ? (Math.ceil(this.state.emissions/1000) * 11 / this.state.btcToUsd).toFixed(3) : ''} BTC</CryptoPaymentCost> :
                                <CryptoPaymentCost>{this.state.ethToUsd ? (Math.ceil(this.state.emissions/1000) * 11 / this.state.ethToUsd).toFixed(3) : ''} ETH</CryptoPaymentCost>
                              }
                              <CryptoPaymentMethod>Pay with Coinbase</CryptoPaymentMethod>
                            </CryptoPaymentOption>
                          </CryptoPaymentOptions>
                        }
                        {this.state.credits > 0 &&
                          <CryptoCheckoutButton onClick={this.checkout.bind(this)}>
                            Offset
                          </CryptoCheckoutButton>
                        }
                      </CryptoOffsetInner>
                    }
                  </CryptoOffsetContainer>
                </CryptoRight>
              }
              {isMobile &&
                <CryptoStartOver onClick={() => window.location='/crypto'}><CryptoStartOverIcon />Start Over</CryptoStartOver>
              }
            </CryptoResultContainer>
          </CryptoInner>
        </CryptoStep>
    )
  }
}

export default withRouter(CryptoResult);
