import styled from 'styled-components';
import { colors } from '../../styles/Colors';
import { fonts, fontWeights, H3, H4, H5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import { skewIn } from '../../styles/Animations';
import send from '../../assets/images/send.svg';
import { Parallax } from 'react-scroll-parallax';
import ReactPlayer from 'react-player';
import Input from 'react-phone-number-input/input';

export const SignupContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding: 0 0 128px 0;

  @media ${breakpoints.iPadW} {
    padding-bottom: 64px;
  }

  @media ${breakpoints.iPhone} {
    padding-bottom: 32px;
    margin: 32px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(253, 250, 255, 1), rgba(253, 250, 255, .8));
    transition: opacity 1s ease;
  }

  &.appear {
    &:after {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const SignupInner = styled.div`
  display: flex;
  max-width: 1200px;
  margin: auto;

  transform: scale(0.95) perspective(1000px) rotateX(20deg) translate3d(0, 0, -100px);
  transition: transform 1s ease;

  @media ${breakpoints.laptop} {
    max-width: initial;
    margin: 0 80px;
  }

  @media ${breakpoints.iPhone} {
    box-shadow: none;
    margin: 0;
    flex-direction: row;
  }

  ${SignupContainer}.appear & {
    transform: scale(1) rotate(0) translate3d(0, 0, 0);
  }
`;

export const SignupAssetContainer = styled.div`
  width: 100%;
  padding-top: 50%;
  border-radius: 32px;
  position: relative;
  overflow: hidden;
  perspective: 1000px;

  @media ${breakpoints.iPhone} {
    justify-content: flex-start;
    padding-top: 140%;
  }
`;

export const SignupVideo = styled(ReactPlayer)`

  video {
    position: absolute;
    height: 120% !important;
    width: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.mobile {
    display: none;

    @media ${breakpoints.iPhone} {
      min-width: 100%;
      height: 150% !important;
      width: auto !important;
    }
  }

  @media ${breakpoints.iPhone} {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
`;

export const SignupParallax = styled(Parallax)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;

  .parallax-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
`;


export const SignupRight = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 320px;
  position: absolute;
  padding: 40px;

  @media ${breakpoints.iPadW} {
    padding: 16px;
    min-width: 280px;
  }

  @media ${breakpoints.iPhone} {
    min-width: 0;
    padding: 0;
    width: calc(100% - 32px);
    margin: 32px 16px;
  }
`;

export const SignupHeader = styled(H4)`
  color: ${colors.purple};

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${SignupContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadW} {
    font-size: 22px;
    line-height: 32px;
  }
`;

export const SignupInputContainer = styled.div`
  position: relative;
  max-width: 320px;
  border-radius: 30px;
  overflow: hidden;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  ${SignupContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const SignupInput = styled(Input)`
  font-family: ${fonts.aeonik};
  border: none;
  width: 100%;
  padding: 16px 0 16px 16px;
  font-size: 16px;
  outline: none;
  margin: 0;

  ${SignupInputContainer}.submitted & {
    pointer-events: none;
  }

  &:disabled {
    background-color: rgba(255, 255, 255, .7);
  }
`;

export const SignupButton = styled.div`
  height: 100%;
  width: 65px;
  background-image: url(${send});
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 40% 50%;
  background-color: #75D67B;
  position: absolute;
  right: 0;
  top: 0;

  &:action {
    background-color: #5bb961;
  }

  ${SignupInputContainer}.submitted & {
    pointer-events: none;
    background-color: rgba(117, 214, 123, .7);
  }
`;
