import React, { useEffect, useState, useContext } from 'react';
import UserContext from '../../UserContext';
import TeamContext from '../../TeamContext';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

import {
  OffsetModalContainer,
  OffsetModalInnerContainer,
  OffsetModalInner,
  OffsetModalClose,
  OffsetModalTitle,
  OffsetModalInfo,
  OffsetModalPicture,
  OffsetModalName,
  OffsetModalEmail,
  OffsetModalOptions,
  OffsetModalButton,
  OffsetModalDeleted,
  OffsetLoader,
  OffsetModalCopy,
  OffsetModalConfirm,
  OffsetModalConfirmButton,
  OffsetModalShare
} from './OffsetStyles';


const OffsetModal = (props) =>  {
  const { user, logout, deleteAccount, showSettings, showShare } = useContext(UserContext);
  const { team, getTeamStats } = useContext(TeamContext);
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(null)
  const [confirmDelete, setConfirmDelete] = useState(false);

  useEffect(() => {
    getUrl()
  }, [user, team]);

  const getUrl = () => {
    if (!!team.name){
      var stats = getTeamStats()
      if (stats.emissions > 0) {
        setUrl(window.location.origin + '/share?name=' + team.name
          + '&emissions=' + stats.emissions + '&distance=' + stats.distance
          + '&air=' + stats.breakdown.air + '&train=' + stats.breakdown.train + '&rideshare=' + stats.breakdown.rideshare)
      }
    } else if (user) {
      setUrl(window.location.origin + '/share/' + user.id)
    }
  }

  return (
      <OffsetModalContainer className={user.settings || user.share ? "show": ""}>
        <OffsetModalInnerContainer>
          {!!user.id && user.activities.length > 0 &&
            <OffsetModalInner className={user.share ? "show" : ""}>
              <OffsetModalClose onClick={() => {showShare(false)}} />
              <OffsetModalTitle>Share your progress!</OffsetModalTitle>
              <OffsetModalShare>
                <img src={url}/>
              </OffsetModalShare>
              {isMobile ?
                <OffsetModalButton href={url} target='_blank' className="download">Save Image</OffsetModalButton>
              :
                <OffsetModalButton href={url} target='_blank' download='Aerial.png' className="download">Save Image</OffsetModalButton>
              }
            </OffsetModalInner>
          }
          <OffsetModalInner className={!confirmDelete && !user.deleting && !user.share ? "show" : ""}>
            <OffsetModalClose onClick={() => {showSettings(false)}} />
            <OffsetModalTitle>Settings</OffsetModalTitle>
            <OffsetModalInfo>
              <OffsetModalPicture style={{backgroundImage: `url(${user.picture})`}} />
              <OffsetModalName>{user.name}</OffsetModalName>
              <OffsetModalEmail>{user.email}</OffsetModalEmail>
            </OffsetModalInfo>
            <OffsetModalOptions>
              <OffsetModalButton className="logout" onClick={() => {showSettings(false); logout()}}>Log Out</OffsetModalButton>
              <OffsetModalButton className="delete" onClick={() => setConfirmDelete(true)}>Delete Account</OffsetModalButton>
            </OffsetModalOptions>
          </OffsetModalInner>

          {
            !!team.name && team.admin && team.admins == 1 && team.members.length > 1 ?
            <OffsetModalInner className={confirmDelete && !user.deleting ? "show" : ""}>
              <OffsetModalTitle>Please delegate someome else as team admin before deleting your account.</OffsetModalTitle>
              <OffsetModalConfirm>
                <OffsetModalConfirmButton onClick={() => setConfirmDelete(false)}>Ok</OffsetModalConfirmButton>
              </OffsetModalConfirm>
            </OffsetModalInner>
            :
            <OffsetModalInner className={confirmDelete && !user.deleting ? "show" : ""}>
              <OffsetModalTitle>Are you sure you want to delete your account?</OffsetModalTitle>
              <OffsetModalConfirm>
                <OffsetModalConfirmButton onClick={() => setConfirmDelete(false)}>Cancel</OffsetModalConfirmButton>
                <OffsetModalConfirmButton className="confirm" onClick={() => {showSettings(false); setConfirmDelete(false); deleteAccount()}}>Yes</OffsetModalConfirmButton>
              </OffsetModalConfirm>
            </OffsetModalInner>
          }

          <OffsetModalInner className={user.deleting ? "show" : ""}>
            <OffsetModalTitle>{user.deleted ? "Account successfully deleted" : "Deleting your account..."} </OffsetModalTitle>
            {user.deleted ?
              <OffsetModalDeleted /> :
              <OffsetLoader className="show"/>
            }
            {user.deleting && !user.deleted &&
              <OffsetModalCopy>(this could take a few minutes)</OffsetModalCopy>
            }
          </OffsetModalInner>
        </OffsetModalInnerContainer>
      </OffsetModalContainer>
    )
}

export default OffsetModal;
