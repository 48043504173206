import React from 'react';

// @function  UserContext
const UserContext = React.createContext({
  id: null,
  activities: [],
  loggedIn: false,
  fetched: false,
  settings: false,
  deleting: false,
  deleted: false,
  share: false
});

export default UserContext;
