// In src/pages/Page.js
import React, { useEffect, useState } from 'react'
import { client, linkResolver } from '../prismic-configuration'
import NotFound from './NotFound'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import createApp from '@shopify/app-bridge';
import {Provider, TitleBar} from '@shopify/app-bridge-react';
import {Button, Redirect} from '@shopify/app-bridge/actions';
import NftShopify from '../components/nft/NftShopify';
import Press from '../components/press/Press';
import Project from '../components/project/Project';
import Promo from '../components/promo/Promo';
import { useHistory } from "react-router-dom";

const Nft = ({ location }) => {
  const [doc, setDocData] = useState(null)
  const [notFound, toggleNotFound] = useState(false)
  const urlParams = new URLSearchParams(location.search);

  const shopOrigin = urlParams.get('shop');
  const host = urlParams.get('host');
  const config = {
    apiKey: '713d93bb45f4d3ea5d5ebc6228b949d1',
    host: host,
  };

  const redirectUri = 'https://aerial.is/auth/shopify/callback';
  const permissionUrl = `https://${shopOrigin}/admin/oauth/authorize?client_id=${config.apiKey}&scope=read_orders&redirect_uri=${redirectUri}`;

  if (!host) {
    // If the current window is the 'parent', change the URL by setting location.href
    if (window.top == window.self) {
      window.location.assign(permissionUrl);

      // If the current window is the 'child', change the parent's URL with Shopify App Bridge's Redirect action
    } else {
      const app = createApp({
        apiKey: config.apiKey,
        shopOrigin: shopOrigin
      });

      Redirect.create(app).dispatch(Redirect.Action.REMOTE, permissionUrl);
    }
  }

  let history = useHistory()

  // Get the page document from Prismic
  useEffect(() => {
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getSingle('homepage')

      if (result) {
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
        toggleNotFound(true)
      }
    }
    fetchData()
  }, [])

  if (doc) {
    return (
      <Provider config={config}>
        <TitleBar
          title="Offset Your NFTs"
        />
        <NftShopify/>
        <Project
          projects={doc.data.projects}
        />
        <Header showLinks={false} />
      </Provider>
    )
  } else if (notFound) {
    return <NotFound />
  }
  return null
}

export default Nft
