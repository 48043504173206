import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { skewIn } from '../../styles/Animations';
import { fonts, fontWeights, H2, H3, H4, H5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import shasta from '../../assets/images/shasta.jpg';
import goals from '../../assets/images/verifications/goals.png';
import acr from '../../assets/images/verifications/acr.png';
import car from '../../assets/images/verifications/car.png';
import verra from '../../assets/images/verifications/verra.png';
import { Parallax } from 'react-scroll-parallax';
import ReactPlayer from 'react-player';

function template(i, items, duration) {
  return `
      &:nth-child(${i + 1}) {
        transition-delay: ${`${(duration / items) * i}s`};
       }
    `
}

function getDelays(items, duration) {
  let str = ''
  for (let i = 0; i < items; i += 1) {
    str += template(i, items, duration)
  }
  return str
}

export const ProjectContainer = styled.div`
  overflow: hidden;
  transition: opacity .3s ease;
  position: relative;
  padding: 80px 0 80px 0;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  @media ${breakpoints.laptop} {
    padding: 64px 0;
  }

  @media ${breakpoints.iPadH} {
    padding: 80px 32px;
  }

  @media ${breakpoints.iPhone} {
    padding: 64px 32px;
    width: auto;
  }

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const ProjectIntro = styled.div`
  max-width: 1200px;
  margin: auto;
  padding-bottom: 40px;

  @media ${breakpoints.iPadW} {
    padding-bottom: 64px;
  }

  @media ${breakpoints.iPhone} {
    padding-bottom: 0;
  }
`;

export const ProjectOuter = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: auto;

  @media ${breakpoints.iPhone} {
    flex-direction: column;
    padding: 0;
  }
`;

export const ProjectInner = styled.div`
  margin: 0 12px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .08);
  border-radius: 32px;
  overflow: hidden;

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }

  transform: scale(0.95) perspective(1000px) rotateX(20deg) translate3d(0, 0, -100px);
  transition: transform 1s ease, opacity 1s ease;
  opacity: 0;
  ${getDelays(3, 1)};

  ${ProjectContainer}.appear & {
    transform: scale(1) rotate(0) translate3d(0, 0, 0);
    opacity: 1;
  }

  @media ${breakpoints.iPadW} {
    margin: 0 8px;
  }

  @media ${breakpoints.iPhone} {
    margin: 16px 0 0 0;
  }
`;


export const ProjectLeft = styled.div`

`;

export const ProjectAssetContainer = styled.div`
  width: 100%;
  padding-top: 66%;
  position: relative;
  overflow: hidden;
  perspective: 1000px;

  @media ${breakpoints.iPhone} {
    width: auto;
    padding-top: 50%;
  }
`;

export const ProjectVideo = styled(ReactPlayer)`
  video {
    position: absolute;
    height: 120% !important;
    width: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ProjectPicture = styled.div`
  position: absolute;
  width: 100%;
  height: 120%;
  background-size: cover;
  background-position: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, .08), inset 0 0 24px 2px rgba(255, 255, 255, .2);
`;

export const ProjectParallax = styled(Parallax)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .parallax-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const ProjectLabel = styled(H5)`
  background: rgba(0, 0, 0, .25);
  padding: 0 8px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  border-radius: 10px;
  color: rgba(255, 255, 255, .9);
  font-size: 11px;
  line-height: 30px;
`;


export const ProjectInfo = styled.div`
  position: absolute;
  color: white;
  text-align: center;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;

  @media ${breakpoints.iPadW} {
    padding: 8px 0;
  }
`;

export const ProjectDescription = styled(P)`
  font-size: 14px;
`;

export const ProjectContent = styled.div`
  padding: 16px 24px;

  @media ${breakpoints.iPadW} {
    padding: 12px 16px;
  }
`;

export const ProjectContentInner = styled.div`
  max-width: 400px;

  @media ${breakpoints.iPhone} {
    margin: 0;
  }
`;

export const ProjectTitle = styled(H5)`
  color: ${colors.midgray};

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  ${ProjectContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const ProjectHeader = styled(H3)`
  color: ${colors.purple4};
  font-weight: ${fontWeights.black};
  font-size: 36px;

  ${ProjectContainer}.home & {
    background: ${gradients.heavy};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
  }
`;

export const ProjectSubtitle = styled(H4)`
  font-size: 18px;
  color: ${colors.purple2};
  margin: 0;
  max-width: 500px;
  line-height: 1.2em;

  ${ProjectContainer}.home & {
    color: ${colors.midgray};
  }
`;

export const ProjectHeader2 = styled(H4)`
  font-size: 24px;
  color: ${colors.purple3};
  margin: 0;
`;

export const ProjectCopy = styled(P)`
  color: ${colors.midgray};
  font-size: 14px;
  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${ProjectContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadW} {
    font-size: 12px;
  }
`;

export const ProjectLocation = styled.div`
  color: ${colors.dark};
  font-size: 16px;
  margin-bottom: 8px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${ProjectContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const ProjectVerifications = styled.div`
  display: flex;
  max-width: 1200px;
  margin: auto;
  margin-top: 40px;

  @media ${breakpoints.iPadW} {
    margin-top: 16px;
  }
`;

export const ProjectVerification = styled.div`

  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  margin-right: 8px;

  &.goals {
    background-image: url(${goals});
    width: 160px;

    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: .3s;

    ${ProjectContainer}.appear & {
      opacity: 0.5;
      transform: translate(0, 0);
    }
  }

  &.acr {
    background-image: url(${acr});
    width: 80px;

    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: .4s;

    ${ProjectContainer}.appear & {
      opacity: 0.5;
      transform: translate(0, 0);
    }
  }

  &.car {
    background-image: url(${car});
    width: 80px;

    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: .5s;

    ${ProjectContainer}.appear & {
      opacity: 0.5;
      transform: translate(0, 0);
    }
  }

  &.verra {
    background-image: url(${verra});
    width: 80px;

    opacity: 0;
    transform: translate(0, 20px);
    transition: opacity 1s ease, transform 1s ease;
    transition-delay: .5s;

    ${ProjectContainer}.appear & {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`;
