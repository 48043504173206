import styled from 'styled-components';
import whiteLogo from '../../assets/images/logos/a.svg';
import colorLogo from '../../assets/images/logos/color-lockup.svg';
import colorLogoPng from '../../assets/images/logos/color-lockup.png';
import { colors, gradients } from '../../styles/Colors';
import { breakpoints } from '../../styles/Breakpoints';
import { fonts, fontWeights } from '../../styles/Typography';
import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import appstore from '../../assets/images/appstore_outline_white.svg';
import appstoreDark from '../../assets/images/appstore_outline.svg';
import appstorePurple from '../../assets/images/appstore_purple.svg';
import googleplay from '../../assets/images/google-play-outline-white.svg';
import googleplayDark from '../../assets/images/google-play-outline.svg';
import googleplayPurple from '../../assets/images/google-play-outline-purple.svg';
import down from '../../assets/images/icons/down.svg';
import downWhite from '../../assets/images/icons/down-white.svg';


export const HeaderContainer = styled.div`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  transition: all .2s ease;
  transition-delay: 0;
  z-index: 99;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    z-index: -1;
  }

  &.scrolled {


    &:after {
      background: rgba(253, 250, 255, 0.85);
      backdrop-filter: blur(10px);
      transition: all 0.3s ease;
      height: 100%;
    }
  }

  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }

  @media ${breakpoints.iPadW} {
    height: 64px;
  }
`;

export const HeaderInner = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media ${breakpoints.laptop} {
    max-width: initial;
    margin: 0 80px;
  }
  @media ${breakpoints.iPadH} {
    max-width: initial;
    margin: 0 16px;
  }
`;

export const HeaderLogos = styled.div`
  width: 140px;
  height: 35px;
  position: relative;
  cursor: pointer;

  ${HeaderContainer}.onload-appear & {
    opacity: 0;
  }
  ${HeaderContainer}.onload-appear-done & {
    opacity: 1;
    transition: opacity 0.6s ease;
    transition-delay: 0.6s;
  }

  @media ${breakpoints.iPadW} {
    width: 120px;
    height: 30px;
  }

  @media ${breakpoints.iPhone} {
    /* left: 50%;
    transform: translate(-50%, 0); */
    width: 100px;
    height: 30px;
  }
`;

export const HeaderLogo = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &.white {
    background-image: url(${whiteLogo});
    transition: opacity .3s ease;
  }
  &.color {
    background-image: url(${colorLogo});
    opacity: 0;
    transition: opacity 1s ease;

    @media ${breakpoints.iPhone} {
      background-image: url(${colorLogoPng});
    }
  }

  ${HeaderContainer}.scrolled & {
    &.white {
      opacity: 0;
    }
    &.color {
      opacity: 1;
    }
  }
`;

export const HeaderLinks = styled.div`
  display: flex;
  align-items: center;

  ${HeaderContainer}.onload-appear & {
    opacity: 0;
  }
  ${HeaderContainer}.onload-appear-done & {
    opacity: 1;
    transition: opacity 0.6s ease;
    transition-delay: 1s;
  }
`;

export const HeaderScrollLink = styled(ScrollLink)`
  color: white;
  text-decoration: none;
  margin: 0 8px;
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  position: relative;
  transition: color 1s ease;
  cursor: pointer;

  ${HeaderContainer}.scrolled & {
    color: ${colors.purple};

    &:after {
      background: ${colors.purple};
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    height: 5px;
    width: 5px;
    margin-top: 4px;
    border-radius: 3px;
    left: 50%;
    transform: translate(-50%, 10px);
    background: white;
    transition: opacity .3s ease, transform .3s ease;
    transition-delay: 0.3s;
    opacity: 0;
  }

  &:hover, &.active {
    &:after {
      transition-delay: 0s;
      opacity: 1;
      transform: translate(-50%, 4px);
    }
  }

  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
}
`;

export const HeaderLink = styled(Link)`
  color: white;
  text-decoration: none;
  margin: 0 8px;
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  position: relative;
  transition: color 1s ease;
  cursor: pointer;

  ${HeaderContainer}.scrolled & {
    color: ${colors.purple};

    &:after {
      background: ${colors.purple};
    }
  }

  &:after {
    content: "";
    position: absolute;
    top: 100%;
    height: 5px;
    width: 5px;
    margin-top: 4px;
    border-radius: 3px;
    left: 50%;
    transform: translate(-50%, 10px);
    background: white;
    transition: opacity .3s ease, transform .3s ease;
    transition-delay: 0.3s;
    opacity: 0;
  }

  &:hover, &.active {
    &:after {
      transition-delay: 0s;
      opacity: 1;
      transform: translate(-50%, 4px);
    }
  }

  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
}
`;

export const HeaderSignInLink = styled(Link)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: ${colors.purple};
  text-decoration: none;
  margin-left: 8px;
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  position: relative;
  transition: color 1s ease;
  cursor: pointer;
  border-radius: 32px;
  padding: 12px 24px;

  span {
    z-index: 2;
  }

  &:before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(125deg, rgba(255, 255, 255, .8), rgba(255, 255, 255, .4));
    border-radius: 32px;
    z-index: -1;
  }

  ${HeaderContainer}.scrolled & {
    color: white;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }

  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }
}
`;

export const HeaderAppStoreLink = styled.a`
  margin-left: 16px;
`;

export const HeaderAppStoreIcon = styled.div`
  background-image: url(${appstore});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 40px;
  transition: background .3s ease;

  ${HeaderContainer}.scrolled & {
    background-image: url(${appstorePurple});
  }

  @media ${breakpoints.iPhone} {
    width: 100px;
    height: 36px;

    ${HeaderContainer}.scrolled & {
      background-image: url(${appstoreDark});
      opacity: .6;
    }
  }
`;

export const HeaderAndroidLink = styled.a`
  margin-left: 16px;

  @media ${breakpoints.iPhone} {
    margin-left: 8px;
  }
`;

export const HeaderAndroidIcon = styled.div`
  background-image: url(${googleplay});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 135px;
  height: 40px;
  transition: background .3s ease;

  ${HeaderContainer}.scrolled & {
    background-image: url(${googleplayPurple});
  }

  @media ${breakpoints.iPhone} {
    width: 110px;
    height: 36px;

    ${HeaderContainer}.scrolled & {
      background-image: url(${googleplayDark});
      opacity: .6;
    }
  }
`;

export const HeaderUserContainer = styled.div`
  margin-left: 16px;
  cursor: pointer;
  position: relative;
`;

export const HeaderUserInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 0 16px;
  padding-right: 40px;
  position: relative;
  z-index: 2;

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    right: 12px;
    background-image: url(${down});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.8;
    transition: transform .3s ease;
  }

  ${HeaderUserContainer}.opened & {
    &:after {
      transform: rotate(180deg);
    }
  }
`;

export const HeaderUserName = styled.div`
  color: ${colors.purple3};
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  transition: color 1s ease;
`;

export const HeaderUserEmail = styled.div`
  color: ${colors.purple2};
  font-size: 12px;
`;

export const HeaderUserPicture = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: ${colors.purple};
  background-size: cover;
  background-position: center;
  margin-right: 8px;
`;

export const HeaderUserMenu = styled.div`
  background: rgba(255, 255, 255, .4);
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  border-radius: 8px;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s ease, background .3s ease;

  ${HeaderContainer}.scrolled & {
    background: rgba(255, 255, 255, .85);
  }

  ${HeaderUserContainer}.opened & {
    opacity: 1;
    pointer-events: all;
  }

  @media ${breakpoints.iPhone} {
    background: rgba(255, 255, 255, .95);
  }
`;

export const HeaderUserMenuItems = styled.div`
  padding: 64px 16px 16px;
`;

export const HeaderUserMenuItem = styled(Link)`
  display: block;
  font-weight: ${fontWeights.medium};
  font-size: 16px;
  padding: 8px 0;
  color: ${colors.purple3};
  white-space: nowrap;
  text-decoration: none;

  &.delete {
    color: ${colors.red};
  }
`;
