import React from "react";
import { CSSTransition } from "react-transition-group";
import VisibilitySensor from "react-visibility-sensor";
import {
  FooterContainer,
  FooterParallax,
  FooterBackground,
  FooterInner,
  FooterLinks,
  FooterLinkColumn,
  FooterLink,
  FooterSocial,
  FooterSocialLinks,
  FooterSocialLink,
  FooterExternalLink,
  FooterDownloadLinks,
  FooterAppstore,
  FooterGoogleplay,
  FooterBranding,
  FooterLogo,
  FooterCopyright,
} from "./FooterStyles";

class Footer extends React.Component {
  state = {
    appear: false,
  };

  render() {
    return (
      <VisibilitySensor
        key={"footer"}
        partialVisibility={true}
        offset={{ bottom: window.innerHeight / 4 }}
        onChange={(isVisible) => isVisible && this.setState({ appear: true })}
      >
        <FooterContainer className={this.state.appear ? "appear" : ""}>
          <FooterParallax y={[10, -10]}>
            <FooterBackground />
          </FooterParallax>
          <FooterInner>
            <FooterLinks>
              <FooterLinkColumn>
                <FooterLink to="/terms-of-service">Terms of Service</FooterLink>
                <FooterLink to="/privacy-policy">Privacy Policy</FooterLink>
              </FooterLinkColumn>
            </FooterLinks>
            <FooterSocial>
              <FooterSocialLinks>
                <FooterSocialLink
                  className="instagram"
                  href="http://www.instagram.com/withaerial"
                  target="_blank"
                />
                <FooterSocialLink
                  className="linkedin"
                  href="https://www.linkedin.com/company/withaerial"
                  target="_blank"
                />
              </FooterSocialLinks>
              <FooterDownloadLinks>
                <FooterAppstore />
                <FooterGoogleplay />
              </FooterDownloadLinks>
            </FooterSocial>
          </FooterInner>
          <FooterBranding>
            <FooterLogo />
            <FooterCopyright>
              © 2024 Corso Technologies, Inc. All rights reserved.
              <br />
              AERIAL® is a registered trademark of Corso Technologies, Inc.
            </FooterCopyright>
          </FooterBranding>
        </FooterContainer>
      </VisibilitySensor>
    );
  }
}

export default Footer;
