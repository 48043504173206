import React, { useEffect, useState } from 'react'
import { client, linkResolver } from '../prismic-configuration'
import queryString from 'query-string';
import { createGlobalStyle } from 'styled-components';

import Embed from '../components/nft/Embed';

const NftEmbed = ({ match, location }) => {
  const GlobalStyle = createGlobalStyle`
    body {
      background: transparent;
    }
  `
  const params = queryString.parse(location.search);
  const path = match.url

  return (
    <div>
      <GlobalStyle />
      <Embed address={params.address} theme={params.theme} bsc={path.toLowerCase().includes('bsc')} />
    </div>
  )
}

export default NftEmbed
