import styled from 'styled-components';

export const ProgressRingContainer = styled.div`
  
`;

export const ProgressRingCircle = styled.circle`
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
`;
