import styled from 'styled-components';
import { css } from 'styled-components';

import { colors } from './Colors';
import { breakpoints } from './Breakpoints';

export const sf = "'sf-rounded', 'Helvetica Neue', sansserif";
export const aeonik = "'aeonik', 'Helvetica Neue', sansserif";
export const adonis = "'adonis-web', serif";
export const urbane = "urbane-rounded, sans-serif"

export const fonts = {
  sf: sf,
  adonis: adonis,
  urbane: urbane,
  aeonik: aeonik
};

export const fontWeights = {
  light: '300',
  regular: 'normal',
  bold: 'bold',
  demibold: '600',
  medium: '500',
  black: '700'
};

export const h1 = `
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.black};
  font-size: 56px;
  line-height: 68px;
`;

export const h2 = `
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.bold};
  font-size: 42px;
  line-height: 56px;
  margin: 16px 0;
`;

export const h3 = `
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.bold};
  font-size: 32px;
  line-height: 40px;
  margin: 16px 0;
`;

export const h4 = `
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.medium};
  font-size: 24px;
  line-height: 36px;
  margin: 8px 0;
`;

export const h5 = `
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.bold};
  font-size: 12px;
  line-height: 36px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
`;

export const p = `
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.medium};
  font-size: 18px;
  line-height: 1.5em;
  margin: 8px 0;
`;

export const H1 = styled.h1`
  ${h1};

  @media ${breakpoints.iPadW} {
    font-size: 42px;
    line-height: 56px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const H2 = styled.h2`
  ${h2}

  @media ${breakpoints.iPadProH} {
    font-size: 32px;
    line-height: 38px;
  }

  @media ${breakpoints.iPadW} {
    font-size: 30px;
    line-height: 36px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 28px;
    line-height: 34px;
  }
`;

export const H3 = styled.h4`
  ${h3}

  @media ${breakpoints.iPadW} {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const H4 = styled.h4`
  ${h4}

  @media ${breakpoints.iPadProH} {
    font-size: 20px;
    line-height: 26px;
  }

  @media ${breakpoints.iPadW} {
    font-size: 20px;
    line-height: 26px;
  }

  @media ${breakpoints.iPadW} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const H5 = styled.h5`
  ${h5}
`;

export const P = styled.p`
  ${p}

  @media ${breakpoints.iPadW} {
    font-size: 16px;
  }
`;
