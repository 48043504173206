import { createGlobalStyle } from 'styled-components';
import { fonts, fontWeights } from './Typography';
import { breakpoints } from './Breakpoints';
import { colors } from './Colors';

import SFRoundedBold from '../assets/fonts/sf/SFProRoundedBold.ttf';
import SFRoundedRegular from '../assets/fonts/sf/SFProRoundedRegular.ttf';

import AeonikLight from '../assets/fonts/aeonik/Aeonik-Light.ttf';
import AeonikRegular from '../assets/fonts/aeonik/Aeonik-Regular.ttf';
import AeonikMedium from '../assets/fonts/aeonik/Aeonik-Medium.ttf';
import AeonikBold from '../assets/fonts/aeonik/Aeonik-Bold.ttf';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: aeonik;
    src: url('${AeonikLight}');
    font-weight: 300;
  };

  @font-face {
    font-family: aeonik;
    src: url('${AeonikRegular}');
    font-weight: normal;
  };

  @font-face {
    font-family: aeonik;
    src: url('${AeonikMedium}');
    font-weight: 500;
  };

  @font-face {
    font-family: aeonik;
    src: url('${AeonikBold}');
    font-weight: bold;
  };

  body {
    background-color: ${colors.light};
    padding: 0;
    margin: 0;
    font-family: ${fonts.aeonik};
    font-weight: ${fontWeights.regular};
    font-size: 14px;
  }

  .producthunt {
    position: fixed;
    bottom: 40px;
    right: 40px;
    width: 250px;
    height: 54px;

    @media ${breakpoints.iPhone} {
      display: none;
    }
  }

  .dark {
    background-color: ${colors.dark};
  }

  .white-background {
    background-color: white;
  }

  .by-letter {
    position: relative;
    overflow: hidden;
  }

  .by-letter .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em;
    overflow: hidden;
  }

  .by-letter .letter {
    transform-origin: 0 100%;
    display: inline-block;
    opacity: 0;
  }

  .by-word {
    position: relative;
    overflow: hidden;
  }

  .by-word .text-wrapper {
    position: relative;
    display: inline-block;
    padding-top: 0.2em;
    padding-right: 0.05em;
    padding-bottom: 0.1em;
    overflow: hidden;
  }

  .by-word .word {
    transform-origin: 0 100%;
    display: inline-block;
    opacity: 0;
  }
`;
