import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  NftResultContainer,
  NftStep,
  NftInner,
  NftTitleContainer,
  NftTitle,
  NftAddress,
  NftStats,
  NftStat,
  NftStatNumber,
  NftStatLabel,
  NftStatUnit,
  NftLeft,
  NftRight,
  NftOffsetContainer,
  NftOffsetInner,
  NftOffsetCredits,
  NftOffsetLabel,
  NftOffsetCopy,
  NftPaymentOptions,
  NftPaymentOption,
  NftPaymentCost,
  NftPaymentMethod,
  NftCheckoutButton,
  NftCryptoButton,
  NftMethod,
  NftMethodTitle,
  NftMethodCopy,
  NftStartOver,
  NftStartOverIcon,
  NftLoader
} from './NftStyles';

const fetchCheckoutSession = async ({ credits, address, tokenid, transactions, bsc }) => {
  return fetch('/_createCheckoutSession', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      credits,
      address,
      tokenid,
      transactions,
      bsc: bsc ? 1 : 0
    }),
  }).then((res) => res.json());
};

const fetchCoinbaseCheckout = async ({ credits, address, token_id, transactions, bsc }) => {
  return fetch('https://api.commerce.coinbase.com/charges', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CC-Api-Key': '5b8d4d50-e9f1-4b81-a5b0-b9e6439f1845',
      'X-CC-Version': '2018-03-22'
    },
    body: JSON.stringify({
      'name': credits + ' Carbon Credits',
      'description': 'Each carbon credit offsets 1 tonne of CO2 emissions.',
      'local_price': {
             'amount': credits * 20,
             'currency': 'USD'
       },
       'pricing_type': 'fixed_price',
       "redirect_url": "https://aerial.is/" + (bsc ? "bsc" : "nft") + "/" + address + "?success=1" + (token_id ? '&tokenid=' + token_id : ''),
       "cancel_url": window.location.url,
       "metadata": {
         "address": address,
         "token_id": token_id,
         "credits": credits,
         "transactions": transactions,
         "bsc": bsc ? 1 : 0
       },
    }),
  }).then((res) => res.json());
};

const fetchEmissions = async (address, gas, transactions, bsc) => {
    if (gas) {
      return fetch('/_nft?gas=' + gas + (transactions ? '&transactions=' + transactions : ''))
        .then(res => res.json())
    }

    return fetch('/_nft/' + address + (bsc ? '?bsc=1' : ''))
      .then(res => res.json())
}

const fetchStatus = async (address, tokenId, bsc) => {
    return fetch('/_nft/offset/' + address + (tokenId ? '?tokenid=' + tokenId : '') + (bsc ? '?bsc=1' : ''))
      .then(res => res.json())
}


class NftResult extends React.Component {
  urlParams = new URLSearchParams(this.props.location.search);

  state = {
    payment_option: 1,
    error: null,
    stripe: null,
    emissions: 0,
    gas: this.urlParams.get("gas") || 0,
    transactions: this.urlParams.get("transactions") || 0,
    coinbaseUrl: null,
    ethToUsd: null,
    success: this.urlParams.get("success"),
    token_id: this.urlParams.get("tokenid"),
    credits_purchased: 0,
    transactions_offset: 0,
    transactions_remaining: 0,
    credits: 0,
    credits_required: 0,
    loading: true
  }

  async componentDidMount() {
    const { co2, gas, transactions, credits, cost, credits_purchased, transactions_offset } = await fetchEmissions(this.props.address, this.state.gas, this.state.transactions, this.props.bsc)
    this.setState({
      emissions: co2,
      gas: gas,
      transactions: transactions,
      credits: credits,
      credits_purchased: credits_purchased,
      transactions_offset: transactions_offset,
      credits_required: transactions_offset == null || transactions_offset < transactions ? Math.max(credits - credits_purchased, 0) : 0,
      transactions_remaining: transactions - (transactions_offset || 0),
      success: transactions_offset == transactions || credits <= credits_purchased,
      loading: false
    });

    const { data } = await fetchCoinbaseCheckout({
      credits: Math.ceil(this.state.credits_required),
      address: this.props.address,
      token_id: this.state.token_id,
      transactions: this.state.transactions_remaining,
      bsc: this.props.bsc
    });

    this.setState({
      coinbaseUrl: data.hosted_url,
      ethToUsd: data.exchange_rates['ETH-USD']
    });
  }

  async checkout() {
    if (this.state.payment_option == 0) {
      this.setState({
        stripe: await loadStripe(
          window.location.hostname.includes('aerial.is') ?
            'pk_live_r2fvaquN6YUB67ACgeiFutfx00iQUZ3rEQ' :
            'pk_test_HfkHaG4z1zrTdfSaytysNbk300HLJW5cz6')
      });

      const { sessionId } = await fetchCheckoutSession({
        credits: Math.ceil(this.state.credits_required),
        address: this.props.address,
        tokenid: this.state.token_id,
        transactions: this.state.transactions_remaining,
        bsc: this.props.bsc
      });

      const { error } = await this.state.stripe.redirectToCheckout({
        sessionId,
      });
    } else {
      window.location.href = this.state.coinbaseUrl
    }

    window.gtag('event', 'checkout', {
      'event_category': 'NFT'
    });
  }

  headerCopy() {
    return this.state.success ?
      "Successfully Offset!" :
      'Your NFT Footprint'
  }

  render() {
    return (
        <NftStep className="result">
          <NftLoader className={this.state.loading ? 'show' : ''} />
          <NftInner className={this.state.success != null ? "appear" : ""}>
            <NftResultContainer>
              <NftLeft>
                <NftTitleContainer>
                {this.state.success == true &&
                  <NftTitle>
                    {isMobile ?
                      <ByWord text="Successfully Offset!" />
                    : <ByLetter text="Successfully Offset!" />}
                  </NftTitle>}
                {this.state.success == false &&
                  <NftTitle>
                    {isMobile ?
                      <ByWord text="Your NFT Footprint" />
                    : <ByLetter text="Your NFT Footprint" />}
                  </NftTitle>
                }
                </NftTitleContainer>
                <NftAddress>{this.props.address}</NftAddress>
                {!(this.state.success && this.state.token_id) &&
                  <NftStats>
                    <NftStat>
                      {this.state.emissions > 1000 ?
                        <NftStatNumber>
                          <CountUp end={this.state.emissions/1000} duration={1} />K
                        </NftStatNumber> :
                          this.state.emissions > 1 ?
                          <NftStatNumber>
                            <CountUp end={this.state.emissions} duration={1} />
                          </NftStatNumber> :
                          <NftStatNumber>
                            {this.state.emissions.toFixed(3)}
                          </NftStatNumber>

                      }
                      <NftStatLabel>CO2 Emissions</NftStatLabel>
                      <NftStatUnit>KG</NftStatUnit>
                    </NftStat>
                    <NftStat>
                        {this.state.gas > 1000000 ?
                          <NftStatNumber>
                            <CountUp start={this.state.gas/1000000 - 20} end={this.state.gas/1000000} duration={2} />M
                          </NftStatNumber> :
                          <NftStatNumber>
                            <CountUp end={this.state.gas/1000} duration={2} />K
                          </NftStatNumber>
                        }
                      <NftStatLabel>Gas Used</NftStatLabel>
                      <NftStatUnit>Units</NftStatUnit>
                    </NftStat>
                    <NftStat>
                      <NftStatNumber><CountUp end={this.state.transactions} duration={3} /></NftStatNumber>
                      <NftStatLabel>Transactions</NftStatLabel>
                    </NftStat>
                  </NftStats>
                }
                <NftMethod>
                  <NftMethodTitle>
                    How is this calculated?
                  </NftMethodTitle>
                  <NftMethodCopy>
                    Emissions are calculated based on the energy consumption of the <a href="http://ethereumenergyconsumption.com/" target="_blank">Ethereum network</a> as detailed in our <a href="/ethereum-footprint" target="_blank">whitepaper</a> and reflect reductions after <a href="https://ethereum.org/en/upgrades/merge/" target="_blank">The Merge</a>. For inquiries please contact us at <a href="mailto:nft@aerial.is">nft@aerial.is</a>.
                  </NftMethodCopy>
                </NftMethod>
                {!isMobile &&
                  <NftStartOver onClick={() => window.location=(this.props.bsc ? '/bsc' : '/nft')}><NftStartOverIcon />Start Over</NftStartOver>
                }
              </NftLeft>
              <NftRight>
                <NftOffsetContainer className={this.state.success != null ? "appear" : ""}>
                  {this.state.success ?
                    <NftOffsetInner className="success">
                      <NftOffsetCredits>{this.state.credits_purchased || this.state.credits}</NftOffsetCredits>
                      <NftOffsetLabel>Carbon Credits Purchased</NftOffsetLabel>
                    </NftOffsetInner> :
                    <NftOffsetInner>
                      <NftOffsetCredits>{this.state.credits_required}</NftOffsetCredits>
                      <NftOffsetLabel>{this.state.credits_purchased > 0 ? "More " : ""}Carbon Credits Required</NftOffsetLabel>
                      <NftOffsetCopy>Securely purchase verified carbon credits to offset this NFT’s footprint.</NftOffsetCopy>
                      <NftPaymentOptions>
                        <NftPaymentOption onClick={() => this.setState({payment_option: 1})} className={this.state.payment_option == 1 ? 'active' : ''}>
                          <NftPaymentCost>{this.state.ethToUsd ? (Math.ceil(this.state.credits_required) * 20 / this.state.ethToUsd).toFixed(3) : ''} ETH</NftPaymentCost>
                          <NftPaymentMethod>Pay with Coinbase</NftPaymentMethod>
                        </NftPaymentOption>
                        <NftPaymentOption onClick={() => this.setState({payment_option: 0})} className={this.state.payment_option == 0 ? 'active' : ''}>
                          <NftPaymentCost>${Math.ceil(this.state.credits_required) * 20}</NftPaymentCost>
                          <NftPaymentMethod>Pay with Stripe</NftPaymentMethod>
                        </NftPaymentOption>
                      </NftPaymentOptions>
                      <NftCheckoutButton onClick={this.checkout.bind(this)}>
                        Offset
                      </NftCheckoutButton>
                    </NftOffsetInner>
                  }
                </NftOffsetContainer>
                <NftOffsetContainer className={this.state.success == false && this.state.credits_purchased > 0 ? "appear purchased" : ((this.state.success == false || this.state.success == true && this.state.credits_purchased > 0) ? "hide" : "")} style={{'margin-top': 40}}>
                    <NftOffsetInner className="success">
                      <NftOffsetCredits>{this.state.credits_purchased}</NftOffsetCredits>
                      <NftOffsetLabel>Carbon Credits Purchased</NftOffsetLabel>
                    </NftOffsetInner>
                </NftOffsetContainer>
              </NftRight>
              {isMobile &&
                <NftStartOver onClick={() => window.location='/nft'}><NftStartOverIcon />Start Over</NftStartOver>
              }
            </NftResultContainer>
          </NftInner>
        </NftStep>
    )
  }
}

export default withRouter(NftResult);
