import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
import { apiEndpoint } from './prismic-configuration'
import { Home, Page, Crypto, Nft, NftEmbed, NftEmbedDemo, Shopify, Preview, Offset, Team, NotFound } from './pages'
import { ParallaxProvider } from 'react-scroll-parallax';
import { GlobalStyle } from './styles/GlobalStyle';
import UserProvider from './UserProvider';
import TeamProvider from './TeamProvider';


/**
 * Main application componenet
 */
const App = (props) => {
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint)
  const repoName = repoNameArray[1]

  return (
    <Fragment>
      <GlobalStyle/>
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
      <TeamProvider>
        <UserProvider>
          <BrowserRouter>
            <ParallaxProvider>
              <Switch>
                <Route exact path='/' component={Home} />
                <Route exact path='/nft/embed' component={NftEmbed} />
                <Route exact path='/bsc/embed' component={NftEmbed} />
                <Route exact path='/nft/embeddemo' component={NftEmbedDemo} />
                <Route exact path='/nft/:address?' component={Nft} />
                <Route exact path='/crypto/:address?' component={Crypto} />
                <Route exact path='/footprint' component={Offset} />
                <Route exact path='/teams/:shortcode?' component={Team} />
                <Route exact path='/shopify' component={Shopify} />
                <Route exact path='/bsc/:address?' component={Nft} />
                <Route exact path='/preview' component={Preview} />
                <Route exact path='/:uid' component={Page} />
                <Route component={NotFound} />
              </Switch>
            </ParallaxProvider>
          </BrowserRouter>
        </UserProvider>
      </TeamProvider>
    </Fragment>
  )
}

export default App
