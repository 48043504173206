import React, { Suspense, useEffect, useRef, useMemo, useState } from "react";
import { CSSTransition } from "react-transition-group";
import VisibilitySensor from "react-visibility-sensor";
import { ByWord } from "../text/animetext";
import CountUp from "react-countup";

import {
  BusinessContainer,
  BusinessInner,
  BusinessContent,
  BusinessGlobe,
  BusinessTitle,
  BusinessHeader,
  BusinessCopy,
  BusinessGraphs,
  BusinessLink,
  BusinessParallax,
  BusinessGraph1,
  BusinessGraph2,
} from "./BusinessStyles";

class Business extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      appear: false,
      tally: 10000,
    };
  }

  componentDidMount() {
    fetch("/_tally")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            tally: result.tally,
          });
        },
        (error) => {}
      );
  }

  render() {
    return (
      <VisibilitySensor
        key={"Business"}
        partialVisibility={true}
        offset={{ bottom: window.innerHeight / 4 }}
        onChange={(isVisible) => isVisible && this.setState({ appear: true })}
      >
        <BusinessContainer
          className={this.state.appear ? "appear" : ""}
          name="business"
        >
          <BusinessInner>
            <BusinessContent>
              <BusinessTitle>For Enterprise</BusinessTitle>
              <BusinessHeader>
                {this.state.appear && <ByWord text="Aerial for Teams" />}
              </BusinessHeader>
              <BusinessCopy>
                Aerial for Teams offers an easy way to track and offset the
                emissions of everyone in your organization with just a few
                clicks.
              </BusinessCopy>
            </BusinessContent>
            <BusinessGraphs>
              <BusinessParallax y={[5, -5]}>
                <BusinessGraph2 />
              </BusinessParallax>
              <BusinessParallax y={[10, -10]}>
                <BusinessGraph1 />
              </BusinessParallax>
            </BusinessGraphs>
          </BusinessInner>
        </BusinessContainer>
      </VisibilitySensor>
    );
  }
}

export default Business;
