// In src/pages/Page.js
import React, { useEffect, useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { client, linkResolver } from '../prismic-configuration'
import NotFound from './NotFound'

import Hero from '../components/hero/Hero';
import Intro from '../components/intro/Intro';
import App from '../components/app/App';
import Business from '../components/business/Business';
import Project from '../components/project/Project';
import NftPromo from '../components/nft/NftPromo';
import Press from '../components/press/Press';
import Praise from '../components/praise/Praise';
import Team from '../components/team/Team';
import Signup from '../components/signup/Signup';
import PageBody from '../components/page/PageBody';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

const Home = () => {
  const [doc, setDocData] = useState(null)
  const [notFound, toggleNotFound] = useState(false)

  // this.state = {
  //   hasAppeared: {}
  // }


  // appear((isVisible, section) => {
  //   if (isVisible) {
  //     let hasAppeared = this.state.hasAppeared;
  //     hasAppeared[section] = true;
  //     this.setState({hasAppeared});
  //   }
  // })

  // Get the page document from Prismic
  useEffect(() => {
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getSingle('homepage')

      if (result) {
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
        toggleNotFound(true)
      }
    }
    fetchData()
  }, [])

  if (doc) {
    return (
      <div>
        <Hero
          statement={RichText.asText(doc.data.statement)}
          intro={RichText.asText(doc.data.intro)}
          backgroundVideo="https://storage.googleapis.com/aerial-app.appspot.com/aerial_illustration_short_HD.mp4"
          mask={16} />
        <Intro title={RichText.asText(doc.data.intro_title)} copy={RichText.asText(doc.data.intro_copy)} pressLogos={doc.data.press_logos}/>
        <App/>
        <NftPromo homepage={true} artists={doc.data.featured_artists} partners={doc.data.nft_partners} />
        <Business/>
        <Praise
          praise={doc.data.praise}
        />
        <Project
          projects={doc.data.projects}
          home={true}
        />
        <Team
          team={doc.data.team}
        />
        <Header showLinks={true} />
        <Footer/>
      </div>
    )
  } else if (notFound) {
    return <NotFound />
  }
  return null
}

export default Home
