import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { ByWord } from "../text/animetext";
import VisibilitySensor from "react-visibility-sensor";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  AppContainer,
  AppContent,
  AppPhoneContainer,
  AppPhone,
  AppPhoneScreenshot,
  AppTitle,
  AppHeader,
  AppCopy,
  AppTour,
  AppTourDots,
  AppTourDot,
  AppPhoneContent,
  AppSlideContent,
  AppSlideTitle,
  AppSlideCopy,
  AppCloudsContainer,
  AppClouds,
  AppStoreLink,
  AppStoreIcon,
  AppStoreLinks,
  AndroidLink,
  AndroidIcon,
} from "./AppStyles";

class App extends React.Component {
  state = {
    appear: false,
    hasAppeared: {
      "slide-1": isMobile,
    },
    currentSlide: 1,
    scrollOffset: 0,
    scrollDown: true,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = document.documentElement.scrollTop;
    let scrollDown = scrollTop > this.state.scrollOffset;

    this.setState({
      scrollDown: scrollDown,
      scrollOffset: scrollTop,
    });
  }

  appear(isVisible, section, index) {
    if (isMobile && !this.state.appear) {
      return;
    }
    let hasAppeared = this.state.hasAppeared;
    hasAppeared[section] = isVisible;
    let currentSlide =
      isVisible &&
      (this.state.scrollDown ||
        (!this.state.scrollDown && index < this.state.currentSlide))
        ? index
        : this.state.currentSlide;

    this.setState({
      hasAppeared,
      currentSlide,
    });
  }

  render() {
    return (
      <VisibilitySensor
        key={"app"}
        partialVisibility={true}
        offset={{ bottom: window.innerHeight / 4 }}
        onChange={(isVisible) => isVisible && this.setState({ appear: true })}
      >
        <AppContainer className={this.state.appear ? "appear" : ""} name="app">
          <AppCloudsContainer>
            <AppClouds />
          </AppCloudsContainer>
          <AppContent>
            <AppTitle>For Everyone</AppTitle>
            <AppHeader>
              {this.state.appear && <ByWord text="The Aerial App" />}
            </AppHeader>
            <AppCopy>
              The Aerial app is the easiest and most accurate way to track and
              offset your personal carbon footprint.
            </AppCopy>
            <AppStoreLinks>
              <AppStoreLink>
                <AppStoreIcon />
              </AppStoreLink>
              <AndroidLink>
                <AndroidIcon />
              </AndroidLink>
            </AppStoreLinks>
          </AppContent>
          <AppTour>
            <AppPhoneContainer className={"slide-" + this.state.currentSlide}>
              <AppPhone>
                <AppPhoneScreenshot className="slide-1" />
                <AppPhoneScreenshot className="slide-2" />
                <AppPhoneScreenshot className="slide-3" />
                <AppTourDots>
                  <AppTourDot
                    className={this.state.currentSlide == 1 ? "active" : ""}
                  />
                  <AppTourDot
                    className={this.state.currentSlide == 2 ? "active" : ""}
                  />
                  <AppTourDot
                    className={this.state.currentSlide == 3 ? "active" : ""}
                  />
                </AppTourDots>
              </AppPhone>
            </AppPhoneContainer>
            <AppPhoneContent className={"slide-" + this.state.currentSlide}>
              <VisibilitySensor
                key={"app-slide-1"}
                partialVisibility={true}
                offset={{
                  bottom: isMobile ? 0 : window.innerHeight / 3,
                  top: window.innerHeight / 2,
                }}
                onChange={(isVisible) => this.appear(isVisible, "slide-1", 1)}
              >
                <AppSlideContent
                  className={this.state.hasAppeared["slide-1"] ? "appear" : ""}
                >
                  <AppSlideTitle>Track Your Footprint</AppSlideTitle>
                  <AppSlideCopy>
                    We accurately calculate how much carbon you release into the
                    atmosphere.
                  </AppSlideCopy>
                </AppSlideContent>
              </VisibilitySensor>
              <VisibilitySensor
                key={"app-slide-2"}
                partialVisibility={true}
                offset={{
                  bottom: isMobile ? 0 : window.innerHeight / 3,
                  top: window.innerHeight / 2,
                }}
                onChange={(isVisible) => this.appear(isVisible, "slide-2", 2)}
              >
                <AppSlideContent
                  className={this.state.hasAppeared["slide-2"] ? "appear" : ""}
                >
                  <AppSlideTitle>Offset Your Emissions</AppSlideTitle>
                  <AppSlideCopy>
                    Instantly offset your carbon emissions by contributing to
                    verified environmental projects.
                  </AppSlideCopy>
                </AppSlideContent>
              </VisibilitySensor>
              <VisibilitySensor
                key={"app-slide-3"}
                partialVisibility={true}
                offset={{
                  bottom: isMobile ? 0 : window.innerHeight / 3,
                  top: window.innerHeight / 2,
                }}
                onChange={(isVisible) => this.appear(isVisible, "slide-3", 3)}
              >
                <AppSlideContent
                  className={this.state.hasAppeared["slide-3"] ? "appear" : ""}
                >
                  <AppSlideTitle>Get Informed</AppSlideTitle>
                  <AppSlideCopy>
                    Gain actionable climate insights with our exclusive content
                    by credible science writers.
                  </AppSlideCopy>
                </AppSlideContent>
              </VisibilitySensor>
            </AppPhoneContent>
          </AppTour>
        </AppContainer>
      </VisibilitySensor>
    );
  }
}

export default App;
