import React, { useEffect, useState } from 'react';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  OffsetActivityContainer,
  OffsetActivityIcon,
  OffsetActivityInfo,
  OffsetActivityLabel,
  OffsetActivityDetails,
  OffsetActivityEmissions,
  OffsetActivityDistance,
  OffsetActivityDate,
  OffsetActivityChecked,
  OffsetActivityOptions,
  OffsetActivityMenu,
  OffsetActivityMenuItem
} from './OffsetStyles';



class OffsetActivity extends React.Component {

  state = {
    show: false,
    showMenu: false
  }

  constructor(props) {
    super(props);

    if (!this.props.offset) {
      this.wrapperRef = React.createRef();
      this.handleClickOutside = this.handleClickOutside.bind(this);
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        show: true
      })
    }, 100);

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.state.showMenu) {
        this.setState({showMenu: false})
      }
    }
  }

  getDate() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(this.props.activity.start)
    return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
  }

  removeActivity() {
    this.props.activity.isDeleted = true;
    fetch('/_removeActivity', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ activity: this.props.activity.id })
    })
    .then((res) => res.json())
    .then(success => {
      this.props.onRemove();
    })
  }

  render() {
    return (
        <OffsetActivityContainer className={(this.state.show && !this.props.activity.isDeleted ? "show": "") + (this.state.showMenu ? " showMenu" : "")}>
          <OffsetActivityIcon className={this.props.activity.type + (this.props.offset ? " offset" : "")}/>
          <OffsetActivityInfo>
            <OffsetActivityLabel>
              {this.props.activity.label}
            </OffsetActivityLabel>
            <OffsetActivityDetails>
              <OffsetActivityEmissions>
                {Math.ceil(this.props.activity.emission).toLocaleString()}kg
              </OffsetActivityEmissions>
              <OffsetActivityDistance>
                {Math.ceil(this.props.activity.consumption).toLocaleString()}{this.props.activity.consumption_unit}
              </OffsetActivityDistance>
              <OffsetActivityDate>
                {this.getDate()}
              </OffsetActivityDate>
            </OffsetActivityDetails>
          </OffsetActivityInfo>
          {!this.props.offset &&
            <OffsetActivityOptions onClick={() => {this.setState({showMenu: !this.state.showMenu})}}>
              <OffsetActivityMenu ref={this.wrapperRef}>
                <OffsetActivityMenuItem className="remove" onClick={this.removeActivity.bind(this)}>
                  Remove Activity
                </OffsetActivityMenuItem>
              </OffsetActivityMenu>
            </OffsetActivityOptions>
          }
        </OffsetActivityContainer>
      )
    }
}

export default OffsetActivity;
