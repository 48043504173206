import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H1, h1, H2, h2, H3, H4, H5, P } from '../../styles/Typography';
import { panBackground } from '../../styles/Animations';
import { breakpoints } from '../../styles/Breakpoints';
import gradient from '../../assets/images/gradient.png';
import clouds from '../../assets/images/clouds.png';
import next from '../../assets/images/icons/next.svg';
import cup from '../../assets/images/3d/cup.png';
import tree1 from '../../assets/images/3d/tree1.png';
import tree2 from '../../assets/images/3d/tree2.png';
import coinbase from '../../assets/images/coinbase.svg';
import lock from '../../assets/images/icons/lock.svg';
import prev from '../../assets/images/icons/prev.svg';
import whiteLogo from '../../assets/images/logos/white.svg';
import colorLogo from '../../assets/images/logos/color.svg';
import colorLockup from '../../assets/images/logos/color-lockup.svg';
import whiteLockup from '../../assets/images/logos/white-lockup.svg';

export const CryptoContainer = styled.div`
  width: 100vw;
  overflow: hidden;
  background: ${gradients.soft};
  background-image: url(${gradient});
  background-size: contain;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${clouds});
    background-size: cover;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;
  }
`;

export const CryptoSteps = styled.div`
  position: relative;
  height: 75vh;
  transition: transform 2.5s ease;
  z-index: 2;

  ${CryptoContainer}.result & {
    transform: translate(0, -100%);
  }

  @media ${breakpoints.short} {
    height: 100vh;
  }

  @media ${breakpoints.iPadW} {
    margin: 0 48px;
    height: 75vh;
  }

  @media ${breakpoints.iPadH} {
    margin: 0 32px;
  }

  @media ${breakpoints.iPhone} {
    height: 70vh;
    min-height: 570px;
    margin: 64px 0 40px;
  }
`;


export const CryptoStep = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease;
  display: flex;
  opacity: 0;


  ${CryptoContainer}.input & {
    &.input {
      opacity: 1;
    }
    &.result {
      opacity: 0;
    }
  }

  ${CryptoContainer}.result & {
    &.result {
      opacity: 1;
    }
    &.input {
      opacity: 0;
    }
  }
`;

export const CryptoInner = styled.div`
  max-width: 1200px;
  margin: auto;

  @media ${breakpoints.narrow} {
    margin: auto 40px;
  }

  @media ${breakpoints.iPadW} {
    margin: auto 32px;
  }

  ${CryptoStep} & {
    width: 100%;

    @media ${breakpoints.iPadW} {
      margin: auto 0;
    }

    @media ${breakpoints.iPhone} {
      width: auto;
      margin: auto 32px;
    }
  }
`;

export const CryptoTitleContainer = styled.div`
  /* height: 94px; */
`;

export const CryptoTitle = styled.div`
  div {
   ${h1}
   color: white;
   margin: 0;
   margin-bottom: 16px;
   transition: opacity .3s ease, filter 1s ease;
   transition-delay: 0.6s;

   span {
     background: ${gradients.purple};
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
   }

   @media ${breakpoints.iPadW} {
     font-size: 36px;
     line-height: 48px;
   }
   @media ${breakpoints.iPhone} {
     font-size: 32px;
     line-height: 36px;
   }

  }

 padding-bottom: 10px;

 @media ${breakpoints.iPhone} {
   padding: 0;
 }

 ${CryptoContainer}.onload-appear & {
   opacity: 0;
   filter: blur(4px);
 }
 ${CryptoContainer}.onload-appear-done & {
   opacity: 1;
   filter: blur(0);
 }
`;

export const CryptoCopy = styled(P)`
  color: ${colors.purple};
  max-width: 500px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  ${CryptoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const CoinbaseConnectButton = styled.div`
  background: white;
  border-radius: 72px;
  position: relative;
  text-align: center;
  color: #0052FF;
  font-size: 18px;
  padding: 8px;
  display: flex;
  align-items: center;
  width: 280px;
  margin: 24px 0;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(98, 91, 140, .1);

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  ${CryptoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const CoinbaseConnectIcon = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  background-image: url(${coinbase});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
`;

export const CryptoPolicy = styled.div`
  font-size: 14px;
  color: ${colors.midgray};
  max-width: 300px;
  line-height: 1.5em;
  position: relative;
  padding-left: 30px;
  color: #38758E;

  a {
    color: #38758E;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .6s;

  ${CryptoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:before {
    content: "";
    background-image: url(${lock});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const CryptoAddress = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  font-size: 14px;
  padding: 8px;
  display: inline-block;
  color: ${colors.purple2};
  font-family: 'Source Code Pro', monospace;
  position: relative;
  z-index: 2;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.2s;

  ${CryptoInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    font-size: 11px;
  }
`;

export const CryptoResultContainer = styled.div`
  display: flex;

  @media ${breakpoints.iPhone} {
    flex-direction: column;
  }
`;

export const CryptoStats = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  padding: 40px 0;
  position: relative;
  z-index: 2;
  padding-right: 16px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.4s;

  ${CryptoInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 16px 0;
  }
`;

export const CryptoStat = styled.div`
  color: ${colors.purple2};
`;

export const CryptoStatNumber = styled.div`
  font-size: 48px;
  font-weight: ${fontWeights.light};

  @media ${breakpoints.iPadW} {
    font-size: 36px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 24px;
  }
`;

export const CryptoStatLabel = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.medium};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 16px;
  }
`;

export const CryptoStatUnit = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;

export const CryptoLeft = styled.div`
  width: 60%;
  position: relative;
  z-index: 0;

  &.error {
    width: 100%;
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
  }
`;

export const CryptoRight = styled.div`
  width: 40%;
  z-index: 2;
  position: relative;

  @media ${breakpoints.iPhone} {
    width: 100%;
    margin-top: 32px;
  }
`;

export const CryptoError = styled.div`
  color: ${colors.purple2};
  font-size: 18px;
`;

export const CryptoStartOver = styled.div`
  color: ${colors.purple2};
  border: 1px solid ${colors.purple2};
  border-radius: 32px;
  position: relative;
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 8px;
  display: flex;
  align-items: center;
  width: 140px;
  cursor: pointer;
  margin-top: 32px;
`;

export const CryptoStartOverIcon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(${prev});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
`;

export const CryptoOffsetContainer = styled.div`
  background: rgba(255, 255, 255, .75);
  border-radius: 32px;
  width: 100%;

  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 1s;

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const CryptoOffsetInner = styled.div`
  padding: 32px;
  position: relative;

  &.success {
    &:after {
      content: "";
      background-image: url(${cup});
      background-size: contain;
      background-repeat: no-repeat;
      width: 75px;
      padding-top: 34%;
      position: absolute;
      bottom: 32px;
      right: 32px;

      @media ${breakpoints.iPadW} {
        bottom: 16px;
        right: 16px;
        padding-top: 46%;
      }

      @media ${breakpoints.iPhone} {
        width: 50px;
        padding-top: 33%;
        bottom: 16px;
        right: 16px;
      }
    }
  }

  @media ${breakpoints.iPadH} {
    padding: 16px;
  }

`;

export const CryptoOffsetCredits = styled.div`
  font-size: 48px;
  font-weight: ${fontWeights.light};
  color: ${colors.purple};

  @media ${breakpoints.iPadW} {
    font-size: 36px;
  }
`;

export const CryptoOffsetLabel = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
    width: 80%;
  }

  @media ${breakpoints.iPhone} {
    font-size: 16px;
    width: initial;
  }
`;

export const CryptoOffsetCopy = styled(P)`
  color: ${colors.midgray};

  @media ${breakpoints.iPadW} {
    font-size: 14px;
  }
`;

export const CryptoPaymentOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0 16px;
`;

export const CryptoPaymentOption = styled.div`
  border-radius: 18px;
  color: ${colors.midgray};
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
  width: calc(50% - 4px);
  cursor: pointer;
  transition: background .3s ease, color .3s ease, box-shadow .3s ease;

  &:first-child {
    margin-right: 8px;
  }

  &.active {
    color: ${colors.purple};
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .08);
  }

  @media ${breakpoints.iPadH} {
    padding: 16px 8px;
  }
`;

export const CryptoPaymentCost = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.bold};

  @media ${breakpoints.iPadH} {
    font-size: 16px;
  }
`;

export const CryptoPaymentMethod = styled(P)`
  margin: 0;

  @media ${breakpoints.laptop} {
    font-size: 16px;
  }

  @media ${breakpoints.iPadW} {
    font-size: 14px;
  }

  @media ${breakpoints.iPadH} {
    font-size: 12px;
  }
`;

export const CryptoCheckoutButton = styled.button`
  background-color: ${colors.purple};
  font-size: 20px;
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.black};
  color: white;
  display: block;
  border-radius: 16px;
  border: 0;
  padding: 16px 0;
  width: 100%;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

export const CryptoArtistsContainer = styled.div`
  border-radius: 32px;
  position: relative;
  z-index: 2;
  overflow: hidden;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 1s;

  ${CryptoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 8px;
  }
`;

export const CryptoArtistsHeader = styled(H3)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 32px auto;
`;

export const CryptoArtistsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: black;
  border-radius: 32px;
  position: relative;
  z-index: 2;
  overflow: hidden;


  @media ${breakpoints.iPhone} {
    flex-direction: column;
  }
`;

export const CryptoArtist = styled.a`
  width: calc(100%/3);
  padding-top: 33%;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
    padding-top: 100%;
  }
`;

export const CryptoArtistImage = styled.div`
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: transform .3s ease;

  ${CryptoArtist}:hover & {
    transform: scale(1.05);
  }
`;

export const CryptoArtistInfo = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: white;
  z-index: 2;
`;

export const CryptoArtistName = styled(H4)`
  margin: 0;
`;

export const CryptoArtistHost = styled(P)`
  margin: 0;
`;

export const CryptoMethod = styled.div`
  color: ${colors.purple2};
  max-width: 400px;

  @media ${breakpoints.iPhone} {
    margin-bottom: 32px;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.6s;

  ${CryptoInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const CryptoMethodTitle= styled(H4)`
  font-size: 20px;
  font-weight: ${fontWeights.medium};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
`;

export const CryptoMethodCopy = styled(P)`
  font-size: 16px;

  a {
    color: ${colors.purple2};
  }

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const CryptoEmbedContainer = styled.a`
  width: 200px;
  height: 54px;
  border-radius: 16px;
  background: ${colors.lilac};
  /* border: 1px solid ${colors.purple}; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-decoration: none;
  border: 1px solid rgb(108 82 251 / 20%);
  /* background-image: url(${gradient}); */
  position: relative;
  /* background-size: contain; */
  margin: 20px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${clouds});
    overflow: hidden;
    border-radius: 16px;
    background-size: 150%;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;
  }

  &.dark {
    background: linear-gradient(45deg, #18103a, #725add);
    border: none;
  }
  &.wide {
    height: 36px;
    border-radius: 5px;
    margin: 0;
    &:after {
      border-radius: 5px;
    }
  }
`;

export const CryptoEmbedIcon = styled.div`
  background-image: url(${tree1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 60px;
  width: 40px;
  bottom: 3px;
  margin-right: 12px;
  position: relative;
  z-index: 1;

  ${CryptoEmbedContainer}.dark & {
    background-image: url(${tree2});
  }

  ${CryptoEmbedContainer}.wide & {
    display: none;
  }

  ${CryptoEmbedContainer}.offset & {
    background-image: url(${cup});
    height: 60px;
    width: 40px;
    bottom: 3px;
    background-position: top;
    background-size: 80%;
  }
`;

export const CryptoEmbedLogo = styled.div`
  background-image: url(${colorLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  z-index: 1;

  ${CryptoEmbedContainer}.dark & {
    background-image: url(${whiteLogo});
  }
  ${CryptoEmbedContainer}.wide & {
    width: 60px;
    background-image: url(${colorLockup});
  }
`;

export const CryptoEmbedContent = styled.div`
  background: ${gradients.purple};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: ${fontWeights.black};
  border-right: 1px solid rgb(108 82 251 / 20%);
  margin-right: 16px;
  position: relative;
  width: 115px;
  z-index: 1;

  ${CryptoEmbedContainer}.dark & {
    color: white;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    border-right: 1px solid rgb(255 255 255 / 20%);
  }

  ${CryptoEmbedContainer}.wide & {
    width: auto;
    border-right: none;
    margin-right: 8px;
  }
`;
