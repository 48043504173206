import React, { useEffect, useState } from 'react';
import VisibilitySensor from "react-visibility-sensor";

import {
  PromoContainer,
  PromoContent,
  PromoTitle,
  PromoCopy,
  PromoButton,
  PromoParallax,
  PromoBackground
} from './PromoStyles';


class Promo extends React.Component {
  state = {
    appear: false
  }

  render() {
    return (
      <VisibilitySensor
        key={'promo'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/4}}
        onChange={isVisible => isVisible && this.setState({appear: true})}>
        <PromoContainer className={(this.state.appear ? 'appear' : '')}>
          <PromoParallax y={[10, -10]}>
            <PromoBackground />
          </PromoParallax>
          <PromoContent>
            <PromoTitle>
              Live sustainably, every day
            </PromoTitle>
            <PromoCopy>
              Get the Aerial app to track and offset your emissions.
            </PromoCopy>
            <PromoButton href="https://www.aerial.is">
              Learn More
            </PromoButton>
          </PromoContent>
        </PromoContainer>
      </VisibilitySensor>
    )
  }
}

export default Promo;
