import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import VisibilitySensor from "react-visibility-sensor";
import Tilt from "react-tilt";
import Press from "../press/Press";
import {
  IntroContainer,
  IntroContent,
  IntroTitle,
  IntroCopy,
  IntroLeft,
  IntroRight,
  IntroTitleContainer,
  IntroAssetContainer,
  IntroVideo,
  IntroParallax,
  IntroButton,
} from "./IntroStyles";

class Intro extends React.Component {
  state = {
    hasAppeared: {},
    appear: true,
  };

  appear(isVisible, section) {
    if (isVisible) {
      let hasAppeared = this.state.hasAppeared;
      hasAppeared[section] = true;
      this.setState({ hasAppeared });
    }
  }

  render() {
    return (
      <VisibilitySensor
        key={"intro"}
        partialVisibility={true}
        offset={{ top: 0 }}
        onChange={(isVisible) => this.appear(isVisible, "intro")}
      >
        <IntroContainer
          className={this.state.hasAppeared["intro"] ? "appear" : ""}
        >
          <IntroContent>
            <IntroTitleContainer>
              <IntroTitle>{this.props.title}</IntroTitle>
              <IntroCopy>{this.props.copy}</IntroCopy>
            </IntroTitleContainer>
          </IntroContent>
          <Press logos={this.props.pressLogos} />
        </IntroContainer>
      </VisibilitySensor>
    );
  }
}

export default Intro;
