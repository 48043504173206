import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, h2, H2, H3, h3, H5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import { panBackground } from '../../styles/Animations';
import clouds from '../../assets/images/clouds-2.png';
import next from '../../assets/images/icons/forward.svg';
import graph1 from '../../assets/images/screenshots/business-1.svg';
import graph2 from '../../assets/images/screenshots/business-2.png';

export const BusinessContainer = styled.div`
  height: 80vh;
  overflow: hidden;
  transition: opacity .3s ease;
  position: relative;
  color: ${colors.light};
  background: ${gradients.midnight};
  overflow: hidden;
  perspective: 1000px;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background-image: url(${clouds});
    background-size: cover;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;

    @media ${breakpoints.iPhone} {
      animation: ${panBackground} 30s linear infinite;
    }
  }

  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }

  @media ${breakpoints.iPadProH} {
    height: 90vh;
  }

  @media ${breakpoints.iPadW} {
    height: 80vh;
  }

  @media ${breakpoints.iPhone} {
    height: 110vh;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

export const BusinessInner = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  min-height: 80vh;


  @media ${breakpoints.iPadProH} {
    flex-direction: column;
    min-height: 0;
    margin: 0;
  }
`;

export const BusinessContent = styled.div`
  width: 55%;
  position: relative;

  @media ${breakpoints.laptop} {
    margin-left: 80px;
  }

  @media ${breakpoints.iPadProH} {
    width: 70%;
    margin: 0;
  }

  @media ${breakpoints.iPadH} {
    padding-top: 80px;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
    padding: 64px 32px 0;
    margin-left: 0;
  }
`;

export const BusinessTitle = styled(H5)`
  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  ${BusinessContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const BusinessHeader = styled.div`
  div {
    position: relative;
    display: inline-block;
    ${h2};

    @media ${breakpoints.iPadW} {
      ${h3}
    }

    @media ${breakpoints.iPhone} {
      ${h3}
    }
`;

export const BusinessCopy = styled(P)`
  padding-right: 32px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${BusinessContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding-right: 0;
  }
`;

export const BusinessGraphs = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45%;
  height: 80vh;
  z-index: 2;

  @media ${breakpoints.iPadH} {
    width: 100%;
    height: 50vh;
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
    height: 60vh;
  }
`;

export const BusinessParallax = styled(Parallax)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .parallax-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const BusinessGraph1 = styled.div`
  position: absolute;
  background-image: url(${graph1});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  padding-top: 70%;
  margin-left: 50%;
  margin-top: 20%;
  top: 50%;
  transform: translate(0, -50%);

  opacity: 0;
  transition: opacity 1s ease;
  transition-delay: 0.2s;

  @media ${breakpoints.wide} {
    width: 70%;
    margin-left: 30%;
  }

  @media ${breakpoints.iPadH} {
    width: 40%;
    top: 35%;
    margin-left: 45%;
  }

  @media ${breakpoints.iPhone} {
    width: 85%;
    margin-left: 20%;
    top: 55%;
  }

  ${BusinessContainer}.appear & {
    opacity: 1;
  }
`;

export const BusinessGraph2 = styled.div`
  position: absolute;
  background-image: url(${graph2});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 130%;
  padding-top: 100%;
  top: 50%;
  transform: translate(0, -50%);

  opacity: 0;
  transition: opacity 1s ease;
  transition-delay: 0.3s;

  @media ${breakpoints.wide} {
    width: 70%;
  }

  @media ${breakpoints.iPadH} {
    width: 40%;
    top: 45%;
    left: 20%;
  }

  @media ${breakpoints.iPhone} {
    width: 85%;
    left: 0;
    top: 55%;
  }

  ${BusinessContainer}.appear & {
    opacity: 1;
  }
`;

export const BusinessLink = styled.a`
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  color: white;
  text-decoration: none;
  position: relative;
  margin-top: 16px;
  display: inline-block;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .4s;

  ${BusinessContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:hover {
    &:after {
      transform: translate(4px, -50%);
    }
  }

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${next});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    margin-left: 4px;
    transform: translate(0, -50%);
    transition: transform .2s ease;
  }
`;
