import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H1, h1, H2, h2, H3, h3, H4, H5, h5, P } from '../../styles/Typography';
import { panBackground } from '../../styles/Animations';
import { breakpoints } from '../../styles/Breakpoints';
import background from '../../assets/images/promo.png';
import clouds from '../../assets/images/clouds.png';
import { Parallax } from 'react-scroll-parallax';

export const PromoContainer = styled.div`
  max-width: 1200px;
  margin: auto;
  background-color: ${colors.lilac};
  padding-top: 25%;
  border-radius: 32px;
  display: flex;
  align-items: center;
  margin: 0 auto 80px;
  overflow: hidden;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity .6s ease, transform .6s ease;

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.narrow} {
    margin: auto 40px;
    padding-top: 33%;
  }

  @media ${breakpoints.iPadW} {
    margin: 0 32px;
    margin-bottom: 32px;
    padding-top: 33%;
  }

  @media ${breakpoints.iPhone} {
    padding-top: 150%;
  }
`;

export const PromoContent = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 64px;

  @media ${breakpoints.iPhone} {
    left: 16px;
    top: 32px;
    transform: none;
  }
`;

export const PromoTitle = styled.div`
  ${h2};
  margin: 0;
  font-weight: ${fontWeights.black};
  background: linear-gradient(rgba(108, 82, 251, 1), rgba(108, 82, 251, 0.5));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media ${breakpoints.iPadW} {
    font-size: 36px;
    line-height: 42px;
  }

  @media ${breakpoints.iPadH} {
    font-size: 32px;
    line-height: 36px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 30px;
    line-height: 36px;
  }
`;

export const PromoCopy = styled(P)`
  color: ${colors.purple};

  @media ${breakpoints.iPhone} {
    width: 70%;
  }
`;

export const PromoButton = styled.a`
  border-radius: 30px;
  border: 2px solid ${colors.purple};
  color: ${colors.purple};
  cursor: pointer;
  ${h5};
  line-height: 1em;
  padding: 12px 24px;
  margin-top: 8px;
  display: inline-block;
  text-decoration: none;


  &:hover {
    background: ${colors.purple};
    color: white;
  }
`;

export const PromoParallax = styled(Parallax)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .parallax-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const PromoBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 120%;
  background-size: cover;
  background-position: center right;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url(${background});
  background-size: cover;

  @media ${breakpoints.iPhone} {
    background-position: center;
  }
`;
