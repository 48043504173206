import React, { useEffect, useState, useContext } from 'react';
import UserContext from './UserContext';
import TeamContext from './TeamContext';

// @function  UserProvider
// Create function to provide UserContext
const UserProvider = ({ children }) => {
  const { team, getTeam, resetTeam } = useContext(TeamContext);

  const [user, setUser] = useState({
    id: null,
    activities: [],
    loggedIn: false,
    fetched: false,
    settings: false,
    deleting: false,
    deleted: false,
    share: false
  });

  const login = (userData) => {
    setUser((user) => ({
      ...user,
      ...userData,
      loggedIn: true,
      fetched: false,
      settings: false,
      deleting: false,
      deleted: false
    }));

    if ('is_parsing' in userData && !userData.is_parsing) {
      getActivities()
    }

    getTeam();
  };

  const logout = () => {
    fetch('/_logout', {
      method: 'POST'
    }).then((res) => res.json())
      .then(success => {
        setUser((user) => ({
          activities: [],
          loggedIn: false,
          fetched: false,
          settings: false,
          deleting: false,
          deleted: false,
          share: false
        }));

        setTimeout(() => {
          resetTeam();
        }, 1000);
      });
  };

  const deleteAccount = () => {
    setUser((user) => ({
      ...user,
      settings: true,
      deleting: true,
      deleted: false,
      share: false
    }));

    fetch('/_deleteAccount', {
      method: 'POST'
    }).then((res) => res.json())
      .then(success => {
        setUser((user) => ({
          userData: {},
          activities: [],
          loggedIn: false,
          fetched: false,
          settings: true,
          deleting: true,
          deleted: true,
          share: false
        }));
        setTimeout(() => {
          setUser((user) => ({
            userData: {},
            activities: [],
            loggedIn: false,
            fetched: false,
            settings: false,
            deleting: false,
            deleted: false,
            share: false
          }));

          resetTeam();
        }, 2000);
      });
  };

  const showSettings = (show) => {
    setUser((user) => ({
      ...user,
      settings: show
    }));
  };

  const showShare = (show) => {
    setUser((user) => ({
      ...user,
      share: show
    }));
  };

  const getUserData = () => {
    fetch('/_user')
      .then((res) => res.json())
      .then(userData => {
        setUser((user) => ({
          ...user,
          ...userData,
          loggedIn: true,
          fetched: userData.is_parsing
        }));

        if (!userData.is_parsing) {
          getActivities()
        }

        getTeam()
      })
      .catch((error) => {
        setUser((user) => ({
          ...user,
          activities: [],
          loggedIn: false,
          fetched: true
        }));
      })
  }

  const getActivities = () => {
    fetch('/_activities')
      .then((res) => res.json())
      .then(activities => {
        setUser((user) => ({
          ...user,
          activities: activities,
          fetched: true
        }));
      })
  }

  useEffect(() => {
    getUserData();
  }, [])

  return (
    <UserContext.Provider value={{ user, login, logout, getUserData, deleteAccount, showSettings, showShare }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
