import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H4, h2, H3, h3, H5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import { Parallax } from 'react-scroll-parallax';
import economist from '../../assets/images/economist.svg';


export const PraiseContainer = styled.div`
  transition: opacity .3s ease;
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: column;
  color: white;
  background: ${gradients.soft};
  padding: 120px 0;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(rgba(253, 250, 255, 1), rgba(253, 250, 255, 0));
    z-index: 0;
  }

  @media ${breakpoints.iPadH} {
    padding: 100px 0;
  }

  @media ${breakpoints.iPadW} {
    padding: 80px 0;
  }

  @media ${breakpoints.iPhone} {
    padding: 32px 0;
  }
`;

export const PraiseContent = styled.div`
  z-index: 2;
  align-items: center;
  text-align: center;

  @media ${breakpoints.wide} {
    padding-bottom: 32px;
  }
  @media ${breakpoints.iPhone} {
    padding-top: 32px;
  }
`;

export const PraiseTitle = styled(H5)`
  color: ${colors.midgray};
  position: relative;
  z-index: 2;

  opacity: 0;
  transform: translate(0, 10%);
  transition: opacity 1s ease, transform 1s ease;

  ${PraiseContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const PraiseParallax = styled(Parallax)`
    /* position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0; */
    position: relative;
    height: 100vh;
    width: 900px;

    .parallax-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const PraiseQuotes = styled.div`
  padding: 32px 0 16px;
  min-width: 900px;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.iPadW} {
    min-width: 800px;
  }

  @media ${breakpoints.iPadH} {
    min-width: 600px;
  }

  @media ${breakpoints.iPhone} {
    min-width: initial;
    padding: 32px 16px 0;
  }
`;


export const PraiseQuoteGroup = styled.div`
  display: inline-block;
  align-self: flex-start;
  perspective: 1000px;

  &:nth-child(2n) {
    align-self: flex-end;
  }
`;


export const PraiseQuoteContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 16px 0;
  transform: scale(0.95) perspective(1000px) rotateX(20deg) translate3d(0, 0, -100px);
  opacity: 0;
  transition: transform 1s ease, opacity 1s ease;

  ${PraiseQuoteGroup}:nth-child(2n) & {
    flex-direction: row-reverse;
  }

  &.show {
    transform: scale(1) rotate(0) translate3d(0, 0, 0);
    opacity: 1;
  }

  @media ${breakpoints.iPhone} {
    margin: 8px 0;
  }
`;

export const PraiseBubble = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 8px 32px;
  border-radius: 36px 36px 0 36px;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, .08), inset 0 0 24px 2px rgba(255, 255, 255, .2);
  background: white;
  max-width: 500px;
  position: relative;

  @media ${breakpoints.iPadH} {
    max-width: 400px;
  }

  @media ${breakpoints.iPhone} {
    padding: 4px 16px;
    max-width: 200px;
  }

  ${PraiseQuoteContainer}:nth-child(2n) & {
    border-radius: 36px 0 36px 36px;
  }

  ${PraiseQuoteGroup}:nth-child(2n) & {
    border-radius: 36px 36px 36px 0;
  }

  ${PraiseQuoteGroup}:nth-child(2n) ${PraiseQuoteContainer}:nth-child(2n) & {
      border-radius: 0 36px 36px 36px;
  }

  &.active {
    background: ${gradients.heavy};
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 1s ease;

    ${PraiseQuoteContainer}.appear & {
      opacity: 0;
    }
  }
`;

export const PraiseCopy = styled(P)`
  text-align: left;
  transition: background 10s ease;
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${PraiseBubble}.active & {
    background: initial;
    color: white;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
  }

  @media ${breakpoints.iPhone} {
    font-size: 14px;
    line-height: 1.5em;
  }
`;

export const PraiseLogo = styled.div`
  background-image: url(${economist});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 140px;
  padding-top: 10%;
  margin: 0 32px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .1s;

  ${PraiseQuoteContainer}.show & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadH} {
    width: 120px;
  }

  @media ${breakpoints.iPhone} {
    margin: 0 16px;
    width: 100px;
  }
`;

export const PraiseReviewer = styled(H5)`
  color: ${colors.purple};
  margin: 0 32px;
  text-align: left;
  font-size: 12px;
  width: 140px;

  &:before {
    content: "★★★★★";
    font-size: 24px;
    display: block;
    line-height: 12px;

    @media ${breakpoints.iPadH} {
      font-size: 20px;
    }

    @media ${breakpoints.iPhone} {
      font-size: 14px;
    }
  }

  @media ${breakpoints.iPadH} {
    width: 120px;
    font-size: 10px;
  }

  @media ${breakpoints.iPhone} {
    margin: 0 16px;
    font-size: 7px;
    width: 100px;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .1s;

  ${PraiseQuoteContainer}.show & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const PraiseStars = styled.div`
  font-size: 24px;
`;
