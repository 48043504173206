import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import PrismicReact, { Link, RichText, Date } from "prismic-reactjs";
import VisibilitySensor from "react-visibility-sensor";
import Tilt from "react-tilt";
import { isMobileOnly } from "react-device-detect";
import {
  NftPromoContainer,
  NftPromoTitle,
  NftPromoIntro,
  NftPromoLink,
  NftPromoHeader,
  NftPromoCopy,
  NftPromoArt,
  NftPromoArtists,
  NftPromoArtist,
  NftArtistPreviewImage,
  NftPromoArtistInfo,
  NftArtistPromoName,
  NftArtistHost,
  NftArtistDescription,
  NftPromoArtwork,
  NftPromoPreviews,
  NftPromoPreview,
  NftArtistPreviewName,
  NftPromoArtGradient,
  NftPartners,
  NftPromoPartners,
  NftPromoPartner,
  NftPromoExternalLink,
} from "./NftStyles";

class NftPromo extends React.Component {
  state = {
    appear: false,
    cover: 0,
  };

  render() {
    return (
      <VisibilitySensor
        key={"nft"}
        partialVisibility={true}
        offset={{ bottom: window.innerHeight / 4 }}
        onChange={(isVisible) => isVisible && this.setState({ appear: true })}
      >
        <NftPromoContainer
          className={
            (this.state.appear ? "appear" : "") +
            (this.props.homepage ? " homepage" : "")
          }
          name="nft"
        >
          {this.props.homepage ? (
            <NftPromoIntro>
              <NftPromoTitle>For Artists</NftPromoTitle>
              <NftPromoHeader>Offset Your Digital Art</NftPromoHeader>
              <NftPromoCopy>
                Major brands and artists are using Aerial to reduce the
                environmental impact of their work.
              </NftPromoCopy>
            </NftPromoIntro>
          ) : (
            <NftPromoIntro>
              <NftPromoHeader>Artists Using Aerial</NftPromoHeader>
              <NftPromoCopy>
                Major brands and artists are using Aerial to reduce the
                environmental impact of their work.
              </NftPromoCopy>
            </NftPromoIntro>
          )}
          <NftPromoArtists>
            <NftPromoArtwork>
              {this.props.artists &&
                this.props.artists.map((artist, index) => {
                  return (
                    <NftPromoArt
                      className={this.state.cover == index ? "active" : ""}
                      style={{
                        backgroundImage: `url(${artist.featured_artist_photo.url})`,
                        backgroundColor: artist.featured_color,
                      }}
                    >
                      <NftPromoArtGradient
                        style={{
                          backgroundImage: isMobileOnly
                            ? `linear-gradient(to bottom, ${artist.featured_color}00 25%, ${artist.featured_color} 40%)`
                            : `linear-gradient(to right, ${artist.featured_color}00 25%, ${artist.featured_color} 40%)`,
                        }}
                      />
                      <NftPromoArtistInfo>
                        <NftArtistPromoName>
                          {PrismicReact.RichText.asText(
                            artist.featured_artist_name
                          )}
                        </NftArtistPromoName>
                        <NftArtistHost>
                          {PrismicReact.RichText.asText(
                            artist.featured_platform
                          )}
                        </NftArtistHost>
                        <NftArtistDescription>
                          {PrismicReact.RichText.asText(
                            artist.featured_description
                          )}
                        </NftArtistDescription>
                      </NftPromoArtistInfo>
                    </NftPromoArt>
                  );
                })}
            </NftPromoArtwork>
            <NftPromoPreviews>
              {this.props.artists &&
                this.props.artists.map((artist, index) => {
                  return (
                    <NftPromoPreview
                      className={this.state.cover == index ? "active" : ""}
                      onMouseOver={() => this.setState({ cover: index })}
                    >
                      <NftArtistPreviewImage
                        style={{
                          backgroundImage: `url(${artist.featured_artist_photo.url})`,
                        }}
                      />
                      <NftArtistPreviewName>
                        {PrismicReact.RichText.asText(
                          artist.featured_artist_name
                        )}
                      </NftArtistPreviewName>
                    </NftPromoPreview>
                  );
                })}
            </NftPromoPreviews>
          </NftPromoArtists>
          <NftPartners>
            <NftPromoTitle className="api">Platform Partners</NftPromoTitle>
            <NftPromoPartners>
              {this.props.partners &&
                this.props.partners.map((partner, index) => {
                  return (
                    <NftPromoPartner
                      style={{
                        backgroundImage: `url(${partner.nft_partner.url})`,
                      }}
                    />
                  );
                })}
            </NftPromoPartners>
            {!this.props.homepage && (
              <NftPromoExternalLink href="https://www.notion.so/aerial/Aerial-API-343ebee875784ca18c244a5aae9fa7d3">
                See Our API
              </NftPromoExternalLink>
            )}
          </NftPartners>
        </NftPromoContainer>
      </VisibilitySensor>
    );
  }
}

export default NftPromo;
