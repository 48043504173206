import React, { useEffect, useState, useContext } from 'react';
import { withRouter, Link } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';
import ProgressRing from '../progressring/ProgressRing';
import OffsetActivity from './OffsetActivity';
import UserContext from '../../UserContext';
import TeamContext from '../../TeamContext';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  OffsetResultContainer,
  OffsetStep,
  OffsetInner,
  OffsetTitleContainer,
  OffsetTitle,
  OffsetSubtitles,
  OffsetSubtitle,
  OffsetAddress,
  OffsetTotal,
  OffsetStats,
  OffsetBreakdown,
  OffsetBreakdownContainer,
  OffsetBreakdownItem,
  OffsetBreakdownType,
  OffsetBreakdownStat,
  OffsetBreakdownDistance,
  OffsetBreakdownHeader,
  OffsetStat,
  OffsetStatNumber,
  OffsetStatLabel,
  OffsetStatUnit,
  OffsetLeft,
  OffsetRight,
  OffsetOffsetContainers,
  OffsetOffsetContainer,
  OffsetOffsetInner,
  OffsetOffsetCredits,
  OffsetOffsetLabel,
  OffsetOffsetCopy,
  OffsetPaymentOptions,
  OffsetPaymentOption,
  OffsetPaymentCost,
  OffsetPaymentMethod,
  OffsetCheckoutButton,
  OffsetOffsetButton,
  OffsetMethod,
  OffsetMethodTitle,
  OffsetMethodCopy,
  OffsetError,
  OffsetProgress,
  OffsetProgressRing,
  OffsetProgressStat,
  OffsetProgressCopy,
  OffsetActivities,
  OffsetActivitiesLabel,
  OffsetActivitiesInner,
  OffsetShowMore,
  OffsetShowMoreInner,
  OffsetShareButton,
  OffsetSuccessInner
} from './OffsetStyles';



const OffsetResult = (props) => {
  const { user, login, logout, getUserData, showShare } = useContext(UserContext);
  const { team } = useContext(TeamContext);
  const [progress, setProgress] = useState(0);
  const [parsing, setParsing] = useState(true);
  const [emissions, setEmissions] = useState(0);
  const [distance, setDistance] = useState(0);
  const [newEmissions, setNewEmissions] = useState(0);
  const [success, setSuccess] = useState(false);
  const [paymentOption, setPaymentOption] = useState(0);
  const [activitiesShown, setActivitiesShown] = useState(0);
  const [breakdown, setBreakdown] = useState({});
  const [coinbaseUrl, setCoinbaseUrl] = useState(null);
  const [ethToUsd, setEthToUsd] = useState(null);
  const [credits, setCredits] = useState(0);
  const [creditsPurchased, setCreditsPurchased] = useState(0);
  const [creditPrice, setCreditPrice] = useState(0);
  const [error, setError] = useState(false);

  const urlParams = new URLSearchParams(props.location.search);
  const successUrl = urlParams.get("success")

  useEffect(() => {
    getStats();
    fetchCreditPrice();
    checkParse();
  }, [user]);

  useEffect(() => {
    showShare(!!successUrl)
  }, []);

  useEffect(() => {
    if (credits > 0 && creditPrice > 0) {
      fetchCoinbaseCheckout({
        credits: credits
      });
    }
  }, [creditPrice, credits]);

  const checkParse = () => {
    if (!('is_parsing' in user) || user.is_parsing) {
      setProgress((user['total_messages'] - user['remaining_messages'])/user['total_messages'] * 100)
      setTimeout(() => {
        getUserData();
      }, 500)
    }
  }

  const fetchCreditPrice = async() => {
    fetch('/_getCreditPrice')
      .then((res) => res.json())
      .then((res) => {
        const creditPrice = res.credit
        setCreditPrice(creditPrice)
      })
  }

  const fetchCoinbaseCheckout = async ({ credits }) => {
    return fetch('https://api.commerce.coinbase.com/charges', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CC-Api-Key': '5b8d4d50-e9f1-4b81-a5b0-b9e6439f1845',
        'X-CC-Version': '2018-03-22'
      },
      body: JSON.stringify({
        'name': credits + ' Carbon Credits',
        'description': 'Each carbon credit offsets 1 tonne of CO2 emissions.',
        'local_price': {
               'amount': credits * creditPrice,
               'currency': 'USD'
         },
         'pricing_type': 'fixed_price',
         "redirect_url": "https://aerial.is/footprint",
         "cancel_url": window.location.url,
         "metadata": {
           "credits": credits,
           "email": user.email
         },
      }),
    }).then((res) => res.json())
      .then((res) => {
        setCoinbaseUrl(res.data.hosted_url);
        setEthToUsd(res.data.exchange_rates['ETH-USD']);
      });
  };

  const fetchCheckoutSession = async ({ credits }) => {
    return fetch('/_createCheckoutSession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "credits": credits,
        "email": user.email
      }),
    }).then((res) => res.json());
  };

  const getStartDate = () => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    if (user.activities && user.activities.length) {
      const date = new Date(user.activities[user.activities.length - 1].start)
      return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
    }
  }

  const getStats = async () => {
    var total = 0
    var newEmissions = 0
    var distance = 0
    var breakdown = {
      'air': 0,
      'train': 0,
      'rideshare': 0
    }
    if (user.activities.length) {
      for (let i = 0; i < user.activities.length; i++) {
        var activity = user.activities[i]
        if (!activity.isDeleted) {
          total += activity.emission;
          breakdown[activity.type] += activity.emission;
          distance += activity.consumption;

          if (!activity.is_offset) {
            newEmissions += activity.emission;
          }
        }
      }
    }

    const totalCredits = Math.ceil(total/1000)
    const creditsPurchased = user.credits || 0
    const creditsRequired = totalCredits - creditsPurchased

    setEmissions(Math.ceil(total))
    setDistance(Math.ceil(distance * 0.621371))
    setNewEmissions(total == newEmissions ? 0 : newEmissions)
    setCreditsPurchased(successUrl ? totalCredits : creditsPurchased)
    setCredits(successUrl ? 0 : creditsRequired)
    setSuccess(creditsRequired <= 0 || !!successUrl)
    setActivitiesShown(activitiesShown || Math.min(user.activities.length, 5))
    setBreakdown(breakdown)
  }


  const checkout = async () => {
    if (paymentOption == 0) {
      const stripe = await loadStripe(
        window.location.hostname.includes('aerial.is') ?
          'pk_live_r2fvaquN6YUB67ACgeiFutfx00iQUZ3rEQ' :
          'pk_test_HfkHaG4z1zrTdfSaytysNbk300HLJW5cz6')

      const { sessionId } = await fetchCheckoutSession({
        credits: Math.ceil(credits),
        user: user.id
      });

      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
    } else {
      window.location.href = coinbaseUrl
    }

    window.gtag('event', 'checkout', {
      'event_category': 'offset'
    });
  }

  return (
    <OffsetStep className={'result' + (user.is_parsing ? ' parsing' : '')}>
      <OffsetProgress className={user.is_parsing ? 'show' : ''}>
        <OffsetProgressRing>
          <ProgressRing
            radius={ 80 }
            stroke={ 9 }
            progress={ progress }
            color="#684DB9"
          />
          <OffsetProgressStat>{ Math.ceil(progress) }</OffsetProgressStat>
        </OffsetProgressRing>
        <OffsetProgressCopy>Calculating your footprint...</OffsetProgressCopy>
      </OffsetProgress>
      <OffsetInner className={!user.is_parsing && user.fetched ? "appear" : ""}>
        {!user.is_parsing && user.fetched &&
          <OffsetTitleContainer>
            <OffsetTitle>
            {isMobile ?
              <ByWord text='Your Footprint' delay={600} />
            : <ByLetter text='Your Footprint' delay={600} />}
            </OffsetTitle>
          </OffsetTitleContainer>
        }
        <OffsetSubtitles>
          {!!team.name && !user.is_parsing && user.fetched &&
            <OffsetSubtitle className={!!user.activities.length ? "team appear" : "team"}>{team.name}</OffsetSubtitle>
          }
          <OffsetSubtitle className={!!user.activities.length ? "since appear" : "since"}>Since {!!user.activities.length ? getStartDate() : ""}</OffsetSubtitle>
        </OffsetSubtitles>
        <OffsetResultContainer>
          <OffsetLeft className={error ? 'error' : ''}>
            {!error &&
              <OffsetStats className={!!newEmissions && !success ? "new" : ""}>
                {!!newEmissions && !success &&
                  <OffsetTotal className="new">
                    <OffsetStatLabel>New Emissions</OffsetStatLabel>
                    <OffsetStat>
                      <OffsetStatNumber className="co2">
                        <CountUp end={newEmissions} duration={1} separator="," />
                      </OffsetStatNumber>
                    </OffsetStat>
                  </OffsetTotal>
                }
                <OffsetTotal>
                  <OffsetStatLabel>Total Emissions</OffsetStatLabel>
                  <OffsetStat>
                    <OffsetStatNumber className="co2">
                      <CountUp end={emissions} duration={1} separator="," />
                    </OffsetStatNumber>
                  </OffsetStat>
                </OffsetTotal>
                <OffsetBreakdown>
                  <OffsetBreakdownHeader>
                    <OffsetStatLabel>Breakdown</OffsetStatLabel>
                    •
                    <OffsetBreakdownDistance><CountUp end={distance} duration={1} separator="," />mi</OffsetBreakdownDistance>
                  </OffsetBreakdownHeader>
                  {breakdown &&
                    <OffsetBreakdownContainer>
                      <OffsetBreakdownItem>
                        <OffsetBreakdownType className="air">AIR</OffsetBreakdownType>
                        <OffsetBreakdownStat>{emissions ? Math.round(breakdown.air/emissions * 100) : 0}</OffsetBreakdownStat>
                      </OffsetBreakdownItem>
                      <OffsetBreakdownItem>
                        <OffsetBreakdownType className="car">CAR</OffsetBreakdownType>
                        <OffsetBreakdownStat>{emissions ? Math.round(breakdown.rideshare/emissions * 100) : 0}</OffsetBreakdownStat>
                      </OffsetBreakdownItem>
                      <OffsetBreakdownItem>
                        <OffsetBreakdownType className="train">RAIL</OffsetBreakdownType>
                        <OffsetBreakdownStat>{emissions ? Math.round(breakdown.train/emissions * 100) : 0}</OffsetBreakdownStat>
                      </OffsetBreakdownItem>
                    </OffsetBreakdownContainer>
                  }
                </OffsetBreakdown>
              </OffsetStats>
            }
            <OffsetActivities>
              <OffsetActivitiesLabel>
                Recent Activity
              </OffsetActivitiesLabel>
              <OffsetActivitiesInner>
                {!!user.activities.length ? user.activities.slice(0, activitiesShown).map((activity, index) => {
                  return (
                    <OffsetActivity activity={activity} offset={activity.is_offset || success} onRemove={getStats} />
                  );
                }):
                <OffsetStatLabel style={{textAlign: "center", paddingTop: 16}}>No activities found</OffsetStatLabel>}
              </OffsetActivitiesInner>
              {!!user.activities.length && activitiesShown < user.activities.length &&
                <OffsetShowMore onClick={() => setActivitiesShown(Math.min(activitiesShown + 5, user.activities.length))}>
                  <OffsetShowMoreInner>
                    See More
                  </OffsetShowMoreInner>
                </OffsetShowMore>
              }
            </OffsetActivities>
          </OffsetLeft>
          {!error  &&
            <OffsetRight>
              <OffsetOffsetContainers>
                <OffsetOffsetContainer className={!user.is_parsing && user.fetched ? "appear" : ""}>
                  {success ?
                    <OffsetOffsetInner className="success">
                      <OffsetSuccessInner>
                        <OffsetStatLabel>Carbon Credits Purchased</OffsetStatLabel>
                        <OffsetOffsetCredits>{creditsPurchased}</OffsetOffsetCredits>
                      </OffsetSuccessInner>
                      <OffsetShareButton onClick={() => showShare(true)}>Share</OffsetShareButton>
                    </OffsetOffsetInner> :
                    <OffsetOffsetInner>
                      <OffsetStatLabel>Take Action</OffsetStatLabel>
                      <OffsetOffsetCredits>{credits}</OffsetOffsetCredits>
                      <OffsetOffsetLabel>Carbon Credits Required</OffsetOffsetLabel>
                      {!!team.name &&
                        <OffsetOffsetCopy>This account is managed by {team.admin ? <Link to="/teams">{team.name}</Link> : team.name}</OffsetOffsetCopy>
                      }
                      {!team.name && credits > 0 &&
                        <OffsetOffsetCopy>Securely purchase verified carbon credits to offset your footprint.</OffsetOffsetCopy>
                      }
                      {!team.name && credits > 0 &&
                        <OffsetPaymentOptions>
                          <OffsetPaymentOption onClick={() => setPaymentOption(0)} className={paymentOption == 0 ? 'active' : ''}>
                            <OffsetPaymentCost>${credits * creditPrice}</OffsetPaymentCost>
                            <OffsetPaymentMethod>Pay with Stripe</OffsetPaymentMethod>
                          </OffsetPaymentOption>
                          <OffsetPaymentOption onClick={() => setPaymentOption(1)} className={paymentOption == 1 ? 'active' : ''}>
                            <OffsetPaymentCost>{ethToUsd ? (credits * creditPrice / ethToUsd).toFixed(3) : ''} Ξ</OffsetPaymentCost>
                            <OffsetPaymentMethod>Pay with Coinbase</OffsetPaymentMethod>
                          </OffsetPaymentOption>
                        </OffsetPaymentOptions>
                      }
                      {!team.name && credits > 0 &&
                        <OffsetCheckoutButton onClick={checkout}>
                          Offset
                        </OffsetCheckoutButton>
                      }
                    </OffsetOffsetInner>
                  }
                </OffsetOffsetContainer>
                <OffsetOffsetContainer className={success == false && creditsPurchased > 0 ? "appear purchased" : ((success == false || success == true && creditsPurchased > 0) ? "hide" : "")}>
                    <OffsetOffsetInner className="success">
                      <OffsetSuccessInner>
                        <OffsetStatLabel>Carbon Credits Purchased</OffsetStatLabel>
                        <OffsetOffsetCredits>{creditsPurchased}</OffsetOffsetCredits>
                      </OffsetSuccessInner>
                      <OffsetShareButton onClick={() => showShare(true)}>Share</OffsetShareButton>
                    </OffsetOffsetInner>
                </OffsetOffsetContainer>
              </OffsetOffsetContainers>
            </OffsetRight>
          }
        </OffsetResultContainer>
      </OffsetInner>
    </OffsetStep>
  )
}

export default withRouter(OffsetResult);
