import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import PrismicReact, { Link, RichText, Date } from "prismic-reactjs";
import VisibilitySensor from "react-visibility-sensor";
import {
  TeamContainer,
  TeamInner,
  TeamTitle,
  TeamHeader,
  TeamCopy,
  TeamMembers,
  TeamMembersInner,
  TeamMember,
  TeamMemberPhoto,
  TeamMemberName,
  TeamJoinLink,
  TeamJoinButton,
} from "./TeamStyles";

class Team extends React.Component {
  state = {
    appear: false,
  };

  render() {
    return (
      <VisibilitySensor
        key={"app"}
        partialVisibility={true}
        offset={{ bottom: window.innerHeight / 4 }}
        onChange={(isVisible) => isVisible && this.setState({ appear: true })}
      >
        <TeamContainer
          className={this.state.appear ? "appear" : ""}
          name="team"
        >
          <TeamInner>
            <TeamHeader>About Us</TeamHeader>
            <TeamCopy>
              Aerial is led by climate conscious technologists who want to find
              solutions to reduce carbon emissions. We're working on innovative
              ways to put climate action into everyone's hands.
            </TeamCopy>
          </TeamInner>
        </TeamContainer>
      </VisibilitySensor>
    );
  }
}

export default Team;
