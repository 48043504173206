import React from "react";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import HeaderUser from "./HeaderUser";
import {
  HeaderContainer,
  HeaderInner,
  HeaderLogos,
  HeaderLogo,
  HeaderLinks,
  HeaderScrollLink,
  HeaderLink,
  HeaderAppStoreLink,
  HeaderAppStoreIcon,
  HeaderAndroidLink,
  HeaderAndroidIcon,
  HeaderSignInLink,
} from "./HeaderStyles";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      fixed: true,
      appear: true,
      scrollDown: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = document.documentElement.scrollTop;
    let scrollDown = scrollTop > this.state.scrollOffset;

    this.setState({
      scrollDown: scrollDown,
      scrollOffset: scrollTop,
      scrolled:
        (scrollDown && scrollTop > 0) || (!scrollDown && scrollTop > 80),
      fixed: scrollTop < 80,
    });
  }

  render() {
    return (
      <CSSTransition
        in={this.state.appear}
        timeout={200}
        classNames="onload"
        unmountOnExit
        appear
      >
        <HeaderContainer className={this.state.scrolled ? "scrolled" : ""}>
          <HeaderInner>
            <Link to="/">
              <HeaderLogos>
                <HeaderLogo className="white" />
                <HeaderLogo className="color" />
              </HeaderLogos>
            </Link>
            {this.props.showLinks && (
              <HeaderLinks
                className={this.state.scrollDown ? "scrolldown" : "scrollup"}
              >
                <HeaderScrollLink
                  to="app"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  App
                </HeaderScrollLink>
                <HeaderScrollLink
                  to="nft"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Artists
                </HeaderScrollLink>
                <HeaderScrollLink
                  to="business"
                  spy={true}
                  smooth={true}
                  duration={500}
                >
                  Teams
                </HeaderScrollLink>
              </HeaderLinks>
            )}
            {this.props.altShowLinks && (
              <HeaderLinks
                className={this.state.scrollDown ? "scrolldown" : "scrollup"}
              >
                <HeaderUser />
              </HeaderLinks>
            )}
          </HeaderInner>
        </HeaderContainer>
      </CSSTransition>
    );
  }
}

export default Header;
