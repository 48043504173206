import React, { useEffect, useState } from 'react';
import TeamContext from './TeamContext';

// @function  TeamProvider
// Create function to provide UserContext
const TeamProvider = ({ children }) => {
  const [team, setTeam] = useState({
    id: null,
    name: "",
    admin: false,
    admins: 0,
    shortcode: "",
    members: [],
    credits: 0,
    fetched: false
  });

  const createTeam = (name) => {
    fetch('/_createTeam?name=' + name, {
      method: 'POST'
    }).then((res) => res.json())
      .then(res => {
        setTeam((team) => ({
          ...team,
          id: res.team.id,
          name: res.team.name,
          admin: res.admin,
          shortcode: res.team.shortcode
        }));

        if (res.admin && res.team) {
          getTeamMembers();
        }
      })
  }

  const getTeam = () => {
    fetch('/_team')
      .then((res) => res.json())
      .then(res => {
        setTeam((team) => ({
          ...team,
          id: res.team.id,
          name: res.team.name,
          admin: res.admin,
          admins: res.team.admins ? res.team.admins.length : 0,
          shortcode: res.team.shortcode,
          credits: res.credits,
          fetched: true
        }));

        if (res.admin && res.team) {
          getTeamMembers();
        }
      })
  }

  const getTeamStats = () => {
    var total = 0
    var newEmissions = 0
    var distance = 0
    var breakdown = {
      'air': 0,
      'train': 0,
      'rideshare': 0
    }

    if (team.members.length) {
      team.members.forEach((member, i) => {
        member.activities.forEach((activity, j) => {
          if (!activity.isDeleted) {
            total += activity.emission;
            breakdown[activity.type] += activity.emission;
            distance += activity.consumption

            if (!activity.is_offset) {
              newEmissions += activity.emission;
            }
          }
        })
      })
    }

    return {
      emissions: Math.ceil(total),
      distance: Math.ceil(distance),
      breakdown: breakdown
    }
  }

  const getTeamByShortcode = (shortcode) => {
    fetch('/_team/' + shortcode)
      .then((res) => res.json())
      .then(res => {
        setTeam((team) => ({
          ...team,
          id: res.team.id,
          name: res.team.name,
          shortcode: res.team.shortcode
        }));
      })
  }

  const getTeamMembers = () => {
    fetch('/_teamMembers')
      .then((res) => res.json())
      .then(res => {
        var members = res.members
        members.forEach((member, i) => {
          var total = 0
          if (member.activities.length) {
            for (let i = 0; i < member.activities.length; i++) {
              var activity = member.activities[i]
              if (!activity.isDeleted) {
                total += activity.emission;
              }
            }
          }
          member['emissions'] = total
        })
        members.sort((a, b) => ((b.admin ? Math.pow(10, 10) : 0) + b.emissions) - ((a.admin ? Math.pow(10, 10) : 0) + a.emissions))
        setTeam((team) => ({
          ...team,
          admins: res.team.admins ? res.team.admins.length : 0,
          members: members
        }))
      })
  }

  const joinTeam = (shortcode) => {
    fetch('/_joinTeam?shortcode=' + shortcode, {
      method: 'POST'
    }).then((res) => res.json())
      .then(res => {
        setTeam((team) => ({
          ...team,
          id: res.team.id,
          name: res.team.name,
          admin: res.admin,
          shortcode: res.team.shortcode
        }));

        if (res.admin && res.team) {
          getTeamMembers();
        }
      })
  }

  const makeTeamAdmin = (userId) => {
    fetch('/_makeTeamAdmin/' + userId, {
      method: 'POST'
    }).then((res) => res.json())
      .then(res => {
        setTeam((team) => ({
          ...team,
        }));

        getTeamMembers()
      })
  }

  const removeTeamAdmin = (userId) => {
    fetch('/_removeTeamAdmin/' + userId, {
      method: 'POST'
    }).then((res) => res.json())
      .then(res => {
        setTeam((team) => ({
          ...team,
        }));

        getTeamMembers()
      })
  }

  const removeTeamMember = (userId) => {
    fetch('/_removeTeamMember/' + userId, {
      method: 'POST'
    }).then((res) => res.json())
      .then(res => {
        getTeamMembers();
      })
  }

  const resetTeam = (shortcode) => {
    setTeam((team) => ({
      id: null,
      name: "",
      admin: false,
      shortcode: "",
      members: [],
      credits: 0
    }));
  }

  return (
    <TeamContext.Provider value={{ team, createTeam, getTeam, getTeamMembers, getTeamStats, joinTeam, getTeamByShortcode, resetTeam, makeTeamAdmin, removeTeamAdmin, removeTeamMember }}>
      {children}
    </TeamContext.Provider>
  );
};

export default TeamProvider;
