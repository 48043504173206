import React, { useEffect, useState } from 'react'
import VisibilitySensor from "react-visibility-sensor";
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import classNames from "classnames";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  PraiseQuoteContainer,
  PraiseBubble,
  PraiseCopy,
  PraiseLogo,
  PraiseReviewer,
  PraiseStars
} from './PraiseStyles';


class PraiseQuote extends React.Component {
  state = {
    hasAppeared: {}
  }

  appear(isVisible, section) {
    if (isVisible) {
      let hasAppeared = this.state.hasAppeared;
      hasAppeared[section] = true;
      this.setState({hasAppeared});
    }
  }

  render() {
    return (
      <VisibilitySensor
        key={'quote-show'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/4}}
        onChange={isVisible => this.appear(isVisible, 'quote-show')}>
        <VisibilitySensor
          key={'quote'}
          partialVisibility={true}
          offset={{bottom: isMobile ? window.innerHeight * .85 : window.innerHeight*.8}}
          onChange={isVisible => this.appear(isVisible, 'quote')}>
          <PraiseQuoteContainer className={classNames({'appear': this.state.hasAppeared['quote'], 'show': this.state.hasAppeared['quote-show']})}>
            <PraiseBubble>
              <PraiseCopy>{this.props.copy}</PraiseCopy>
              <PraiseBubble className="active">
                <PraiseCopy>{this.props.copy}</PraiseCopy>
              </PraiseBubble>
            </PraiseBubble>
            {this.props.logo ?
              <PraiseLogo style={{backgroundImage: `url(${this.props.logo})`}} /> :
              <PraiseReviewer>APP STORE REVIEW</PraiseReviewer>
            }
          </PraiseQuoteContainer>
          </VisibilitySensor>
        </VisibilitySensor>
    )
  }
}

export default PraiseQuote;
