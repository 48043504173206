import { keyframes } from 'styled-components';

export const skewIn = keyframes`
  0% {
    opacity: 0;
    transform: translateZ(0) rotate3d(4, 1, 0, 25deg);
  }
  100% {
    opacity: 1;
    transform: translateZ(0) rotate3d(4, 1, 0, 0deg);
  }
`;

export const panBackground = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: -100vw 0;
  }
`;

export const rotate3d = keyframes`
	from {
    transform: rotateY(0deg);
  }
	to {
    transform: rotateY(360deg);
  }
`;
