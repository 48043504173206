import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H3, H5, h5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import forward from '../../assets/images/icons/forward-purple.svg';
import teammember from '../../assets/images/team/team-1.png';

function template(i, items, duration) {
  return `
      &:nth-child(${i + 1}) {
        transition-delay: ${`${(duration / items) * i}s`};
       }
    `
}

function getDelays(items, duration) {
  let str = ''
  for (let i = 0; i < items; i += 1) {
    str += template(i, items, duration)
  }
  return str
}

export const TeamContainer = styled.div`
  overflow: hidden;
  transition: opacity .3s ease;
  position: relative;
  background: #FAFAFA;
  padding: 80px 0 160px;

  @media ${breakpoints.laptop} {
    padding: 40px 0 80px;
  }

  @media ${breakpoints.iPadW} {
    padding: 32px 0 64px;
  }

  @media ${breakpoints.iPhone} {
    padding: 16px 0 32px;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(253, 250, 255, 1), rgba(253, 250, 255, .8));
    transition: opacity 1s ease;
  }

  &.appear {
    &:after {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const TeamInner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: auto;

  @media ${breakpoints.iPadW} {
    max-width: initial;
    margin: 0 80px;
  }

  @media ${breakpoints.iPhone} {
    align-items: flex-start;
    margin: 0;
  }
`;

export const TeamTitle = styled(H5)`
  color: ${colors.midgray};
  margin: auto;

  @media ${breakpoints.iPhone} {
    padding: 0 32px;
  }
`;

export const TeamHeader = styled(H3)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 16px 0;
  margin: auto;

  @media ${breakpoints.iPhone} {
    padding: 0 32px;
  }
`;

export const TeamCopy = styled(P)`
  text-align: center;
  color: ${colors.midgray};
  margin: auto;
  margin: 32px 0;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${TeamContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    margin: 16px 0;
    padding: 0 32px;
  }
`;

export const TeamMembers = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  @media ${breakpoints.iPhone} {
    justify-content: flex-start;
    margin-left: 32px;
  }
`;

export const TeamMembersInner = styled.div`
  display: flex;
  align-items: center;
  padding: 64px 0;

  @media ${breakpoints.iPhone} {
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 0;
  }
`;

export const TeamMember = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  ${getDelays(3, 1)};

  ${TeamContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 0;
    margin: 8px;
    flex-direction: initial;
  }
`;

export const TeamMemberPhoto = styled.div`
  width: 120px;
  height: 120px;
  background-image: url(${teammember});
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &.you {
    &:after {
      content: "+";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, .25);
      color: white;
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media ${breakpoints.iPadW} {
    width: 100px;
    height: 100px;
  }
  @media ${breakpoints.iPhone} {
    width: 64px;
    height: 64px;
    margin-right: 16px;
  }
`;

export const TeamMemberName = styled(P)`
  font-weight: ${fontWeights.demibold};
`;

export const TeamJoinLink = styled.a`
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple};
  text-decoration: none;
  position: relative;
  margin: 16px auto;
  display: inline-block;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .4s;

  ${TeamContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:hover {
    &:after {
      transform: translate(4px, -50%);
    }
  }

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${forward});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    margin-left: 4px;
    transform: translate(0, -50%);
    transition: transform .2s ease;
  }
`;

export const TeamJoinButton = styled.div`
  border-radius: 30px;
  border: 2px solid ${colors.purple};
  color: ${colors.purple};
  cursor: pointer;
  ${h5};
  line-height: 1em;
  padding: 12px 24px;
  margin: 0;
  transition: background .3s ease, color .3s ease;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .6s;

  ${TeamContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }


  &:hover {
    background: ${colors.purple};
    color: white;
  }
`;
