import React, { useEffect, useState, useContext, useRef } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { useGoogleLogin, hasGrantedAllScopesGoogle } from '@react-oauth/google';
import TeamDash from './TeamDash';
import OffsetResult from './OffsetResult';
import UserContext from '../../UserContext';
import TeamContext from '../../TeamContext';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  OffsetContainer,
  OffsetSteps,
  OffsetStep,
  OffsetInner,
  OffsetTitle,
  OffsetHeader,
  OffsetCopy,
  GoogleButton,
  ConnectButton,
  TeamsPreview,
  GoogleIcon,
  OffsetPolicy,
  TeamInputLabel,
  TeamNameInput
} from './OffsetStyles';

const TeamHero = (props) =>  {
  const { user, login, logout } = useContext(UserContext);
  const { team, createTeam, getTeam, joinTeam, getTeamByShortcode } = useContext(TeamContext);
  const [appear, setAppear] = useState(false)
  const [step, setStep] = useState('input')
  const [animate, setAnimate] = useState(false)
  const [gmailScope, setGmailScope] = useState(false)
  const [connect, setConnect] = useState(false)
  const [teamsInfo, setTeamsInfo] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [teamName, setTeamName] = useState('')
  const [enableSubmit, setEnableSubmit] = useState(false)
  const inputRef = useRef(null);

  useEffect(() => {
    if (user.loggedIn && team.fetched && team.name) {
      setShowResult(true);
      setAnimate(true);
      setTeamsInfo(false);
      setTimeout(() => {
        setStep('result');
        // setGmailScope(false);
        // setConnect(true);
        setTimeout(() => {
          setAppear(true);
        }, 600)
      });
    } else if (connect && user.loggedIn && team.fetched && !props.shortcode) {
      setShowResult(false);
      setAnimate(true);

      setTimeout(() => {
        setStep('teaminfo');
        setTimeout(() => {
          setAppear(true);
          inputRef.current.focus();
        }, 600)
      });
    } else if (connect) {
      setShowResult(false);
      setAnimate(true);
      setTimeout(() => {
        setStep('connect');
        setTimeout(() => {
          setAppear(true);
        }, 600)
      });
    } else if (gmailScope) {
      setShowResult(false);
      setConnect(false);
      setAnimate(true);
      setTimeout(() => {
        setStep('gmail');
        setTimeout(() => {
          setAppear(true);
        }, 600)
      });
    } else {
      setShowResult(false);
      setStep('input');
    }

    if (user.fetched) {
      setTimeout(() => {
        setAppear(true);
      });
    }
  })

  useEffect(() => {
    if (props.shortcode) {
      getTeamByShortcode(props.shortcode);
    }
  }, [])

  const googleLogin = useGoogleLogin({
    onSuccess: codeResponse => loginWithGoogle(codeResponse),
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/gmail.readonly'
  });

  const onStart = () => {
    setConnect(true);
  }

  const onKeypress = (e) => {
    setTeamName(e.target.value)
    setEnableSubmit(e.target.value.length > 2);
  }

  const onSubmit = () => {
    createTeam(teamName);
  }

  const loginWithGoogle = async (code) => {
    const hasGmailAccess = hasGrantedAllScopesGoogle(
      code,
      'https://www.googleapis.com/auth/gmail.readonly'
    );

    return fetch('/_loginWithGoogle?auth_code=' + code['code'] + '&scopes=' + code['scope'], {
      method: 'POST',
      headers: {
        'X-Requested-With': 'web-app'
      }
    }).then((res) => res.json())
      .then((user_data) => {
        if (hasGmailAccess) {
          setConnect(true);
          setGmailScope(false);
          login(user_data)

          if (props.shortcode) {
            joinTeam(props.shortcode);
          }
        } else {
          setConnect(false);
          setGmailScope(true);
        }
      })
  };

  return (
    <OffsetContainer className={(appear ? 'appear teams ' : '') + step } name="Offset">
      <OffsetSteps className={animate ? 'animate' : ''}>
        <OffsetStep className="input teams">
          <OffsetInner>
            <OffsetTitle className="hero">
            {isMobile ?
              <ByWord text='Aerial for Teams' />
            : <ByLetter text='Aerial for Teams' />}
            </OffsetTitle>
            <OffsetCopy>Quickly calculate and offset your team's carbon emissions in one simple interface.</OffsetCopy>
            <GoogleButton onClick={onStart}>
              {team.name ? 'Join ' + team.name : 'Get Started'}
            </GoogleButton>
            <TeamsPreview/>
          </OffsetInner>
        </OffsetStep>
        <OffsetStep className="connect">
          <OffsetInner>
            {step == 'connect' &&
              <OffsetHeader className="connect">
                <ByWord delay={300} text={team.name ? 'Join ' + team.name + ' on Aerial' : 'Welcome to Aerial'} />
              </OffsetHeader>
            }
            <ConnectButton onClick={googleLogin}>
              <GoogleIcon /> Continue with Google
            </ConnectButton>
            <OffsetPolicy>
              By proceeding, you agree to our <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>
            </OffsetPolicy>
          </OffsetInner>
        </OffsetStep>
        <OffsetStep className="gmail">
          <OffsetInner>
            <OffsetHeader className="gmail">
              <ByWord text='Grant Gmail Access' />
            </OffsetHeader>
            <OffsetCopy>Aerial calculates your carbon footprint by searching for flight and travel confirmations in your email inbox. Please grant Gmail permissions to continue.</OffsetCopy>
            <GoogleButton onClick={googleLogin}>
              <GoogleIcon className="gmail" /> Grant Gmail Access
            </GoogleButton>
            <OffsetPolicy>
              Aerial has been verified to handle emails in accordance with Google's API Services. By proceeding, you agree to our <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>
            </OffsetPolicy>
          </OffsetInner>
        </OffsetStep>
        <OffsetStep className="teaminfo">
          <OffsetInner>
            <OffsetHeader className="teaminfo">
            {isMobile ?
              <ByWord text='Add Team Info' />
            : <ByLetter text='Add Team Info' />
            }
            </OffsetHeader>
            <TeamInputLabel>
              Team Name
            </TeamInputLabel>
            <TeamNameInput placeholder="" onChange={onKeypress} ref={inputRef} />
            <ConnectButton className={enableSubmit ? "" : "disabled"} onClick={onSubmit}>
              Continue
            </ConnectButton>
          </OffsetInner>
        </OffsetStep>
        {showResult && (team.admin ?
          <TeamDash/> :
          <OffsetResult/>)
        }
        </OffsetSteps>
    </OffsetContainer>
  )
}

export default withRouter(TeamHero);
