import React, { useEffect, useState } from 'react'
import VisibilitySensor from "react-visibility-sensor";
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  PraiseContainer,
  PraiseContent,
  PraiseTitle,
  PraiseQuotes,
  PraiseQuoteGroup,
  PraiseParallax
} from './PraiseStyles';

import PraiseQuote from './PraiseQuote';

class Praise extends React.Component {
  state = {
    hasAppeared: {}
  }

  appear(isVisible, section) {
    if (isVisible) {
      let hasAppeared = this.state.hasAppeared;
      hasAppeared[section] = true;
      this.setState({hasAppeared});
    }
  }

  render() {
    return (
      <VisibilitySensor
        key={'praise'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/3}}
        onChange={isVisible => this.appear(isVisible, 'praise')}>
        <PraiseContainer className={this.state.hasAppeared['praise'] ? 'appear' : ''}>
          <PraiseContent>
            <PraiseTitle>What People are Saying</PraiseTitle>
            <PraiseQuotes>
              {this.props.praise && Array.from(Array(Math.ceil(this.props.praise.length/2)), (e, i) => {
                return (
                  <PraiseQuoteGroup>
                    <PraiseQuote index={2*i} copy={PrismicReact.RichText.asText(this.props.praise[2*i].praise_copy)} logo={this.props.praise[2*i].praise_logo.url} />
                    {this.props.praise[2*i+1] &&
                      <PraiseQuote index={2*i+1} copy={PrismicReact.RichText.asText(this.props.praise[2*i+1].praise_copy)} logo={this.props.praise[2*i+1].praise_logo.url} />
                    }
                  </PraiseQuoteGroup>
                );
              })}
            </PraiseQuotes>
          </PraiseContent>
        </PraiseContainer>
      </VisibilitySensor>
    )
  }
}

export default Praise;
