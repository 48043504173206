import React from 'react';
import { RichText } from 'prismic-reactjs'
import { client, linkResolver } from '../../prismic-configuration'
import {
  PageBodyContainer,
} from './PageStyles';


class PageBody extends React.Component {
  render() {
    return (
      <PageBodyContainer>
        <RichText render={this.props.body} linkResolver={linkResolver} />
      </PageBodyContainer>
    )
  }
}

export default PageBody;
