import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H1, h1, H2, h2, H3, H4, H5, P } from '../../styles/Typography';
import { panBackground, rotate3d } from '../../styles/Animations';
import { breakpoints } from '../../styles/Breakpoints';
import { Link } from 'react-router-dom';
import gradient from '../../assets/images/gradient.png';
import clouds from '../../assets/images/clouds.png';
import next from '../../assets/images/icons/next.svg';
import forward from '../../assets/images/icons/forward-purple.svg';
import cup from '../../assets/images/3d/cup.png';
import prev from '../../assets/images/icons/prev.svg';
import tree1 from '../../assets/images/3d/tree1.png';
import tree2 from '../../assets/images/3d/tree2.png';
import whiteLogo from '../../assets/images/logos/white.svg';
import colorLogo from '../../assets/images/logos/color.svg';
import downArrow from '../../assets/images/down.svg';
import colorLockup from '../../assets/images/logos/color-lockup.svg';
import whiteLockup from '../../assets/images/logos/white-lockup.svg';


function template(i, items, duration) {
  return `
      &:nth-child(${i + 1}) {
        transition-delay: ${`${(duration / items) * i}s`};
       }
    `
}

function getDelays(items, duration) {
  let str = ''
  for (let i = 0; i < items; i += 1) {
    str += template(i, items, duration)
  }
  return str
}

export const NftContainer = styled.div`
  width: 100vw;
  overflow: hidden;
  background: ${gradients.soft};
  background-image: url(${gradient});
  background-size: contain;
  position: relative;

  @media ${breakpoints.iPhone} {
    background-size: auto 200vh;
    background-repeat: repeat-x;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${clouds});
    background-size: cover;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;

    @media ${breakpoints.iPhone} {
      background-size: 100vh 100vh;
      animation: ${panBackground} 30s linear infinite;
      background-repeat: repeat-x;
    }
  }
`;

export const NftSteps = styled.div`
  position: relative;
  height: 60vh;
  transition: transform 2.5s ease;
  z-index: 2;
  margin-top: 100px;

  ${NftContainer}.result & {
    transform: translate(0, -100%);
  }

  @media ${breakpoints.laptop} {
    height: 100vh;
  }

  @media ${breakpoints.short} {
    height: 100vh;
  }

  @media ${breakpoints.iPadW} {
    margin: 0 48px;
  }

  @media ${breakpoints.iPadH} {
    margin: 0 32px;
  }

  @media ${breakpoints.iPhone} {
    height: 90vh;
    min-height: 570px;
    margin: 64px 0 0;
  }

  ${NftContainer}.result & {
    transform: translate(0, -100%);

    @media ${breakpoints.iPhone} {
      height: 110vh;
    }
  }
`;


export const NftStep = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.6s ease;
  display: flex;
  opacity: 0;


  ${NftContainer}.input & {
    &.input {
      opacity: 1;
    }
    &.result {
      opacity: 0;
    }
  }

  ${NftContainer}.result & {
    &.result {
      opacity: 1;
    }
    &.input {
      opacity: 0;
    }
  }
`;

export const NftInner = styled.div`
  max-width: 1200px;
  margin: auto;

  @media ${breakpoints.narrow} {
    margin: auto 40px;
  }

  @media ${breakpoints.iPadW} {
    margin: auto 32px;
  }

  ${NftStep} & {
    width: 100%;

    @media ${breakpoints.iPadW} {
      margin: auto 0;
    }

    @media ${breakpoints.iPhone} {
      width: auto;
      margin: auto 32px;
    }
  }

  ${NftStep}.input & {
    @media ${breakpoints.iPhone} {
      position: relative;
      top: -64px;
    }
  }
`;

export const NftTitleContainer = styled.div`
  /* height: 94px; */
`;

export const NftTitle = styled.div`
  div {
   ${h1}
   color: white;
   margin: 0;
   margin-bottom: 16px;
   transition: opacity .3s ease, filter 1s ease;
   transition-delay: 0.6s;

   span {
     background: ${gradients.purple};
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
   }

   @media ${breakpoints.iPadW} {
     font-size: 36px;
     line-height: 48px;
   }
   @media ${breakpoints.iPhone} {
     font-size: 32px;
     line-height: 36px;
   }

  }

 padding-bottom: 10px;

 @media ${breakpoints.iPhone} {
   padding: 0;
 }

 ${NftContainer}.onload-appear & {
   opacity: 0;
   filter: blur(4px);
 }
 ${NftContainer}.onload-appear-done & {
   opacity: 1;
   filter: blur(0);
 }
`;

export const NftCopy = styled(P)`
  color: ${colors.purple};
  max-width: 500px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  ${NftContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const NftInputTitle = styled(H4)`
  font-size: 20px;
  font-weight: ${fontWeights.medium};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  color: ${colors.purple2};
  max-width: 500px;
  margin-top: 36px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${NftContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const NftInputContainer = styled.div`
  position: relative;
  max-width: 500px;
  border-radius: 30px;
  overflow: hidden;
  margin: 16px 0;
  position: relative;
  left: -16px;
  z-index: 2;
  background: white;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  ${NftContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    left: 0;
  }
`;

export const NftInput = styled.input`
  border: none;
  width: calc(100% - 75px);
  padding: 16px 0 16px 16px;
  font-size: 14px;
  outline: none;
  margin: 0;
  color: ${colors.purple};
  font-family: 'Source Code Pro', monospace;

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const NftButton = styled.div`
  height: 36px;
  width: 36px;
  background-image: url(${next});
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @media ${breakpoints.iPhone} {
    top: 6px;
    right: 6px;
  }
`;

export const NftPolicy = styled.div`
  font-size: 12px;
  color: ${colors.purple2};

  a {
    color: ${colors.purple2};
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .6s;

  ${NftContainer}.appear & {
    opacity: 0.5;
    transform: translate(0, 0);
  }
`;

export const NftAddress = styled.div`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  font-size: 14px;
  padding: 8px;
  display: inline-block;
  color: ${colors.purple2};
  font-family: 'Source Code Pro', monospace;
  position: relative;
  z-index: 2;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.2s;

  ${NftInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    font-size: 11px;
  }
`;

export const NftResultContainer = styled.div`
  display: flex;

  @media ${breakpoints.iPhone} {
    flex-direction: column;
  }
`;

export const NftStats = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  padding: 40px 0;
  position: relative;
  z-index: 2;
  padding-right: 16px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.4s;

  ${NftInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 16px 0;
  }
`;

export const NftStat = styled.div`
  color: ${colors.purple2};
`;

export const NftStatNumber = styled.div`
  font-size: 48px;
  font-weight: ${fontWeights.light};

  @media ${breakpoints.iPadW} {
    font-size: 36px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 24px;
  }
`;

export const NftStatLabel = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.medium};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 16px;
  }
`;

export const NftStatUnit = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;

export const NftLeft = styled.div`
  width: 60%;
  position: relative;
  z-index: 0;

  @media ${breakpoints.iPhone} {
    width: 100%;
  }
`;

export const NftRight = styled.div`
  width: 40%;
  z-index: 2;
  position: relative;

  @media ${breakpoints.iPhone} {
    width: 100%;
  }
`;

export const NftOffsetContainer = styled.div`
  background: rgba(255, 255, 255, .75);
  border-radius: 32px;
  width: 100%;

  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 1s;

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
  }

  &.hide {
    display: none;
  }

  &.purchased {
    transition-delay: 1.2s;
  }
`;

export const NftOffsetInner = styled.div`
  padding: 32px;
  position: relative;

  &.success {
    &:after {
      content: "";
      background-image: url(${cup});
      background-size: contain;
      background-repeat: no-repeat;
      width: 75px;
      padding-top: 34%;
      position: absolute;
      bottom: 32px;
      right: 32px;

      @media ${breakpoints.iPadW} {
        bottom: 16px;
        right: 16px;
        padding-top: 46%;
      }

      @media ${breakpoints.iPhone} {
        width: 50px;
        padding-top: 33%;
        bottom: 16px;
        right: 16px;
      }
    }
  }

  @media ${breakpoints.iPadH} {
    padding: 16px;
  }

`;

export const NftOffsetCredits = styled.div`
  font-size: 48px;
  font-weight: ${fontWeights.light};
  color: ${colors.purple};

  @media ${breakpoints.iPadW} {
    font-size: 36px;
  }
`;

export const NftOffsetLabel = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
    width: 80%;
  }

  @media ${breakpoints.iPhone} {
    font-size: 16px;
    width: initial;
  }
`;

export const NftOffsetCopy = styled(P)`
  color: ${colors.midgray};

  @media ${breakpoints.iPadW} {
    font-size: 14px;
  }
`;

export const NftPaymentOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0 16px;
`;

export const NftPaymentOption = styled.div`
  border-radius: 18px;
  color: ${colors.midgray};
  background: rgba(255, 255, 255, 0.5);
  padding: 16px;
  width: calc(50% - 4px);
  cursor: pointer;
  transition: background .3s ease, color .3s ease, box-shadow .3s ease;

  &:first-child {
    margin-right: 8px;
  }

  &.active {
    color: ${colors.purple};
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .08);
  }

  @media ${breakpoints.iPadH} {
    padding: 16px 8px;
  }
`;

export const NftPaymentCost = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.bold};

  @media ${breakpoints.iPadH} {
    font-size: 16px;
  }
`;

export const NftPaymentMethod = styled(P)`
  margin: 0;

  @media ${breakpoints.laptop} {
    font-size: 16px;
  }

  @media ${breakpoints.iPadW} {
    font-size: 14px;
  }

  @media ${breakpoints.iPadH} {
    font-size: 12px;
  }
`;

export const NftCheckoutButton = styled.button`
  background-color: ${colors.purple};
  font-size: 20px;
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.black};
  color: white;
  display: block;
  border-radius: 16px;
  border: 0;
  padding: 16px 0;
  width: 100%;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

export const NftArtistsContainer = styled.div`
  border-radius: 32px;
  position: relative;
  z-index: 2;
  overflow: hidden;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 1s;

  ${NftContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 8px;
  }
`;

export const NftArtistsHeader = styled(H3)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 32px auto;
`;

export const NftArtistsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: black;
  border-radius: 32px;
  position: relative;
  z-index: 2;
  overflow: hidden;


  @media ${breakpoints.iPhone} {
    flex-direction: column;
  }
`;

export const NftLoader = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${whiteLogo});
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${rotate3d} 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -30px;
  margin-left: -30px;
  transition: opacity .3s ease;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
    transition-delay: .3s;
  }

  @media ${breakpoints.iPhone} {
    margin-top: -80px;
  }
`;

export const NftArtist = styled.a`
  width: 25%;
  padding-top: 25%;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
    padding-top: 100%;
  }
`;

export const NftArtistImage = styled.div`
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: transform .3s ease;

  ${NftArtist}:hover & {
    transform: scale(1.05);
  }
`;

export const NftArtistInfo = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: white;
  z-index: 2;
`;

export const NftArtistName = styled(H4)`
  font-size: 20px;
  line-height: 26px;
  margin: 0;
`;

export const NftArtistHost = styled(P)`
  font-size: 16px;
  margin: 0;
`;

export const NftMethod = styled.div`
  color: ${colors.purple2};
  max-width: 450px;

  @media ${breakpoints.iPhone} {
    margin-bottom: 32px;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.6s;

  &.hero {
    margin-top: 40px;

    ${NftContainer}.appear & {
        opacity: 1;
        transform: translate(0, 0);

    }
  }

  ${NftInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const NftMethodTitle= styled(H4)`
  font-size: 20px;
  font-weight: ${fontWeights.medium};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
`;

export const NftMethodCopy = styled(P)`
  font-size: 16px;

  a {
    color: ${colors.purple2};
  }

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const NftEmbedContainer = styled.a`
  width: 200px;
  height: 54px;
  border-radius: 16px;
  background: ${colors.lilac};
  /* border: 1px solid ${colors.purple}; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-decoration: none;
  border: 1px solid rgb(108 82 251 / 20%);
  /* background-image: url(${gradient}); */
  position: relative;
  /* background-size: contain; */
  margin: 20px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${clouds});
    overflow: hidden;
    border-radius: 16px;
    background-size: 150%;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;
  }

  &.dark {
    background: linear-gradient(45deg, #18103a, #725add);
    border: none;
  }
  &.wide {
    height: 36px;
    border-radius: 5px;
    margin: 0;
    &:after {
      border-radius: 5px;
    }
  }
`;

export const NftEmbedIcon = styled.div`
  background-image: url(${tree1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 60px;
  width: 40px;
  bottom: 3px;
  margin-right: 12px;
  position: relative;
  z-index: 1;

  ${NftEmbedContainer}.dark & {
    background-image: url(${tree2});
  }

  ${NftEmbedContainer}.wide & {
    display: none;
  }

  ${NftEmbedContainer}.offset & {
    background-image: url(${cup});
    height: 60px;
    width: 40px;
    bottom: 3px;
    background-position: top;
    background-size: 80%;
  }
`;

export const NftEmbedLogo = styled.div`
  background-image: url(${colorLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  z-index: 1;

  ${NftEmbedContainer}.dark & {
    background-image: url(${whiteLogo});
  }
  ${NftEmbedContainer}.wide & {
    width: 60px;
    background-image: url(${colorLockup});
  }
`;

export const NftEmbedContent = styled.div`
  background: ${gradients.purple};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: ${fontWeights.black};
  border-right: 1px solid rgb(108 82 251 / 20%);
  margin-right: 16px;
  position: relative;
  width: 115px;
  z-index: 1;

  ${NftEmbedContainer}.dark & {
    color: white;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    border-right: 1px solid rgb(255 255 255 / 20%);
  }

  ${NftEmbedContainer}.wide & {
    width: auto;
    border-right: none;
    margin-right: 8px;
  }
`;

export const NftStartOver = styled.div`
  color: ${colors.purple2};
  border: 1px solid ${colors.purple2};
  border-radius: 32px;
  position: relative;
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 8px;
  display: flex;
  align-items: center;
  width: 140px;
  cursor: pointer;
  margin: 32px 0;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 2s;

  ${NftInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const NftStartOverIcon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(${prev});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
`;

export const NftPromoContainer = styled.div`
  overflow: hidden;
  transition: opacity .3s ease;
  position: relative;
  padding: 80px 0 0;
  z-index: 2;

  &.homepage {
    padding: 120px 16px 120px;
  }

  @media ${breakpoints.laptop} {
    padding: 80px;
  }

  @media ${breakpoints.iPadH} {
    padding: 80px 32px;
  }

  @media ${breakpoints.iPhone} {
    padding: 64px 32px;
    width: auto;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(253, 250, 255, 1), rgba(253, 250, 255, .8));
    transition: opacity 1s ease;
    pointer-events: none;
  }

  &.appear {
    &:after {
      opacity: 0;
    }
  }
`;

export const NftPromoIntro = styled.div`
  max-width: 600px;
  margin: auto;
  text-align: center;
  padding-bottom: 64px;

  @media ${breakpoints.iPadW} {
    padding-bottom: 64px;
  }

  @media ${breakpoints.iPhone} {
    padding-bottom: 32px;
  }
`;

export const NftPromoTitle = styled(H5)`
  color: ${colors.midgray};
  position: relative;
  z-index: 2;
  text-align: center;

  opacity: 0;
  transform: translate(0, 10%);
  transition: opacity 1s ease, transform 1s ease;


  &.api {
    transition-delay: 0.6s;
  }

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    margin-top: 32px;
  }
`;


export const NftPromoHeader = styled(H3)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 16px 0;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .1s;

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const NftPromoCopy = styled(P)`
  color: ${colors.midgray};
  font-size: 18px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const NftPromoArt = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  opacity: 0;
  transition: opacity 0.3s ease;

  &.active {
    opacity: 1;
  }

  @media ${breakpoints.iPadH} {
    background-position: -50%;
  }

  @media ${breakpoints.iPhone} {
      background-position: top;
  }
`;

export const NftPromoArtGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const NftPromoArtwork = styled.div`

`;

export const NftPromoArtists = styled.div`
  width: 100%;
  height: 500px;
  border-radius: 32px;

  display: flex;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto 64px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .08);

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .4s;

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    height: 600px;
  }
`;

export const NftPromoArtist = styled.div`

`;

export const NftArtistPromoName = styled(H4)`
  font-size: 26px;
  line-height: 26px;
  margin: 0;

  @media ${breakpoints.iPhone} {
    font-size: 20px;
  }
`;

export const NftPromoArtistInfo = styled.div`
  position: absolute;
  left: 50%;
  color: white;
  z-index: 2;
  top: 80px;

  @media ${breakpoints.iPhone} {
    top: 45%;
    left: 0;
    padding: 0 20px;
  }
`;

export const NftPromoPreviews = styled.div`
  position: absolute;
  bottom: 16px;
  width: 100%;
  display: flex;
  justify-content: center;

  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  justify-content: initial;
  margin-bottom: -32px;
  padding-bottom: 32px;
  padding-top: 8px;

  @media ${breakpoints.iPhone} {
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    justify-content: initial;
    padding: 8px 0 40px 0;
    margin-bottom: -32px;
  }
`;

export const NftPromoPreview = styled.div`
  position: relative;
  margin: 0;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  ${getDelays(14, 2)};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 96px;

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:first-child {
    padding-left: 16px;
  }
  &:last-child {
    padding-right: 16px;
  }

  margin: 0 4px;
`;

export const NftArtistDescription = styled(P)`
  position: relative;
  max-width: 400px;
  opacity: 0.65;
`;

export const NftArtistPreviewImage = styled.div`
  background-size: cover;
  background-position: center;
  width: 80px;
  height: 80px;
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 16px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .16);
  cursor: pointer;

  ${NftPromoPreview}.active & {
    transform: scale(1.1);
  }
`;

export const NftArtistPreviewName = styled(P)`
  color: white;
  font-size: 12px;
  text-align: center;

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const NftPartners = styled.div`
  text-align: center;
`;

export const NftPromoPartners = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 16px 0;

  @media ${breakpoints.iPhone} {
    margin: 8px 0;
    display: block;
  }
`;

export const NftPromoPartner = styled.div`
  width: 160px;
  height: 40px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 16px;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  ${getDelays(6, 1)};

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    width: 120px;
    height: 60px;
    margin: 0 8px;
    display: inline-block;
  }
`;

export const NftPromoLink = styled(Link)`
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple};
  text-decoration: none;
  position: relative;
  margin-top: 16px;
  display: inline-block;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .4s;

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:hover {
    &:after {
      transform: translate(4px, -50%);
    }
  }

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${forward});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    margin-left: 4px;
    transform: translate(0, -50%);
    transition: transform .2s ease;
  }
`;

export const NftPromoExternalLink = styled.a`
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple};
  text-decoration: none;
  position: relative;
  margin-top: 16px;
  display: inline-block;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .4s;

  ${NftPromoContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:hover {
    &:after {
      transform: translate(4px, -50%);
    }
  }

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${forward});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    margin-left: 4px;
    transform: translate(0, -50%);
    transition: transform .2s ease;
  }
`;

export const NftArrow = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -8px;
  border-radius: 30px;
  background: ${colors.light};
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, .08);
  opacity: 0;
  transition: opacity .6s ease, bottom .6s ease;
  cursor: pointer;

  &:after {
    content: "";
    background-image: url(${downArrow});
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }


  @media ${breakpoints.iPhone} {
    bottom: 80px;
  }

  ${NftContainer}.appear & {
    opacity: 0.65;
    transform: translate(-50%, 0);
    bottom: 16px;
    transition-delay: 0;

    &:hover {
      bottom: -8px;
      opacity: 1;
    }

    @media ${breakpoints.iPhone} {
      bottom: 88px;

      &:hover {
        bottom: 80px;
      }
    }
  }

  ${NftContainer}.result & {
    transition-delay: 0;
    opacity 0;
  }
`;
