import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';
import TeamMember from './TeamMember';
import TeamMemberDash from './TeamMemberDash';
import UserContext from '../../UserContext';
import TeamContext from '../../TeamContext';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  TeamDashContainer,
  OffsetStep,
  OffsetInner,
  OffsetHeaderContainer,
  OffsetHeaderInner,
  OffsetTitleContainer,
  OffsetTitle,
  OffsetSubtitles,
  OffsetSubtitle,
  OffsetAddress,
  OffsetTotal,
  OffsetStats,
  OffsetBreakdown,
  OffsetBreakdownHeader,
  OffsetBreakdownDistance,
  OffsetBreakdownContainer,
  OffsetBreakdownItem,
  OffsetBreakdownType,
  OffsetBreakdownStat,
  OffsetStat,
  OffsetStatNumber,
  OffsetStatLabel,
  OffsetStatUnit,
  OffsetLoader,
  OffsetLeft,
  OffsetRight,
  OffsetOffsetContainers,
  OffsetOffsetContainer,
  OffsetOffsetInner,
  OffsetOffsetCredits,
  OffsetOffsetLabel,
  OffsetOffsetCopy,
  OffsetPaymentOptions,
  OffsetPaymentOption,
  OffsetPaymentCost,
  OffsetPaymentMethod,
  OffsetCheckoutButton,
  OffsetOffsetButton,
  OffsetMethod,
  OffsetMethodTitle,
  OffsetMethodCopy,
  OffsetError,
  TeamMembers,
  OffsetActivitiesLabel,
  OffsetActivitiesInner,
  OffsetShowMore,
  OffsetShowMoreInner,
  OffsetShareButton,
  OffsetSuccessInner,
  TeamInviteLink,
  TeamDashPage,
  TeamMemberPicture,
  OffsetBackButton,
  TeamInviteInner,
  TeamInviteCopyButton
} from './OffsetStyles';



const TeamDash = (props) => {
  const { user, login, logout, getUserData, showShare } = useContext(UserContext);
  const { team, makeTeamAdmin, removeTeamAdmin, removeTeamMember } = useContext(TeamContext);
  const [parsing, setParsing] = useState(true);
  const [emissions, setEmissions] = useState(0);
  const [newEmissions, setNewEmissions] = useState(0);
  const [distance, setDistance] = useState(0);
  const [success, setSuccess] = useState(false);
  const [paymentOption, setPaymentOption] = useState(0);
  const [teamMembersShown, setTeamMembersShown] = useState(0);
  const [breakdown, setBreakdown] = useState({});
  const [coinbaseUrl, setCoinbaseUrl] = useState(null);
  const [ethToUsd, setEthToUsd] = useState(null);
  const [credits, setCredits] = useState(0);
  const [creditsPurchased, setCreditsPurchased] = useState(0);
  const [teamAdmins, setTeamAdmins] = useState(0);
  const [creditPrice, setCreditPrice] = useState(0);
  const [error, setError] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null)
  const [copyButtonText, setCopyButtonText] = useState('Copy')

  const urlParams = new URLSearchParams(props.location.search);
  const successUrl = urlParams.get("success")

  useEffect(() => {
    getStats();
    setTeamAdmins(team.admins);
  }, [team]);

  useEffect(() => {
    fetchCreditPrice()
    showShare(!!successUrl)
  }, []);

  useEffect(() => {
    if (team.fetched && !team.admin) {
      setSelectedMember(user)
    }

    if (user.loggedIn == false) {
      setSelectedMember(null)
    }
  }, [user]);

  useEffect(() => {
    if (credits > 0 && creditPrice > 0) {
      fetchCoinbaseCheckout({
        credits: credits
      });
    }
  }, [creditPrice, credits]);

  const fetchCreditPrice = async() => {
    fetch('/_getCreditPrice')
      .then((res) => res.json())
      .then((res) => {
        const creditPrice = res.credit
        setCreditPrice(creditPrice)
      })
  }

  const fetchCoinbaseCheckout = async ({ credits }) => {
    return fetch('https://api.commerce.coinbase.com/charges', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CC-Api-Key': '5b8d4d50-e9f1-4b81-a5b0-b9e6439f1845',
        'X-CC-Version': '2018-03-22'
      },
      body: JSON.stringify({
        'name': credits + ' Carbon Credits',
        'description': 'Each carbon credit offsets 1 tonne of CO2 emissions.',
        'local_price': {
               'amount': credits * creditPrice,
               'currency': 'USD'
         },
         'pricing_type': 'fixed_price',
         "redirect_url": "https://aerial.is/teams",
         "cancel_url": window.location.url,
         "metadata": {
           "credits": credits,
           "team": team.id
         },
      }),
    }).then((res) => res.json())
      .then((res) => {
        setCoinbaseUrl(res.data.hosted_url);
        setEthToUsd(res.data.exchange_rates['ETH-USD']);
      });
  };

  const fetchCheckoutSession = async ({ credits }) => {
    return fetch('/_createCheckoutSession', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "credits": credits,
        "team": team.id
      }),
    }).then((res) => res.json());
  };

  const getStartDate = (user) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    if (user.activities && user.activities.length) {
      const date = new Date(user.activities[user.activities.length - 1].start)
      return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
    }
  }

  const getTeamStartDate = (team) => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    var startDate = null;
    team.members.forEach((member, i) => {
      if (member.activities && member.activities.length) {
        const date = new Date(member.activities[member.activities.length - 1].start);
        if (!startDate || startDate < date) {
          startDate = date;
        }
      }
    })
    return startDate ? monthNames[startDate.getMonth()] + ' ' + startDate.getDate() + ', ' + startDate.getFullYear() : "";
  }

  const getStats = async () => {
    var total = 0
    var newEmissions = 0
    var distance = 0
    var breakdown = {
      'air': 0,
      'train': 0,
      'rideshare': 0
    }

    if (team.members.length) {
      team.members.forEach((member, i) => {
        member.activities.forEach((activity, j) => {
          if (!activity.isDeleted) {
            total += activity.emission;
            breakdown[activity.type] += activity.emission;
            distance += activity.consumption

            if (!activity.is_offset) {
              newEmissions += activity.emission;
            }
          }
        })
      })
    }

    const totalCredits = Math.ceil(total/1000)
    const newEmissionCredits = Math.ceil(newEmissions/1000)
    const creditsPurchased = team.credits || 0
    const creditsRequired = newEmissionCredits

    setEmissions(Math.ceil(total))
    setNewEmissions(total == newEmissions ? 0 : newEmissions)
    setDistance(Math.ceil(distance * 0.621371))
    setCreditsPurchased(successUrl ? totalCredits : creditsPurchased)
    setCredits(successUrl ? 0 : creditsRequired)
    setSuccess(creditsRequired <= 0 || !!successUrl)
    setTeamMembersShown(teamMembersShown || Math.min(team.members.length, 5))
    setBreakdown(breakdown)
  }


  const checkout = async () => {
    if (paymentOption == 0) {
      const stripe = await loadStripe(
        window.location.hostname.includes('aerial.is') ?
          'pk_live_r2fvaquN6YUB67ACgeiFutfx00iQUZ3rEQ' :
          'pk_test_HfkHaG4z1zrTdfSaytysNbk300HLJW5cz6')

      const { sessionId } = await fetchCheckoutSession({
        credits: Math.ceil(credits),
        user: user.id
      });

      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });
    } else {
      window.location.href = coinbaseUrl
    }

    window.gtag('event', 'checkout', {
      'event_category': 'offset'
    });
  }

  return (
    <OffsetStep className="teams result">
      <OffsetInner className={team.name ? "appear" : ""}>
          {selectedMember ?
            <OffsetHeaderContainer className="selectedmember">
              {team.admin && <OffsetBackButton onClick={() => setSelectedMember(null)} />}
              <TeamMemberPicture className="header" style={{backgroundImage: `url(${selectedMember.picture})`}} />
              <OffsetHeaderInner>
                <OffsetTitleContainer>
                  <OffsetTitle>
                    {isMobile ?
                      <ByWord text={selectedMember.first_name + "'s Footprint"} delay={0} />
                    : <ByLetter text={selectedMember.first_name + "'s Footprint"} delay={0} />}
                  </OffsetTitle>
                  </OffsetTitleContainer>
                <OffsetSubtitles>
                  {
                    !!selectedMember.activities.length &&
                    <OffsetSubtitle className={!!team.name ? "appear since" : "since"}>Since {getStartDate(selectedMember)}</OffsetSubtitle>
                  }
                  <OffsetSubtitle className="email appear">{selectedMember.email}</OffsetSubtitle>
                </OffsetSubtitles>
              </OffsetHeaderInner>
            </OffsetHeaderContainer>
            :
            <OffsetHeaderContainer>
              <OffsetHeaderInner>
                <OffsetTitleContainer>
                  <OffsetTitle>
                    {isMobile ?
                      <ByWord text={team.name + "'s Footprint"} delay={0} />
                    : <ByLetter text={team.name +"'s Footprint"} delay={0} />}
                  </OffsetTitle>
                </OffsetTitleContainer>
                <OffsetSubtitles>
                  {
                    emissions > 0 &&
                    <OffsetSubtitle className={!!team.name ? "appear since" : "since"}>Since {!!team.members.length ? getTeamStartDate(team) : ""}</OffsetSubtitle>
                  }
                </OffsetSubtitles>
              </OffsetHeaderInner>
            </OffsetHeaderContainer>
          }
        <TeamDashContainer className={selectedMember ? "selectedmember" : ""}>
          <TeamDashPage className="teamdash">
            <OffsetLeft className={error ? 'error' : ''}>
              {!error &&
                <OffsetStats className={!!newEmissions && !success ? "new" : ""}>
                  {!!newEmissions && !success &&
                    <OffsetTotal className="new">
                      <OffsetStatLabel>New Emissions</OffsetStatLabel>
                      <OffsetStat>
                        <OffsetStatNumber className="co2">
                          <CountUp end={newEmissions} duration={1} separator="," />
                        </OffsetStatNumber>
                      </OffsetStat>
                    </OffsetTotal>
                  }
                  <OffsetTotal>
                    <OffsetStatLabel>Total Emissions</OffsetStatLabel>
                    <OffsetStat>
                      <OffsetStatNumber className="co2">
                        <CountUp end={emissions} duration={1} separator="," />
                      </OffsetStatNumber>
                    </OffsetStat>
                  </OffsetTotal>
                  <OffsetBreakdown>
                    <OffsetBreakdownHeader>
                      <OffsetStatLabel>Breakdown</OffsetStatLabel>
                      •
                      <OffsetBreakdownDistance><CountUp end={distance} duration={1} separator="," />mi</OffsetBreakdownDistance>
                    </OffsetBreakdownHeader>
                    {breakdown &&
                      <OffsetBreakdownContainer>
                        <OffsetBreakdownItem>
                          <OffsetBreakdownType className="air">AIR</OffsetBreakdownType>
                          <OffsetBreakdownStat>{emissions ? Math.round(breakdown.air/emissions * 100) : 0}</OffsetBreakdownStat>
                        </OffsetBreakdownItem>
                        <OffsetBreakdownItem>
                          <OffsetBreakdownType className="car">CAR</OffsetBreakdownType>
                          <OffsetBreakdownStat>{emissions ? Math.round(breakdown.rideshare/emissions * 100) : 0}</OffsetBreakdownStat>
                        </OffsetBreakdownItem>
                        <OffsetBreakdownItem>
                          <OffsetBreakdownType className="train">RAIL</OffsetBreakdownType>
                          <OffsetBreakdownStat>{emissions ? Math.round(breakdown.train/emissions * 100) : 0}</OffsetBreakdownStat>
                        </OffsetBreakdownItem>
                      </OffsetBreakdownContainer>
                    }
                  </OffsetBreakdown>
                </OffsetStats>
              }
              <TeamMembers>
                <OffsetActivitiesLabel>
                  Team Members
                </OffsetActivitiesLabel>
                <OffsetActivitiesInner>
                  {team.members.length > 0 ?
                    team.members.slice(0, teamMembersShown).map((member, index) => {
                      return (
                        <TeamMember user={member} self={member.id == user.id} admins={teamAdmins} getUserData={getUserData} onClick={() => setSelectedMember(member)} onMakeAdmin={() => {makeTeamAdmin(member.id)}} onRemoveAdmin={() => {removeTeamAdmin(member.id)}} onRemoveMember={() => {removeTeamMember(member.id)}} />
                      );
                    })
                    :
                    <OffsetLoader className="show"/>
                  }
                </OffsetActivitiesInner>
                {!!team.members.length && teamMembersShown < team.members.length &&
                  <OffsetShowMore onClick={() => setTeamMembersShown(Math.min(teamMembersShown + 5, team.members.length))}>
                    <OffsetShowMoreInner>
                      See More
                    </OffsetShowMoreInner>
                  </OffsetShowMore>
                }
              </TeamMembers>
            </OffsetLeft>
            {!error  &&
              <OffsetRight>
                <OffsetOffsetContainers>
                  <OffsetOffsetContainer className={credits > 0 ? "appear" : "hide"}>
                    <OffsetOffsetInner>
                      <OffsetStatLabel>Take Action</OffsetStatLabel>
                      <OffsetOffsetCredits>{credits}</OffsetOffsetCredits>
                      <OffsetOffsetLabel>Carbon Credits Required</OffsetOffsetLabel>
                      {credits > 0 &&
                        <OffsetOffsetCopy>Securely purchase verified carbon credits to offset your footprint.</OffsetOffsetCopy>
                      }
                      {credits > 0 &&
                        <OffsetPaymentOptions>
                          <OffsetPaymentOption onClick={() => setPaymentOption(0)} className={paymentOption == 0 ? 'active' : ''}>
                            <OffsetPaymentCost>${credits * creditPrice}</OffsetPaymentCost>
                            <OffsetPaymentMethod>Pay with Stripe</OffsetPaymentMethod>
                          </OffsetPaymentOption>
                          <OffsetPaymentOption onClick={() => setPaymentOption(1)} className={paymentOption == 1 ? 'active' : ''}>
                            <OffsetPaymentCost>{ethToUsd ? (credits * creditPrice / ethToUsd).toFixed(3) : ''} Ξ</OffsetPaymentCost>
                            <OffsetPaymentMethod>Pay with Coinbase</OffsetPaymentMethod>
                          </OffsetPaymentOption>
                        </OffsetPaymentOptions>
                      }
                      {credits > 0 &&
                        <OffsetCheckoutButton onClick={checkout}>
                          Offset
                        </OffsetCheckoutButton>
                      }
                    </OffsetOffsetInner>
                  </OffsetOffsetContainer>
                  <OffsetOffsetContainer className={creditsPurchased > 0 && team.members.length ? "purchased appear" : "hide"}>
                      <OffsetOffsetInner className="success">
                        <OffsetSuccessInner>
                          <OffsetStatLabel>Carbon Credits Purchased</OffsetStatLabel>
                          <OffsetOffsetCredits>{creditsPurchased}</OffsetOffsetCredits>
                        </OffsetSuccessInner>
                        <OffsetShareButton onClick={() => showShare(true)}>Share</OffsetShareButton>
                      </OffsetOffsetInner>
                  </OffsetOffsetContainer>
                  <OffsetOffsetContainer className={!!team.name && team.members.length ? "invite appear" : ""}>
                    <OffsetOffsetInner className="invite">
                      <OffsetStatLabel>Invite Your Team</OffsetStatLabel>
                      <OffsetOffsetCopy>Share this link to add team members</OffsetOffsetCopy>
                      <TeamInviteInner>
                        <TeamInviteLink>https://aerial.is/teams/{team.shortcode}</TeamInviteLink>
                        <TeamInviteCopyButton onClick={() => {navigator.clipboard.writeText('https://aerial.is/teams/' + team.shortcode); setCopyButtonText('Copied')}}>{copyButtonText}</TeamInviteCopyButton>
                      </TeamInviteInner>
                    </OffsetOffsetInner>
                  </OffsetOffsetContainer>
                </OffsetOffsetContainers>
              </OffsetRight>
          }
          </TeamDashPage>
          <TeamDashPage className="teammember">
            {selectedMember &&
              <TeamMemberDash member={selectedMember} success={success} />
            }
          </TeamDashPage>
        </TeamDashContainer>
      </OffsetInner>
    </OffsetStep>
  )
}

export default withRouter(TeamDash);
