import React, { useEffect, useState } from 'react';
import {
  ProgressRingContainer,
  ProgressRingCircle
} from './ProgressRingStyles';


class ProgressRing extends React.Component {
  constructor(props) {
    super(props);

    const { radius, stroke } = this.props;

    this.normalizedRadius = radius - stroke * 2;
    this.circumference = this.normalizedRadius * 2 * Math.PI;
  }

  render() {
    const { radius, stroke, progress, color } = this.props;

    const strokeDashoffset = this.circumference - progress / 100 * this.circumference;

    return (
      <ProgressRingContainer>
        <svg
          height={radius * 2}
          width={radius * 2}
         >
           <ProgressRingCircle
             stroke={ color }
             fill="transparent"
             strokeOpacity="0.2"
             strokeWidth={ stroke }
             strokeDasharray={ this.circumference + ' ' + this.circumference }
             style={ { strokeDashoffset: this.circumference - 1 * this.circumference } }
             stroke-width={ stroke }
             r={ this.normalizedRadius }
             cx={ radius }
             cy={ radius }
            />
          <ProgressRingCircle
            stroke={ color }
            fill="transparent"
            strokeWidth={ stroke }
            strokeDasharray={ this.circumference + ' ' + this.circumference }
            style={ { strokeDashoffset } }
            stroke-width={ stroke }
            r={ this.normalizedRadius }
            cx={ radius }
            cy={ radius }
           />
        </svg>
      </ProgressRingContainer>
    );
  }
}

export default ProgressRing;
