import styled from 'styled-components';
import { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import whiteLogo from '../../assets/images/logos/white.svg';
import aLogo from '../../assets/images/logos/ablack.svg';

const primary = '#ffffff';
const secondary = '#ffffff';
const third = '#ffffff';

export const morph = keyframes`
  0% {
     border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
     background: linear-gradient(45deg, ${primary} 0%, ${secondary} 100%);
  }

  50% {
     border-radius:  30% 60% 70% 40% / 50% 60% 30% 60%;
     background: linear-gradient(45deg, ${third} 0%, ${secondary} 100%);
  }

  100% {
   border-radius:  60% 40% 30% 70% / 60% 30% 70% 40%;
   background: linear-gradient(45deg, ${primary} 0%, ${secondary} 100%);
  }
`;

export const BlobContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const BlobShape = styled.div`
  background: linear-gradient(45deg, ${primary} 0%, ${secondary} 100%);
  animation: ${morph} 8s ease-in-out infinite;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  height: 120px;
  transition: all 1s ease-in-out;
  width: 120px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

  &:after {
    content: "";
    background-image: url(${aLogo});
    background-size: contain;
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
  }

  transition: opacity .3s ease, filter 1s ease;
  transition-delay: 0.3s;

  ${BlobContainer}.onload-appear & {
    opacity: 0;
    filter: blur(4px);
  }
  ${BlobContainer}.onload-appear-done & {
    opacity: 1;
    filter: blur(0);
  }
`;
