import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import {
  NftEmbedContainer,
  NftEmbedIcon,
  NftEmbedLogo,
  NftEmbedContent
} from './NftStyles';

const fetchStatus = async (address, bsc) => {
    return fetch('/_nft/offset/' + address + (bsc ? "?bsc=1" : ""))
      .then(res => res.json())
}

class Embed extends React.Component {

  state = {
    offset: false
  }

  async componentDidMount() {
    const { offset } = await fetchStatus(this.props.address, this.props.bsc)
    this.setState({
      offset,
    });
  }

  render() {
    return (
      <NftEmbedContainer className={classNames({
        'offset': this.state.offset,
        'dark': this.props.theme == 'dark',
        'wide': this.props.theme == 'niftykit'
      })} href={"http://www.aerial.is/" + (this.props.bsc ? "bsc" : "nft") + "/" + this.props.address} target="_blank">
        <NftEmbedIcon />
        <NftEmbedContent>
          {
            this.props.theme == 'niftykit' ?
              "Carbon offset with"
             : this.state.offset ?
              "NFT has been Carbon Offset" :
              "Carbon Offset this NFT"
          }
        </NftEmbedContent>
        <NftEmbedLogo />
      </NftEmbedContainer>
    )
  }
}

export default Embed;
