import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import VisibilitySensor from "react-visibility-sensor";
import {
  PressContainer,
  PressTitle,
  PressLogos,
  PressLogo
} from './PressStyles';


class Press extends React.Component {
  state = {
    hasAppeared: {}
  }

  appear(isVisible, section) {
    if (isVisible) {
      let hasAppeared = this.state.hasAppeared;
      hasAppeared[section] = true;
      this.setState({hasAppeared});
    }
  }

  render() {
    return (
      <VisibilitySensor
        key={'press'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/3}}
        onChange={isVisible => this.appear(isVisible, 'press')}>
        <PressContainer className={this.state.hasAppeared['press'] ? 'appear' : ''}>
          <PressTitle>As featured in</PressTitle>
          <PressLogos>
          {this.props.logos && this.props.logos.map((logo, index) => {
            return (
              <PressLogo style={{backgroundImage: `url(${logo.press_logo.url})`}} />
            );
          })}
          </PressLogos>
        </PressContainer>
      </VisibilitySensor>
    )
  }
}

export default Press;
