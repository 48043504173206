import styled from 'styled-components';
import { colors } from '../../styles/Colors';
import { fonts, fontWeights, H1, p } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import whiteLogo from '../../assets/images/logos/white.svg';

export const PageHeroContainer = styled.div`
  width: 100%;
  height: 50vh;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: opacity .3s ease;

  &.onload-appear-active {
    opacity: 1;
  }
  &.onload-appear {
    opacity: 0;
  }
`;

export const PageHeroMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.opacity ? 'rgba(0, 0, 0, ' + props.opacity/100 + ')' : 'transparent' };
`;


export const PageHeroTitle = styled(H1)`
  position: absolute;
  bottom: 64px;
  color: white;
  z-index: 2;

  @media ${breakpoints.iPhone} {
    bottom: 16px;
    margin: 0 16px;
  }
`;

export const PageBodyContainer = styled.div`
  max-width: 800px;
  margin: auto;
  padding: 32px 0;
  font-size: 18px;

  p {
    line-height: 1.5em;
  }

  a {
    color: ${colors.purple2};
  }

  img {
    max-width: 800px;
    width: 100%;
  }

  @media ${breakpoints.iPhone} {
    padding: 16px;
  }
`;
