import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H1, h1, H2, h2, H3, h3, H4, H5, P } from '../../styles/Typography';
import { panBackground, rotate3d } from '../../styles/Animations';
import { breakpoints } from '../../styles/Breakpoints';
import gradient from '../../assets/images/gradient.png';
import clouds from '../../assets/images/clouds.png';
import forwardPurple from '../../assets/images/icons/forward-purple.svg';
import cup from '../../assets/images/3d/cup.png';
import tree1 from '../../assets/images/3d/tree1.png';
import tree2 from '../../assets/images/3d/tree2.png';
import google from '../../assets/images/icons/google.svg';
import googlePurple from '../../assets/images/icons/google-purple.svg';
import gmail from '../../assets/images/icons/gmail-purple.svg';
import lock from '../../assets/images/icons/lock.svg';
import prev from '../../assets/images/icons/prev.svg';
import whiteLogo from '../../assets/images/logos/white.svg';
import colorLogo from '../../assets/images/logos/color.svg';
import colorLockup from '../../assets/images/logos/color-lockup.svg';
import airplane from '../../assets/images/icons/airplane.svg';
import tram from '../../assets/images/icons/tram.svg';
import car from '../../assets/images/icons/car.svg';
import co2 from '../../assets/images/icons/co2.svg';
import speedometer from '../../assets/images/icons/speedometer.svg';
import calendar from '../../assets/images/icons/calendar.svg';
import down from '../../assets/images/icons/down.svg';
import check from '../../assets/images/icons/check.svg';
import clock from '../../assets/images/icons/clock.svg';
import logout from '../../assets/images/icons/logout.svg';
import trash from '../../assets/images/icons/trash.svg';
import close from '../../assets/images/icons/close.svg';
import dots from '../../assets/images/icons/dots.svg';
import download from '../../assets/images/icons/download.svg';
import share from '../../assets/images/icons/share.svg';
import copy from '../../assets/images/icons/copy.svg';
import person from '../../assets/images/icons/person.svg';
import office from '../../assets/images/icons/office.svg';
import teamsPreview from '../../assets/images/screenshots/teams.png';

const BORDER_RADIUS = 20;
const SPACING = 12;

export const OffsetContainer = styled.div`
  width: 100vw;
  background: ${gradients.soft};
  background-image: url(${gradient});
  background-size: contain;
  opacity: 0;
  transition: opacity .3s ease;
  overflow: hidden;

  &.appear {
    opacity: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${clouds});
    background-size: cover;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;
  }
`;

export const OffsetSteps = styled.div`
  position: relative;
  transition: transform 2.5s ease;
  z-index: 2;
  padding-top: 100px;

  @media ${breakpoints.iPadW} {
    margin: 0 48px;
  }

  @media ${breakpoints.iPadH} {
    margin: 0 32px;
  }

  @media ${breakpoints.iPhone} {
    margin: 0;
  }

  ${OffsetContainer}.result & {
    min-height: 50vh;

    @media ${breakpoints.iPadW} {
      margin: 0 32px;
      height: auto;
    }

    @media ${breakpoints.iPhone} {
      margin: 0 16px;
    }
  }
`;

export const OffsetInner = styled.div`
  max-width: 1200px;
  margin: auto;

  @media ${breakpoints.narrow} {
    margin: auto 40px;
  }

  @media ${breakpoints.iPadW} {
    margin: auto 32px;
  }

  
`;

// ${OffsetStep} & {
//   width: 100%;

//   @media ${breakpoints.iPadW} {
//     margin: auto 0;
//   }

//   @media ${breakpoints.iPhone} {
//     width: auto;
//     margin: auto;
//   }
// }

export const OffsetStep = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  pointer-events: none;
  max-height: infinite;

  ${OffsetSteps}.animate & {
    transition: opacity 0.6s ease, height 0.6s ease, top 1.5s ease, margin 1.5s ease, max-height .3s ease;
  }

  &.input {
    height: 50vh;

    @media ${breakpoints.iPhone} {
      height: 65vh;
    }

    &.teams {
      height: 75vh;
    }
  }

  &.connect {
    height: 0;

    ${OffsetInner} & {
      max-width: 310px;
      margin: auto;
    }
  }

  &.gmail {
    height: 0;
  }

  &.teaminfo {
    height: 0;

    ${OffsetInner} & {
      max-width: 310px;
      margin: auto;
    }
  }

  &.result {
    height: 0;
  }

  ${OffsetContainer}.input & {
    &.input {
      opacity: 1;
      pointer-events: all;
    }
    &.result {
      opacity: 1;
      height: 0;
    }
  }

  ${OffsetContainer}.connect & {
    &.input {
      opacity: 0;
      height: 0;
      top: -75vh;
    }
    &.connect {
      opacity: 1;
      height: 65vh;
      pointer-events: all;
    }
    &.result {
      opacity: 1;
      height: 0;
    }
  }

  ${OffsetContainer}.gmail & {
    &.input {
      opacity: 0;
      height: 0;
    }
    &.gmail {
      opacity: 1;
      height: 65vh;
      pointer-events: all;
    }
    &.result {
      opacity: 1;
      height: 0;
    }
  }

  ${OffsetContainer}.teaminfo & {
    &.input {
      opacity: 0;
      height: 0;
      top: -75vh;
    }
    &.connect {
      top: -65vh;
    }
    &.teaminfo {
      opacity: 1;
      height: 65vh;
      pointer-events: all;
    }
    &.result {
      opacity: 1;
      height: 0;
    }
  }

  ${OffsetContainer}.result & {
    &.input {
      opacity: 0;
      top: -75vh;
    }
    &.gmail {
      opacity: 0;
      top: -65vh;
    }
    &.teaminfo {
      opacity: 0;
      top: -130vh;
    }
    &.result {
      min-height: 50vh;
      height: 100%;
      opacity: 1;
      top: -50vh;
      margin-bottom: -50vh;
      pointer-events: all;

      &.parsing {
        max-height: 50vh;
      }

      &.teams {
        top: -75vh;
        margin-bottom: -65vh;
        @media ${breakpoints.iPhone} {
          display: block;
        }
      }

      @media ${breakpoints.iPhone} {
        top: -65vh;
        margin-bottom: -65vh;
      }
    }
  }

  ${OffsetContainer}.teams & {
    &.result {
      top: -75vh;
      margin-bottom: -65vh;
    }
  }

  &.teammember {
    opacity: 1;
    pointer-events: all;

    &.parsing {
      max-height: 50vh;
      opacity: 1;
    }
  }
`;


export const OffsetHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OffsetHeaderInner = styled.div`
  ${OffsetHeaderContainer}.selectedmember & {
    @media ${breakpoints.iPhone} {
      width: 70%;
    }
  }
`;

export const OffsetTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OffsetTitle = styled.div`
  div {
   ${h1};
   margin: 0;
   margin-bottom: 16px;
   transition: opacity .3s ease, filter 1s ease;
   transition-delay: 0.6s;

   span {
     background: ${colors.purple4};
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
   }

   @media ${breakpoints.iPadW} {
     font-size: 36px;
     line-height: 48px;
   }
   @media ${breakpoints.iPhone} {
     font-size: 32px;
     line-height: 36px;
     margin-bottom: 8px;
   }

  }

  &.hero {
    div {
      font-size: 72px;
      line-height: 1.2em;
      text-align: center;

      @media ${breakpoints.iPadW} {
        font-size: 56px;
      }
    }
  }

  &.connect {
    div {
      text-align: center;

      @media ${breakpoints.iPadW} {
        font-size: 56px;
        line-height: 1em;
      }
    }
  }

  &.gmail {
    div {
      text-align: center;

      @media ${breakpoints.iPadW} {
        font-size: 56px;
        line-height: 1em;
      }
    }
  }

 @media ${breakpoints.iPhone} {
   padding: 0;
 }

 ${OffsetContainer}.onload-appear & {
   opacity: 0;
   filter: blur(4px);
 }
 ${OffsetContainer}.onload-appear-done & {
   opacity: 1;
   filter: blur(0);
 }
`;

export const OffsetBackButton = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 12px;
  top: -4px;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${forwardPurple});
  transform: rotate(180deg);
  cursor: pointer;
`;

export const OffsetHeader = styled(H3)`
  font-weight: ${fontWeights.black};
  margin-bottom: 32px;

  &.gmail {
    text-align: center;
    margin-bottom: 16px;
  }

  div {
   ${h3};
   margin: 0;
   margin-bottom: 16px;
   transition: opacity .3s ease, filter 1s ease;
   transition-delay: 0.6s;

   span {
     background: ${colors.purple4};
     -webkit-background-clip: text;
     -webkit-text-fill-color: transparent;
   }

   @media ${breakpoints.iPadW} {
     font-size: 36px;
     line-height: 48px;
   }
   @media ${breakpoints.iPhone} {
     font-size: 32px;
     line-height: 36px;
   }

  }
`;

export const OffsetCopy = styled(P)`
  font-size: 18px;
  color: ${colors.purple2};
  font-weight: ${fontWeights.medium};
  max-width: 500px;
  margin: 0 auto 24px;
  text-align: center;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  @media ${breakpoints.iPhone} {
    padding: 0 16px;
  }

  ${OffsetContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  ${OffsetStep}.gmail & {
    max-width: 390px;
  }
`;

export const OffsetSubtitles = styled.div`
  display: flex;
  gap: 16px;

  @media ${breakpoints.iPhone} {
    display: block;
    margin-bottom: 16px;
  }
`;

export const OffsetSubtitle = styled.div`
  color: ${colors.purple3};
  margin-top: -12px;
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: ${fontWeights.medium};
  display: flex;
  align-items: center;

  opacity: 0;
  transform: translate(0, -20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .9s;

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
  }

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    margin-right: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.since {
    &:before {
      background-image: url(${clock});
    }
  }

  &.team {
    &:before {
      background-image: url(${office});
    }
  }

  &.email {
    &:before {
      background-image: url(${person});
    }
  }

  @media ${breakpoints.iPhone} {
    margin: 0;
    font-size: 16px;
  }
`;

export const GoogleButton = styled.div`
  background: ${gradients.purpleBlue};
  font-weight: ${fontWeights.medium};
  border-radius: 8px;
  position: relative;
  text-align: center;
  color: white;
  font-size: 18px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  width: 280px;
  margin: 24px auto;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(98, 91, 140, .1);
  margin: auto;
  justify-content: center;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  ${OffsetContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const ConnectButton = styled.div`
  background: white;
  font-weight: ${fontWeights.medium};
  border-radius: 8px;
  position: relative;
  text-align: center;
  color: ${colors.purple4};
  font-size: 18px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 24px auto;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(98, 91, 140, .1);
  margin: auto;
  justify-content: center;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  &:after {
    content: "";
    background-image: url(${forwardPurple});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 12px;
  }

  &.disabled {
    opacity: 0.5 !important;
    pointer-events: none;
    transition: opacity .3s ease;
    transition-delay: 0;
  }

  ${OffsetContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const GoogleIcon = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(${google});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
  margin-left: 4px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: 12px;

  &.gmail {
    background-image: url(${gmail});
  }

  &.purple {
    background-image: url(${googlePurple});
  }
`;

export const TeamsPreview = styled.div`
  width: 100%;
  padding-top: 40%;
  background-image: url(${teamsPreview});
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-top: 64px;
  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  @media ${breakpoints.iPhone} {
    width: calc(100% - 16px);
    margin: auto;
  }

  ${OffsetContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const OffsetPolicy = styled.div`
  font-size: 14px;
  color: ${colors.midgray};
  max-width: 240px;
  line-height: 1.5em;
  position: relative;
  padding-left: 30px;
  color: #38758E;
  margin: 24px auto 0;
  text-align: left;

  a {
    color: #38758E;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .6s;

  ${OffsetContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  ${OffsetStep}.gmail & {
    max-width: 300px;
  }

  &:before {
    content: "";
    background-image: url(${lock});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 16px;
    height: 21px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
  }

  @media ${breakpoints.iPhone} {
    max-width: 300px;
  }
`;

export const OffsetAddress = styled.div`
  background: rgba(252, 252, 255, 0.4);
  border-radius: 8px;
  font-size: 14px;
  padding: 8px;
  display: inline-block;
  color: ${colors.purple2};
  font-family: 'Source Code Pro', monospace;
  position: relative;
  z-index: 2;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.2s;

  ${OffsetInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    font-size: 11px;
  }
`;

export const OffsetResultContainer = styled.div`
  display: flex;

  @media ${breakpoints.iPhone} {
    flex-direction: column-reverse;
  }
`;

export const OffsetLabel = styled.div`
  font-size: 16px;
  color: ${colors.purple3};
  font-weight: ${fontWeights.bold};
  white-space: nowrap;

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  @media ${breakpoints.iPhone} {
    font-size: 16px;
  }
`;

export const OffsetStats = styled.div`
  display: flex;

  @media ${breakpoints.iPadW} {
    flex-wrap: wrap;
  }
`;

export const OffsetTotal = styled.div`
  position: relative;
  z-index: 2;
  padding-right: 16px;
  background: linear-gradient(125deg, rgba(255, 255, 255, .6), rgba(255, 255, 255, .3));
  border-radius: ${BORDER_RADIUS}px;
  padding: 20px 24px;
  margin-bottom: ${SPACING}px;
  margin-right: ${SPACING}px;
  color: ${colors.purple3};

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.4s;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${OffsetInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadW} {
    width: auto;
    padding: 16px;
    flex-grow: 1;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
    margin-right: 0;
  }

  &.new {
    background: linear-gradient(125deg, rgba(255, 255, 255, .8), rgba(255, 255, 255, .6));

    @media ${breakpoints.iPhone} {
      margin-right: 8px;
    }
  }
`;

export const OffsetBreakdown = styled.div`
  min-width: 200px;
  position: relative;
  z-index: 2;
  padding-right: 16px;
  background: rgba(252, 252, 255, 0.4);
  padding: 20px 24px;
  border-radius: ${BORDER_RADIUS}px;
  margin-bottom: ${SPACING}px;
  margin-right: ${SPACING}px;
  color: ${colors.purple3};

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.4s;

  ${OffsetInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadW} {
    padding: 16px;
    flex-grow: 2;
    width: auto;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
    margin-right: 0;
  }
`;

export const OffsetBreakdownHeader = styled.div`
  display: flex;
  gap: 8px;
`;

export const OffsetBreakdownContainer = styled.div`
  display: flex;
  margin-top: 8px;
  align-item: baseline;
  justify-content: space-between;
`;

export const OffsetBreakdownDistance = styled.div`

`;

export const OffsetBreakdownItem = styled.div`
  padding: 0 16px;
  &:first-child {
    padding-left: 0;
  }

  @media ${breakpoints.iPadW} {
    padding: 0 4px;
  }
`;

export const OffsetBreakdownType = styled.div`
  font-size: 14px;
  font-weight: ${fontWeights.medium};
  padding-left: 20px;
  position: relative;

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 0;
    top: 2px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.air {
    &:after {
      background-image: url(${airplane});
    }
  }

  &.train {
    &:after {
      background-image: url(${tram});
    }
  }

  &.car {
    &:after {
      background-image: url(${car});
    }
  }
`;

export const OffsetBreakdownStat = styled.div`
  font-size: 36px;
  position: relative;
  display: inline-block;

  &:after {
    content: "%";
    font-size: 16px;
    position: absolute;
    top: 8px;
    left: 100%;
    margin-left: 4px;
  }
`;

export const OffsetStat = styled.div`
  color: ${colors.purple3};
`;

export const OffsetStatNumber = styled.div`
  font-size: 36px;
  display: flex;
  align-items: center;

  &:after {
    content: "KG";
    width: 18px;
    height: 34px;
    background-image: url(${co2});
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    margin-left: 8px;
    font-size: 12px;
    font-weight: ${fontWeights.medium};
    opacity: 0;
    transition: opacity .3s ease;
    transition-delay: 1.5s;
  }

  ${OffsetInner}.appear & {
    &:after {
      opacity: 1;
    }
  }
`;

export const OffsetStatLabel = styled(OffsetLabel)`
  margin-bottom: 8px;
`;

export const OffsetStatUnit = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 4px;
`;

export const OffsetLeft = styled.div`
  width: 60%;
  position: relative;
  z-index: 3;

  &.error {
    width: 100%;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const OffsetRight = styled.div`
  width: 40%;
  z-index: 2;
  position: relative;

  @media ${breakpoints.iPhone} {
    width: auto;
    margin-bottom: ${SPACING}px;
  }
`;

export const OffsetError = styled.div`
  color: ${colors.purple2};
  font-size: 18px;
`;

export const OffsetStartOver = styled.div`
  color: ${colors.purple2};
  border: 1px solid ${colors.purple2};
  border-radius: 32px;
  position: relative;
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 8px;
  display: flex;
  align-items: center;
  width: 140px;
  cursor: pointer;
  margin-top: 32px;
`;

export const OffsetStartOverIcon = styled.div`
  display: inline-block;
  width: 28px;
  height: 28px;
  background-image: url(${prev});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 16px;
`;

export const OffsetOffsetContainers = styled.div`
  position: sticky;
  top: 90px;

  @media ${breakpoints.iPhone} {
    position: relative;
    top: initial;
  }

`;

export const OffsetOffsetContainer = styled.div`
  background: linear-gradient(125deg, rgba(255, 255, 255, .8), rgba(255, 255, 255, .6));
  border-radius: ${BORDER_RADIUS}px;
  width: 100%;

  opacity: 0;
  transform: translate(0, 40px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .3s;

  &.appear {
    opacity: 1;
    transform: translate(0, 0);
    margin-bottom: ${SPACING}px;
  }

  &.purchased {
    transition-delay: .4s;
  }

  &.invite {
    transition-delay: .6s;
  }

  @media ${breakpoints.iPhone} {
    &:not(:first-child) {
      margin-bottom: ${SPACING}px;
    }
  }

  &.hide {
    height: 0;
    overflow: hidden;
    margin: 0 !important;
  }
`;

export const OffsetOffsetInner = styled.div`
  padding: 20px 24px;
  position: relative;
  overflow: hidden;


  @media ${breakpoints.iPadW} {
    padding: 16px;
  }

  &.success {
    display: flex;
    justify-content: space-between;
  }
`;

export const OffsetOffsetCredits = styled.div`
  font-size: 36px;
  color: ${colors.purple3};

  @media ${breakpoints.iPadW} {
    font-size: 36px;
  }
`;

export const OffsetOffsetLabel = styled.div`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple3};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
    width: 80%;
  }

  @media ${breakpoints.iPhone} {
    font-size: 16px;
    width: initial;
  }
`;

export const OffsetOffsetCopy = styled(P)`
  color: ${colors.purple2};

  @media ${breakpoints.iPadW} {
    font-size: 14px;
  }
`;

export const OffsetSuccessInner = styled.div`

`;

export const OffsetShareButton = styled.div`
  color: ${colors.purple};
  border: 2px solid rgba(105, 85, 255, .5);
  font-weight: ${fontWeights.bold};
  border-radius: 12px;
  display: flex;
  display: inline-block;
  position: relative;
  padding: 8px 12px 8px 36px;
  font-size: 16px;
  margin: auto 0;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translate(0, -50%);
    width: 18px;
    height: 18px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${share});
    margin-right: 8px;
  }
`;

export const OffsetPaymentOptions = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 0 16px;
`;

export const OffsetPaymentOption = styled.div`
  border-radius: 18px;
  color: ${colors.purple2};
  background: rgba(252, 252, 255, 0.4);
  border: 1px solid rgba(104, 77, 185, .12);
  padding: 16px;
  width: calc(50% - 4px);
  cursor: pointer;
  transition: background .3s ease, color .3s ease, box-shadow .3s ease;

  &:first-child {
    margin-right: 8px;
  }

  &.active {
    color: ${colors.purple};
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, .08);
  }

  @media ${breakpoints.iPadH} {
    padding: 16px 8px;
  }
`;

export const OffsetPaymentCost = styled.div`
  font-size: 20px;
  font-weight: ${fontWeights.bold};

  @media ${breakpoints.iPadH} {
    font-size: 16px;
  }
`;

export const OffsetPaymentMethod = styled(P)`
  margin: 0;

  @media ${breakpoints.laptop} {
    font-size: 16px;
  }

  @media ${breakpoints.iPadW} {
    font-size: 14px;
  }

  @media ${breakpoints.iPadH} {
    font-size: 12px;
  }
`;

export const OffsetCheckoutButton = styled.button`
  background: ${gradients.purpleBlue};
  font-size: 20px;
  font-family: ${fonts.aeonik};
  font-weight: ${fontWeights.black};
  color: white;
  display: block;
  border-radius: 16px;
  border: 0;
  padding: 16px 0;
  width: 100%;
  text-align: center;
  outline: none;
  cursor: pointer;
`;

export const OffsetArtistsContainer = styled.div`
  border-radius: 32px;
  position: relative;
  z-index: 2;
  overflow: hidden;

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 1s;

  ${OffsetContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 8px;
  }
`;

export const OffsetArtistsHeader = styled(H3)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin: 32px auto;
`;

export const OffsetArtistsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: black;
  border-radius: 32px;
  position: relative;
  z-index: 2;
  overflow: hidden;


  @media ${breakpoints.iPhone} {
    flex-direction: column;
  }
`;

export const OffsetArtist = styled.a`
  width: calc(100%/3);
  padding-top: 33%;
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
    padding-top: 100%;
  }
`;

export const OffsetArtistImage = styled.div`
  background-size: cover;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: transform .3s ease;

  ${OffsetArtist}:hover & {
    transform: scale(1.05);
  }
`;

export const OffsetArtistInfo = styled.div`
  position: absolute;
  left: 16px;
  bottom: 16px;
  color: white;
  z-index: 2;
`;

export const OffsetArtistName = styled(H4)`
  margin: 0;
`;

export const OffsetArtistHost = styled(P)`
  margin: 0;
`;

export const OffsetMethod = styled.div`
  color: ${colors.purple3};
  max-width: 400px;

  @media ${breakpoints.iPhone} {
    margin-bottom: 32px;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.6s;

  ${OffsetInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const OffsetMethodTitle= styled(H4)`
  font-size: 20px;
  font-weight: ${fontWeights.medium};

  @media ${breakpoints.iPadW} {
    font-size: 18px;
  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
`;

export const OffsetMethodCopy = styled(P)`
  font-size: 16px;

  a {
    color: ${colors.purple3};
  }

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const OffsetEmbedContainer = styled.a`
  width: 200px;
  height: 54px;
  border-radius: 16px;
  background: ${colors.lilac};
  /* border: 1px solid ${colors.purple}; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  text-decoration: none;
  border: 1px solid rgb(108 82 251 / 20%);
  /* background-image: url(${gradient}); */
  position: relative;
  /* background-size: contain; */
  margin: 20px;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${clouds});
    overflow: hidden;
    border-radius: 16px;
    background-size: 150%;

    animation: ${panBackground} 60s linear infinite;
    pointer-events: none;
  }

  &.dark {
    background: linear-gradient(45deg, #18103a, #725add);
    border: none;
  }
  &.wide {
    height: 36px;
    border-radius: 5px;
    margin: 0;
    &:after {
      border-radius: 5px;
    }
  }
`;

export const OffsetEmbedIcon = styled.div`
  background-image: url(${tree1});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  height: 60px;
  width: 40px;
  bottom: 3px;
  margin-right: 12px;
  position: relative;
  z-index: 1;

  ${OffsetEmbedContainer}.dark & {
    background-image: url(${tree2});
  }

  ${OffsetEmbedContainer}.wide & {
    display: none;
  }

  ${OffsetEmbedContainer}.offset & {
    background-image: url(${cup});
    height: 60px;
    width: 40px;
    bottom: 3px;
    background-position: top;
    background-size: 80%;
  }
`;

export const OffsetEmbedLogo = styled.div`
  background-image: url(${colorLogo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 24px;
  height: 24px;
  z-index: 1;

  ${OffsetEmbedContainer}.dark & {
    background-image: url(${whiteLogo});
  }
  ${OffsetEmbedContainer}.wide & {
    width: 60px;
    background-image: url(${colorLockup});
  }
`;

export const OffsetEmbedContent = styled.div`
  background: ${gradients.purple};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: ${fontWeights.black};
  border-right: 1px solid rgb(108 82 251 / 20%);
  margin-right: 16px;
  position: relative;
  width: 115px;
  z-index: 1;

  ${OffsetEmbedContainer}.dark & {
    color: white;
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    border-right: 1px solid rgb(255 255 255 / 20%);
  }

  ${OffsetEmbedContainer}.wide & {
    width: auto;
    border-right: none;
    margin-right: 8px;
  }
`;


export const OffsetProgress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity .3s ease;
  opacity: 0;
  pointer-events: none;

  &.show {
    opacity: 1;
  }
`;

export const OffsetProgressRing = styled.div`
  position: relative;
  text-align: center;

  &.picture {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 1px;
    opacity: 0;
  }

  &.show {
    opacity: 1;
  }
`;

export const OffsetProgressStat = styled.div`
  color: ${colors.purple3};
  font-weight: ${fontWeights.black};
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-left: -5px;

  &:after {
    content: "%";
    font-size: 16px;
    position: absolute;
    top: 4px;
    left: 100%;
  }
`;

export const OffsetProgressCopy = styled(OffsetLabel)`
  margin-top: 16px;
  color: ${colors.purple3};
`;

export const OffsetActivities = styled.div`
  padding: 40px 0;
  position: relative;
  z-index: 2;
  padding-right: 16px;
  background: linear-gradient(125deg, rgba(255, 255, 255, .6), rgba(255, 255, 255, .3));
  border-radius: ${BORDER_RADIUS}px;
  margin-right: ${SPACING}px;
  padding: 20px 0;
  color: ${colors.purple3};

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.4s;

  ${OffsetInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 16px 0;
    width: auto;
    margin-right: 0;
  }
`;

export const TeamMembers = styled.div`
  padding: 40px 0;
  position: relative;
  z-index: 2;
  padding-right: 16px;
  background: linear-gradient(125deg, rgba(255, 255, 255, .6), rgba(255, 255, 255, .3));
  border-radius: ${BORDER_RADIUS}px;
  margin-right: ${SPACING}px;
  padding: 20px 0;
  color: ${colors.purple3};

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 0.4s;

  ${OffsetInner}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    padding: 16px 0;
    width: auto;
    margin-right: 0;
  }
`;

export const OffsetActivitiesLabel = styled(OffsetStatLabel)`
  padding: 0px 24px 20px;
  border-bottom: 1px solid rgba(104, 77, 185, .12);
`;

export const OffsetActivitiesInner = styled.div`
  padding: 0 24px;

  @media ${breakpoints.iPhone} {
    padding: 0 16px;
  }
`;

export const OffsetActivityContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(104, 77, 185, .12);
  max-height 0;
  opacity: 0;
  transition: max-height .6s ease, opacity .6s ease, padding .6s ease;
  position: relative;
  overflow: hidden;
  padding: 0;

  &.show {
    max-height: 100px;
    opacity: 1;
    overflow: initial;
    padding: 16px 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const OffsetActivityIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: rgba(104, 77, 185, .12);
  position: relative;
  margin-right: 16px;

  &:after {
    content: "";
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.air {
    &:after {
      background-image: url(${airplane});
    }
  }

  &.rail {
    &:after {
      background-image: url(${tram});
    }
  }

  &.rideshare {
    &:after {
      background-image: url(${car});
    }
  }

  &.offset {
    &:before {
      content: "";
      position: absolute;
      right: -2px;
      bottom: -2px;
      width: 16px;
      height: 16px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url(${check});
    }
  }

  @media ${breakpoints.iPhone} {
    margin-right: 12px;
    width: 36px;
    height: 36px;

    &:after {
      width: 20px;
      height: 20px;
    }
  }
`;

export const OffsetActivityInfo = styled.div`
  @media ${breakpoints.iPhone} {
    max-width: 256px;
  }
`;

export const OffsetActivityLabel = styled.div`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  margin-bottom: 6px;
`;

export const OffsetActivityDetails = styled.div`
  display: flex;
  font-size: 16px;

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const OffsetActivityEmissions = styled.div`
  position: relative;
  width: 75px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${co2});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .8;
    margin-right: 4px;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const OffsetActivityDistance = styled.div`
  position: relative;
  margin-left: 12px;
  width: 85px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${speedometer});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .8;
    margin-right: 4px;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const OffsetActivityDate = styled.div`
  position: relative;
  margin-left: 12px;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${calendar});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .8;
    margin-right: 4px;
  }
`;

export const OffsetActivityOptions = styled.div`
  position: absolute;
  right: 0;
  top: 50%:
  transform: translate(-50%, 0);
  width: 20px;
  height: 20px;
  background-image: url(${dots});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s ease;
  pointer-events: none;

  ${OffsetActivityContainer}:hover & {
    pointer-events: all;
    opacity: 1;
  }

  ${OffsetActivityContainer}.showMenu & {
    opacity: 1;
    pointer-events: all;
  }
`;

export const OffsetActivityMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  border-radius: 8px;
  padding: 8px;
  margin-top: 8px;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .08);

  ${OffsetActivityContainer}.showMenu & {
    opacity: 1;
    pointer-events: all;
  }

  &:after {
    bottom: 100%;
  	left: 50%;
  	border: solid transparent;
  	content: "";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  	border-color: rgba(255, 255, 255, 0);
  	border-bottom-color: #ffffff;
  	border-width: 10px;
  	margin-left: -10px;
  }

  @media ${breakpoints.iPhone} {
    top: 50%;
    left: initial;
    right: 100%;
    transform: translate(0, -50%);
    margin-top: 0;
    margin-right: 8px;

    &:after {
      left: 100%;
      bottom: initial;
    	top: 50%;
    	border: solid transparent;
    	content: "";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    	border-color: rgba(255, 255, 255, 0);
    	border-left-color: #ffffff;
    	border-width: 10px;
    	margin-top: -10px;
      margin-left: 0;
    }
  }
`;

export const OffsetActivityMenuItem = styled.div`
  background: rgba(104, 77, 185, .12);
  color: ${colors.purple3};
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 16px 24px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;

  &.remove {
    background: #FBE7E9;
    color: #E13B45;
  }
`;

export const TeamMemberContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(104, 77, 185, .12);
  max-height 0;
  opacity: 0;
  transition: max-height .6s ease, opacity .6s ease, padding .6s ease;
  position: relative;
  overflow: hidden;
  padding: 0;
  cursor: pointer;

  &.show {
    max-height: 100px;
    opacity: 1;
    overflow: initial;
    padding: 16px 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const TeamMemberPicture = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-size: cover;
  background-color: rgb(108 82 251 / 20%);
  background-size: cover;
  background-position: center center;

  position: relative;
  margin-right: 16px;

  &.header {
    width: 64px;
    height: 64px;
    border-radius: 32px;
    top: -4px;
  }

  @media ${breakpoints.iPhone} {
    margin-right: 12px;
    width: 36px;
    height: 36px;

    &:after {
      width: 20px;
      height: 20px;
    }
  }
`;

export const TeamMemberInfo = styled.div`
  @media ${breakpoints.iPhone} {
    max-width: 256px;
  }
`;

export const TeamMemberName = styled.div`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  margin-bottom: 6px;
`;

export const TeamMemberDetails = styled.div`
  display: flex;
  font-size: 16px;

  @media ${breakpoints.iPhone} {
    font-size: 12px;
  }
`;

export const TeamMemberEmissions = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  &:before {
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${co2});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    opacity: .8;
    margin-right: 4px;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const TeamMemberEmail = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const TeamMemberOptions = styled.div`
  position: absolute;
  right: 0;
  top: 50%:
  transform: translate(-50%, 0);
  width: 20px;
  height: 20px;
  background-image: url(${dots});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  opacity: 0;
  transition: opacity .3s ease;
  pointer-events: none;

  ${TeamMemberContainer}:hover & {
    pointer-events: all;
    opacity: 1;
  }

  ${TeamMemberContainer}.showMenu & {
    opacity: 1;
    pointer-events: all;
  }
`;

export const TeamMemberMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  border-radius: 8px;
  padding: 8px;
  margin-top: 12px;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .08);

  ${TeamMemberContainer}.showMenu & {
    opacity: 1;
    pointer-events: all;
  }

  &:after {
    bottom: 100%;
  	left: 50%;
  	border: solid transparent;
  	content: "";
  	height: 0;
  	width: 0;
  	position: absolute;
  	pointer-events: none;
  	border-color: rgba(255, 255, 255, 0);
  	border-bottom-color: #ffffff;
  	border-width: 10px;
  	margin-left: -10px;
  }

  @media ${breakpoints.iPhone} {
    top: 50%;
    left: initial;
    right: 100%;
    transform: translate(0, -50%);
    margin-top: 0;
    margin-right: 8px;

    &:after {
      left: 100%;
      bottom: initial;
    	top: 50%;
    	border: solid transparent;
    	content: "";
    	height: 0;
    	width: 0;
    	position: absolute;
    	pointer-events: none;
    	border-color: rgba(255, 255, 255, 0);
    	border-left-color: #ffffff;
    	border-width: 10px;
    	margin-top: -10px;
      margin-left: 0;
    }
  }
`;

export const TeamMemberMenuItem = styled.div`
  background: rgba(104, 77, 185, .12);
  color: ${colors.purple3};
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 16px 24px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;

  &:not(:first-child) {
    margin-top: 8px;
  }

  &.remove {
    background: #FBE7E9;
    color: #E13B45;
  }
`;

export const TeamDashContainer = styled.div`
  display: flex;
  width: 200%;
  align-items: flex-start;

  @media ${breakpoints.iPhone} {
    flex-direction: column-reverse;
    width: 100%;
  }
`;

export const TeamDashPage = styled.div`
  width: 100%;
  display: flex;
  transition: transform .6s ease, opacity .3s ease;

  @media ${breakpoints.iPhone} {
    flex-direction: column-reverse;
  }

  &.teammember {
    opacity: 0;

    @media ${breakpoints.iPhone} {
      transform: translate(100%, 0);
    }
  }

  ${TeamDashContainer}.selectedmember & {
    &.teamdash {
      transform: translate(-100%, 0);
      opacity: 0;
    }

    &.teammember {
      transform: translate(-100%, 0);
      opacity: 1;
      pointer-events: all;

      @media ${breakpoints.iPhone} {
        transform: translate(0, 0);
      }
    }
  }
`;

export const TeamMemberDashContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 3;
  opacity: 0;
  transform: translate(0, 20px);
  transition: transform .6s ease, opacity .3s ease;

  &.error {
    width: 100%;
  }

  ${OffsetInner}.teammember.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const TeamMemberAdmin = styled.div`
  font-size: 12px;
  font-weight: ${fontWeights.bold};
  padding: 4px;
  border: 1px solid ${colors.purple2};
  border-radius: 4px;
  color: ${colors.purple2};
  position: absolute;
  right: 0;

  &:before {
    content: "ADMIN";
  }
`;

export const OffsetShowMore = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px 0 0;
`;

export const OffsetShowMoreInner = styled.div`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple};
  position: relative;
  text-align: center;
  cursor: pointer;

  &:after {
    content: "";
    width: 16px;
    height: 16px;
    position: absolute;
    left: 100%;
    top: 3px;
    margin-left: 4px;
    background-image: url(${down});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
`;

export const OffsetModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 0, 52, .5);
  z-index: 99;

  opacity: 0;
  transition: opacity .3s ease;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: all;
  }
`;

export const OffsetModalShare = styled.div`
  width: 100%;
  padding-top: 136%;
  background-size: cover;
  background-position: center;
  border-radius: ${BORDER_RADIUS}px;
  margin: 20px 0;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
  }
`;

export const OffsetModalInnerContainer = styled.div`
  width: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  background: rgba(255, 255, 255);
  padding: 24px;
  margin-top: 20px;
  transition: opacity .1s ease, margin .3s ease;
  opacity: 0;
  pointer-events: none;

  ${OffsetModalContainer}.show & {
    margin-top: 0;
    opacity: 1;
    pointer-events: all;
  }
`;

export const OffsetModalInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  opacity: 0;
  transition: opacity .3s ease, max-height .3s ease .15s;
  max-height: 0;
  overflow: hidden;

  &.show {
    max-height: 700px;
    opacity: 1;
    transition: opacity .3s ease .4s, max-height .3s ease .2s;
  }
`;

export const OffsetModalTitle = styled.div`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple4};
  text-align: center;
`;

export const OffsetModalInfo = styled.div`
  margin: 64px auto;
  text-align: center;
`;

export const OffsetModalPicture = styled.div`
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: ${colors.purple};
  background-size: cover;
  background-position: center;
  margin: auto;
`;

export const OffsetModalName = styled.div`
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple3};
  margin-top: 8px;
`;

export const OffsetModalEmail = styled.div`
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  color: ${colors.purple2};
  margin-top: 4px;
`;

export const OffsetModalOptions = styled.div`

`;

export const OffsetModalButton = styled.a`
  background: rgba(104, 77, 185, .12);
  color: ${colors.purple3};
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 24px 24px 24px 64px;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  display: block;

  &:before {
    content: "";
    position: absolute;
    left: 24px;
    top: 50%;
    transform: translate(0, -50%);
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  &.download {
    &:before {
      background-image: url(${download});
    }
  }

  &.logout {
    &:before {
      background-image: url(${logout});
    }
  }

  &.delete {
    background: #FBE7E9;
    color: #E13B45;
    margin-top: 8px;

    &:before {
      background-image: url(${trash});
    }
  }
`;

export const OffsetModalClose = styled.div`
  background: rgba(104, 77, 185, .12);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;
  pointer-events: none;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 16px;
    height: 16px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${close});
  }

  ${OffsetModalInner}.show & {
    pointer-events: all;
  }
`;

export const OffsetLoader = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${colorLogo});
  background-size: contain;
  background-repeat: no-repeat;
  animation: ${rotate3d} 1s linear infinite;
  margin: 64px auto;
  position: relative;
  pointer-events: none;
`;

export const OffsetModalDeleted = styled.div`
  width: 60px;
  height: 60px;
  background-image: url(${check});
  background-size: contain;
  background-repeat: no-repeat;
  margin: 64px auto;
  position: relative;
  pointer-events: none;
`;

export const OffsetModalCopy = styled.div`
  font-size: 12px;
  color: ${colors.purple3};
  text-align: center;
`;

export const OffsetModalConfirm = styled.div`
  display: flex;
  margin-top: 32px;
`;

export const OffsetModalConfirmButton = styled.div`
  background: rgba(104, 77, 185, .12);
  color: ${colors.purple3};
  font-size: 16px;
  font-weight: ${fontWeights.medium};
  padding: 24px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  border-radius: 8px;

  &.confirm {
    background: #FBE7E9;
    color: #E13B45;
    margin-left: 16px;
  }
`;

export const TeamInputLabel = styled.div`
  text-transform: uppercase;
  color: ${colors.purple3};
  font-size: 12px;
  font-weight: ${fontWeights.medium};
  margin-bottom: 8px;
`;

export const TeamNameInput = styled.input.attrs({
  type: 'text',
})`
  background: transparent;
  border-radius: 12px;
  border: 1px solid ${colors.purple3};
  padding: 12px;
  width: calc(100% - 24px);
  font-size: 16px;
  color: ${colors.purple4};
  font-weight: ${fontWeights.medium};
  font-family: ${fonts.aeonik};
  margin-bottom: 24px;

  &:focus {
    border: 1px solid ${colors.purple3};
    outline: none;
  }
`;

export const TeamSizeInput = styled.input.attrs({
  type: 'select',
})`
  background: transparent;
  border-radius: 12px;
  border: 1px solid ${colors.purple3};
  padding: 12px;
  width: calc(100% - 24px);
  font-size: 16px;
  color: ${colors.purple4};
  font-weight: ${fontWeights.medium};
  font-family: ${fonts.aeonik};
  margin-bottom: 24px;

  &:focus {
    border: 1px solid ${colors.purple3};
    outline: none;
  }
`;

export const TeamInviteInner = styled.div`
  display: flex;
`;

export const TeamInviteLink = styled.div`
  background: rgba(255, 255, 255, 0.85);
  border-radius: 8px;
  font-size: 12px;
  padding: 11px;
  display: inline-block;
  color: ${colors.purple2};
  font-family: 'Source Code Pro', monospace;
  position: relative;
  width: 100%;

  @media ${breakpoints.iPhone} {
    font-size: 11px;
  }
`;

export const TeamInviteCopyButton = styled.div`
  background: white;
  font-weight: ${fontWeights.medium};
  border-radius: 8px;
  position: relative;
  text-align: center;
  color: ${colors.purple4};
  font-size: 18px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  margin: 24px auto;
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(98, 91, 140, .1);
  margin: auto;
  justify-content: center;
  margin-left: 8px;

  &:before {
    content: "";
    background-image: url(${copy});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }
`;
