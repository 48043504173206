import React, { useEffect, useState } from 'react';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  NftArtistsContainer,
  NftArtistImage,
  NftInner,
  NftArtistsHeader,
  NftArtistsInner,
  NftArtist,
  NftArtistInfo,
  NftArtistName,
  NftArtistHost
} from './NftStyles';


class NftArtists extends React.Component {

  render() {
    return (
      <NftInner>
        <NftArtistsContainer>
          <NftArtistsHeader>
            Artists using Aerial
          </NftArtistsHeader>
          <NftArtistsInner>
            {this.props.artists && this.props.artists.map((artist, index) => {
              return (
                <NftArtist href={PrismicReact.Link.url(artist.artist_nft_link)} target="_blank">
                  <NftArtistImage style={{backgroundImage: `url(${artist.artist_photo.url})`}}/>
                  <NftArtistInfo>
                    <NftArtistName>
                      {PrismicReact.RichText.asText(artist.artist_name)}
                    </NftArtistName>
                    <NftArtistHost>
                      {PrismicReact.RichText.asText(artist.artist_host)}
                    </NftArtistHost>
                  </NftArtistInfo>
                </NftArtist>
              )
            })}
          </NftArtistsInner>
        </NftArtistsContainer>
      </NftInner>
    )
  }
}

export default NftArtists;
