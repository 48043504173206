import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import Web3 from 'web3';

import CryptoResult from './CryptoResult';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  CryptoContainer,
  CryptoSteps,
  CryptoStep,
  CryptoInner,
  CryptoTitle,
  CryptoCopy,
  CoinbaseConnectButton,
  CoinbaseConnectIcon,
  CryptoPolicy
} from './CryptoStyles';


class CryptoHero extends React.Component {
  web3 = new Web3();
  urlParams = new URLSearchParams(this.props.location.search);

  state = {
    appear: false,
    input: "",
    valid: false,
    submitted: false,
    address: this.props.address,
    code: this.urlParams.get("code"),
    step: this.urlParams.get("code") || this.urlParams.get("success") == 1 ? 1 : 0,
  }

  componentDidMount() {
    if (this.props.address) {
      this.setState({
        step: 1
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      address: this.props.address,
      step: this.props.address ? 1 : 0
    })
  }


  onInput(e) {
    this.setState({
      input: e.target.value,
      valid: this.web3.utils.isAddress(e.target.value)
    })
  }

  submit() {
    if (this.web3.utils.isAddress(this.state.input)) {
      this.setState({
        address: this.state.input,
        submitted: true,
        step: 1
      })

      this.props.history.push('/Crypto/' + this.state.input)

      window.gtag('event', 'submit', {
        'event_category': 'Crypto'
      });
    }
  }

  render() {
    return (
      <VisibilitySensor
        key={'Crypto'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/4}}
        onChange={isVisible => isVisible && this.setState({appear: true})}>
        <CryptoContainer className={(this.state.appear ? 'appear' : '') + (this.state.step == 0 ? ' input' : ' result')} name="Crypto">
          <CryptoSteps>
            <CryptoStep className="input">
              <CryptoInner>
                <CryptoTitle>
                {isMobile ?
                  <ByWord text='Offset Your Crypto' />
                : <ByLetter text='Offset Your Crypto' />}
                </CryptoTitle>
                  <CryptoCopy>Blockchain technology generates a <a href="https://digiconomist.net/bitcoin-energy-consumption" target="_blank" style={{'color': '#6c52fb'}}>huge</a> carbon footprint. Calculate and offset your Bitcoin and Ethereum wallets with our simple tool.</CryptoCopy>
                <CoinbaseConnectButton onClick={() => window.location="https://www.coinbase.com/oauth/authorize?response_type=code&client_id=07736b9dc6e6e6c2a29e111edee5baeb18c1a70fdd023ff18b52df864bfdf521&scope=wallet:accounts:read,wallet:addresses:read&redirect_uri=https://aerial.is/crypto" }>
                  <CoinbaseConnectIcon/> Sign In with Coinbase
                </CoinbaseConnectButton>
                <CryptoPolicy>
                  Your data will not be stored. By proceeding, you agree to our <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>
                </CryptoPolicy>
              </CryptoInner>
            </CryptoStep>

            {this.state.step == 1 &&
              <CryptoResult/>
            }
            </CryptoSteps>
        </CryptoContainer>
      </VisibilitySensor>
    )
  }
}

export default withRouter(CryptoHero);
