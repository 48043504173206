// In src/pages/Page.js
import React, { useEffect, useState } from 'react'
import { client, linkResolver } from '../prismic-configuration'
import NotFound from './NotFound'
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import CryptoHero from '../components/crypto/CryptoHero';
import Press from '../components/press/Press';
import Project from '../components/project/Project';
import Promo from '../components/promo/Promo';
import { useHistory } from "react-router-dom";

const Crypto = ({ match }) => {
  const [doc, setDocData] = useState(null)
  const [notFound, toggleNotFound] = useState(false)
  const address = match.params.address
  const path = match.url
  let history = useHistory()

  // Get the page document from Prismic
  useEffect(() => {
    const fetchData = async () => {
      // We are using the function to get a document by its UID
      const result = await client.getSingle('homepage')

      if (result) {
        // We use the State hook to save the document
        return setDocData(result)
      } else {
        // Otherwise show an error message
        console.warn('Page document not found. Make sure it exists in your Prismic repository')
        toggleNotFound(true)
      }
    }
    fetchData()
  }, [])

  if (doc) {
    return (
      <div>
        <CryptoHero address={address} artists={doc.data.artists} bsc={path.toLowerCase().includes('bsc')} />
        <Project
          projects={doc.data.projects}
        />
        <Promo />
        <Header altShowLinks={true} />
        <Footer/>
      </div>
    )
  } else if (notFound) {
    return <NotFound />
  }
  return null
}

export default Crypto
