import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import * as Scroll from 'react-scroll';
import Web3 from 'web3';

import NftResult from './NftResult';
import NftArtists from './NftArtists';
import NftPromo from './NftPromo';


import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  NftContainer,
  NftSteps,
  NftStep,
  NftInner,
  NftTitle,
  NftCopy,
  NftInputTitle,
  NftInputContainer,
  NftInput,
  NftButton,
  NftPolicy,
  NftMethod,
  NftMethodTitle,
  NftMethodCopy,
  NftArrow
} from './NftStyles';

let scroll = Scroll.animateScroll;

class NftHero extends React.Component {
  web3 = new Web3();

  state = {
    appear: false,
    input: "",
    valid: false,
    submitted: false,
    address: this.props.address,
    step: this.props.address ? 1 : 0
  }

  componentDidMount() {
    if (this.props.address) {
      this.setState({
        step: 1
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    this.setState({
      address: this.props.address,
      step: this.props.address ? 1 : 0
    })
  }


  onInput(e) {
    this.setState({
      input: e.target.value,
      valid: this.web3.utils.isAddress(e.target.value)
    })
  }

  submit() {
    if (this.web3.utils.isAddress(this.state.input)) {
      this.setState({
        address: this.state.input,
        submitted: true,
        step: 1
      })

      this.props.history.push((this.props.bsc ? '/bsc/' : '/nft/') + this.state.input)

      window.gtag('event', 'submit', {
        'event_category': 'NFT'
      });
    }
  }

  scrollDown() {
    scroll.scrollTo(isMobile ? window.innerHeight : window.innerHeight * .65);
  }

  render() {
    return (
      <VisibilitySensor
        key={'nft'}
        partialVisibility={true}
        offset={{bottom: window.innerHeight/4}}
        onChange={isVisible => isVisible && this.setState({appear: true})}>
        <NftContainer className={(this.state.appear ? 'appear' : '') + (this.state.step == 0 ? ' input' : ' result')} name="nft">
          <NftSteps>
            <NftStep className="input">
              <NftInner>
                <NftTitle>
                {isMobile ?
                  <ByWord text='Offset Your NFTs' />
                : <ByLetter text='Offset Your NFTs' />}
                </NftTitle>
                {this.props.bsc ?
                  <NftCopy>Calculate and offset your BSC NFT sale with our simple tool.</NftCopy>
                  :
                  <NftCopy>Blockchain technology generates a huge carbon footprint. Calculate and offset your NFT sale with our simple tool.</NftCopy>
                }
                {this.props.bsc ?
                  <NftInputTitle>
                    Enter your BSC collection address
                  </NftInputTitle>
                :
                  <NftInputTitle>
                    Enter your collection address
                  </NftInputTitle>
                }
                <NftInputContainer className={this.state.submitted ? 'submitted' : ''}>
                    <NftInput
                      placeholder={isMobile ? "0x1D9B23e9A2d11854292..." : "0x1D9B23e9A2d1185429206E2eB572bd70ED8dca60"}
                      value={this.state.input}
                      onChange={this.onInput.bind(this)} />
                  <NftButton className={this.state.valid ? '' : 'disabled'} onClick={this.submit.bind(this)}></NftButton>
                </NftInputContainer>
                <NftPolicy>
                  By proceeding, you agree to our <a href="/terms-of-service">Terms of Service</a> and <a href="/privacy-policy">Privacy Policy</a>
                </NftPolicy>
                <NftMethod className="hero">
                  <NftMethodCopy>
                    Emissions are calculated based on the energy consumption of the <a href="http://ethereumenergyconsumption.com/" target="_blank">Ethereum network</a> as detailed in our <a href="/ethereum-footprint" target="_blank">whitepaper</a> and reflect reductions after <a href="https://ethereum.org/en/upgrades/merge/" target="_blank">The Merge</a>. For inquiries please contact us at <a href="mailto:nft@aerial.is">nft@aerial.is</a>.
                  </NftMethodCopy>
                </NftMethod>
              </NftInner>
            </NftStep>

            {this.state.step == 1 &&
              <NftResult
                address={this.state.address}
                bsc={this.props.bsc}
              />
            }
            <NftArrow onClick={this.scrollDown} />
            </NftSteps>
            <NftPromo artists={this.props.artists} partners={this.props.partners} />
        </NftContainer>
      </VisibilitySensor>
    )
  }
}

export default withRouter(NftHero);
