import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../styles/Colors';
import { fonts, fontWeights } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import { Parallax } from 'react-scroll-parallax';
import night from '../../assets/images/3d/night.png';
import logo from '../../assets/images/logos/white.svg';
import redLogo from '../../assets/images/logos/red.svg';
import instagram from '../../assets/images/social/instagram-white.svg';
import twitter from '../../assets/images/social/twitter-white.svg';
import facebook from '../../assets/images/social/facebook-white.svg';
import linkedin from '../../assets/images/social/linkedin-white.svg';
import email from '../../assets/images/social/email-white.svg';
import appstore from '../../assets/images/appstore_outline_white.svg';
import googleplay from '../../assets/images/google-play-outline-white.svg';

export const FooterContainer = styled.div`
  width: 100%;
  padding: 64px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: opacity .6s ease;
  transition-delay: 1.2s;
  position: relative;
  height: 280px;
  padding: 120px 0 180px;

  @media ${breakpoints.laptop} {
    padding: 80px 0 120px;
  }

  @media ${breakpoints.iPadH} {
    padding: 80px 0 120px;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
    padding: 32px;
    height: 560px;
    justify-content: flex-start;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, .8));
    transition: opacity 1s ease;
  }

  &.appear {
    &:after {
      opacity: 0;
      pointer-events: none;
    }
  }
`;

export const FooterParallax = styled(Parallax)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .parallax-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const FooterBackground = styled.div`
  position: absolute;
  background-image: url(${night});
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 120%;
  top: 50%;
  transform: translate(0, -50%);
  left: 0;
  z-index: 0;
`;

export const FooterInner = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  z-index: 1;

  @media ${breakpoints.laptop} {
    max-width: initial;
    width: calc(100% - 160px);
    margin: 0 80px;
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
    margin: 40px;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  @media ${breakpoints.iPhone} {
    flex-direction: column;
  }
`;

export const FooterLinkColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 64px;

  @media ${breakpoints.iPadW} {
    margin-right: 32px;
  }
`;

export const FooterLink = styled(Link)`
  color: white;
  margin: 0 8px;
  font-weight: ${fontWeights.medium};
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 8px;

  &:hover {

  }
`;

export const FooterExternalLink = styled.a`
  color: white;
  margin: 0 8px;
  font-weight: ${fontWeights.medium};
  font-size: 14px;
  text-decoration: none;
  margin-bottom: 8px;

  &:hover {

  }
`;

export const FooterSocial = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  @media ${breakpoints.iPhone} {
    justify-content: flex-start;
  }
`;

export const FooterDownloadLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.iPhone} {
    justify-content: flex-end;
    flex-direction: column;
  }
`;

export const FooterAppstore = styled.a`
  background-image: url(${appstore});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 120px;
  height: 40px;

  @media ${breakpoints.iPhone} {
    width: 100px;
    height: 36px;
  }
`;

export const FooterGoogleplay = styled.a`
  background-image: url(${googleplay});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 135px;
  height: 40px;
  margin-left: 8px;

  @media ${breakpoints.iPhone} {
    width: 100px;
    height: 36px;
    margin-left: 0;
    margin-top: 4px;
  }
`;

export const FooterSocialLinks = styled.div`
  display: flex;
  margin-bottom: 24px;
`;


export const FooterSocialLink = styled.a`
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  padding-left: 16px;

  &.instagram {
    background-image: url(${instagram});
  }
  &.twitter {
    background-image: url(${twitter});
  }
  &.facebook {
    background-image: url(${facebook});
  }
  &.linkedin {
    background-image: url(${linkedin});
  }
  &.email {
    background-image: url(${email});
  }
`;

export const FooterBranding = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
`;

export const FooterLogo = styled.div`
  background-image: url(${logo});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  width: 48px;
  height: 48px;
  margin: 16px;
  transition: all .3s ease;

  &:hover {
    background-image: url(${redLogo});
    transform: rotate(-180deg);
    transition-delay: 0;
    &:after {
      opacity: 1;
      transition: opacity .3s ease;
      transition-delay: .3s;
    }
  }
  &:after {
    content: "made with";
    position: absolute;
    left: 100%;
    margin-left: 8px;
    font-size: 22px;
    transform: rotate(180deg);
    opacity: 0;
    color: white;
    text-align: right;
  }
`;

export const FooterCopyright = styled.div`
  font-size: 12px;
  max-width: 300px;
  text-align: center;
  color: white;
  z-index: 1;
  opacity: 0.6;
`;
