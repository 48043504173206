import React, { useEffect, useState } from 'react'
import { CSSTransition } from 'react-transition-group';
import { ByLetter, ByWord } from '../text/animetext';
import ReactPlayer from 'react-player';
import * as Scroll from 'react-scroll';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  HeroOuter,
  HeroFixed,
  HeroContainer,
  HeroContentContainer,
  HeroMask,
  HeroMaskInner,
  HeroVideoBackground,
  HeroVideoContainer,
  HeroContent,
  HeroType,
  HeroStatement,
  HeroIntro,
  HeroArrow
} from './HeroStyles';

let scroll = Scroll.animateScroll;

class Hero extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
      fixed: true,
      appear: true,
      scrollDown: true
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    let scrollTop = document.documentElement.scrollTop
    let scrollDown = scrollTop > this.state.scrollOffset

    this.setState({
      scrollDown: scrollDown,
      scrollOffset: scrollTop,
      scrolled: (scrollDown && scrollTop > 0) || (!scrollDown && scrollTop > 80),
      fixed: scrollTop < 80
    });
  }

  scrollDown() {
    scroll.scrollTo(isMobile ? window.innerHeight * .75 : window.innerHeight * .8);
  }

  render() {
    return (
      <CSSTransition in={this.state.appear} timeout={200} classNames="onload" unmountOnExit appear>
        <HeroContainer>
          <HeroOuter className={this.state.scrolled ? "scrolled" : ""}>
            <HeroFixed className={this.state.fixed ? "fixed" : ""}>
              <HeroContentContainer>
                <HeroVideoBackground className="desktop" playsinline={true} muted={true} playing={true} loop url="https://storage.googleapis.com/aerial-app.appspot.com/aerial_illustration_short_HD.mp4" />
                <HeroVideoBackground className="mobile" playsinline={true} muted={true} playing={true} loop url="https://storage.googleapis.com/aerial-app.appspot.com/homepage_mobile.mp4" />
                <HeroContent>
                  <HeroStatement>
                    {isMobile ?
                      <ByWord text={this.props.statement} />
                    : <ByLetter text={this.props.statement} />}
                  </HeroStatement>
                  <HeroIntro>
                    {this.props.intro}
                  </HeroIntro>
                </HeroContent>
              </HeroContentContainer>
              <HeroMask>
                <HeroMaskInner/>
              </HeroMask>
              <HeroArrow onClick={this.scrollDown} />
            </HeroFixed>
          </HeroOuter>
        </HeroContainer>
      </CSSTransition>
    )
  }
}

export default Hero;
