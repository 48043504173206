import React, { useEffect, useState, useContext } from 'react';
import { withRouter } from "react-router-dom";
import { CSSTransition } from 'react-transition-group';
import PrismicReact, { Link, RichText, Date } from 'prismic-reactjs';
import { ByLetter, ByWord } from '../text/animetext';
import VisibilitySensor from "react-visibility-sensor";
import { loadStripe } from '@stripe/stripe-js';
import CountUp from 'react-countup';
import ProgressRing from '../progressring/ProgressRing';
import OffsetActivity from './OffsetActivity';
import UserContext from '../../UserContext';
import TeamContext from '../../TeamContext';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  TeamMemberDashContainer,
  TeamMemberEmail,
  TeamMemberPicture,
  OffsetStep,
  OffsetInner,
  OffsetTitleContainer,
  OffsetTitle,
  OffsetSubtitle,
  OffsetAddress,
  OffsetTotal,
  OffsetStats,
  OffsetBreakdown,
  OffsetBreakdownHeader,
  OffsetBreakdownDistance,
  OffsetBreakdownContainer,
  OffsetBreakdownItem,
  OffsetBreakdownType,
  OffsetBreakdownStat,
  OffsetStat,
  OffsetStatNumber,
  OffsetStatLabel,
  OffsetStatUnit,
  OffsetLeft,
  OffsetRight,
  OffsetOffsetContainers,
  OffsetOffsetContainer,
  OffsetOffsetInner,
  OffsetOffsetCredits,
  OffsetOffsetLabel,
  OffsetOffsetCopy,
  OffsetPaymentOptions,
  OffsetPaymentOption,
  OffsetPaymentCost,
  OffsetPaymentMethod,
  OffsetCheckoutButton,
  OffsetOffsetButton,
  OffsetMethod,
  OffsetMethodTitle,
  OffsetMethodCopy,
  OffsetError,
  OffsetProgress,
  OffsetProgressRing,
  OffsetProgressStat,
  OffsetProgressCopy,
  OffsetActivities,
  OffsetActivitiesLabel,
  OffsetActivitiesInner,
  OffsetShowMore,
  OffsetShowMoreInner,
  OffsetShareButton,
  OffsetSuccessInner
} from './OffsetStyles';


const TeamMemberDash = (props) => {
  var member = props.member
  var success = props.success
  const { user, login, logout, getUserData, showShare } = useContext(UserContext);
  const { team } = useContext(TeamContext);
  const [progress, setProgress] = useState(0);
  const [parsing, setParsing] = useState(true);
  const [emissions, setEmissions] = useState(0);
  const [distance, setDistance] = useState(0);
  const [newEmissions, setNewEmissions] = useState(0);
  const [paymentOption, setPaymentOption] = useState(0);
  const [activitiesShown, setActivitiesShown] = useState(0);
  const [breakdown, setBreakdown] = useState({});
  const [coinbaseUrl, setCoinbaseUrl] = useState(null);
  const [ethToUsd, setEthToUsd] = useState(null);
  const [credits, setCredits] = useState(0);
  const [creditPrice, setCreditPrice] = useState(0);
  const [error, setError] = useState(false);

  useEffect(() => {
    getStats();
    checkParse();
    window.scrollTo(0, 0);
  }, [member.id == user.id ? user : member]);


  const checkParse = () => {
    var currentMember = member.id == user.id ? user : member;
    if (!('is_parsing' in member) || member.is_parsing) {
      setProgress((currentMember['total_messages'] - currentMember['remaining_messages'])/currentMember['total_messages'] * 100)
      setTimeout(() => {
        getUserData();
      }, 500)
    }
  }

  const getStartDate = () => {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    if (member.activities && member.activities.length) {
      const date = new Date(member.activities[member.activities.length - 1].start)
      return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
    }
  }

  const getStats = async () => {
    var total = 0
    var newEmissions = 0
    var distance = 0
    var breakdown = {
      'air': 0,
      'train': 0,
      'rideshare': 0
    }
    if (member.activities.length) {
      for (let i = 0; i < member.activities.length; i++) {
        var activity = member.activities[i]
        if (!activity.isDeleted) {
          total += activity.emission;
          breakdown[activity.type] += activity.emission;
          distance += activity.consumption;

          if (!activity.is_offset) {
            newEmissions += activity.emission;
          }
        }
      }
    }

    const totalCredits = Math.ceil(total/1000)
    setEmissions(Math.ceil(total))
    setDistance(Math.ceil(distance * 0.621371))
    setNewEmissions(total == newEmissions ? 0 : newEmissions)
    setActivitiesShown(activitiesShown || Math.min(member.activities.length, 5))
    setBreakdown(breakdown)
  }

  return (
    <OffsetStep className={'teammember' + (user.is_parsing ? ' parsing' : '')}>
      <OffsetProgress className={user.is_parsing ? 'show' : ''}>
        <OffsetProgressRing>
          <ProgressRing
            radius={ 80 }
            stroke={ 9 }
            progress={ progress }
            color="#684DB9"
          />
          <OffsetProgressStat>{ Math.ceil(progress) }</OffsetProgressStat>
        </OffsetProgressRing>
        <OffsetProgressCopy>Calculating your footprint...</OffsetProgressCopy>
      </OffsetProgress>
      <OffsetInner className={!user.is_parsing && user.fetched ? "teammember appear" : "teammember"}>
        <TeamMemberDashContainer>
          <OffsetStats className={!!newEmissions && !success ? "new" : ""}>
            {!!newEmissions && !success &&
              <OffsetTotal className="new">
                <OffsetStatLabel>New Emissions</OffsetStatLabel>
                <OffsetStat>
                  <OffsetStatNumber className="co2">
                    <CountUp end={newEmissions} duration={1} separator="," />
                  </OffsetStatNumber>
                </OffsetStat>
              </OffsetTotal>
            }
            <OffsetTotal>
              <OffsetStatLabel>Total Emissions</OffsetStatLabel>
              <OffsetStat>
                <OffsetStatNumber className="co2">
                  <CountUp end={emissions} duration={1} separator="," />
                </OffsetStatNumber>
              </OffsetStat>
            </OffsetTotal>
            <OffsetBreakdown>
              <OffsetBreakdownHeader>
                <OffsetStatLabel>Breakdown</OffsetStatLabel>
                •
                <OffsetBreakdownDistance><CountUp end={distance} duration={1} separator="," />mi</OffsetBreakdownDistance>
              </OffsetBreakdownHeader>
              {breakdown &&
                <OffsetBreakdownContainer>
                  <OffsetBreakdownItem>
                    <OffsetBreakdownType className="air">AIR</OffsetBreakdownType>
                    <OffsetBreakdownStat>{emissions ? Math.round(breakdown.air/emissions * 100) : 0}</OffsetBreakdownStat>
                  </OffsetBreakdownItem>
                  <OffsetBreakdownItem>
                    <OffsetBreakdownType className="car">CAR</OffsetBreakdownType>
                    <OffsetBreakdownStat>{emissions ? Math.round(breakdown.rideshare/emissions * 100) : 0}</OffsetBreakdownStat>
                  </OffsetBreakdownItem>
                  <OffsetBreakdownItem>
                    <OffsetBreakdownType className="train">RAIL</OffsetBreakdownType>
                    <OffsetBreakdownStat>{emissions ? Math.round(breakdown.train/emissions * 100) : 0}</OffsetBreakdownStat>
                  </OffsetBreakdownItem>
                </OffsetBreakdownContainer>
              }
            </OffsetBreakdown>
          </OffsetStats>
          <OffsetActivities>
            <OffsetActivitiesLabel>
              Recent Activity
            </OffsetActivitiesLabel>
            <OffsetActivitiesInner>
              {!!member.activities.length ? member.activities.slice(0, activitiesShown).map((activity, index) => {
                return (
                  <OffsetActivity activity={activity} offset={activity.is_offset || success} onRemove={getStats} />
                );
              }):
              <OffsetStatLabel style={{textAlign: "center", paddingTop: 16}}>No activities found</OffsetStatLabel>}
            </OffsetActivitiesInner>
            {!!member.activities.length && activitiesShown < member.activities.length &&
              <OffsetShowMore onClick={() => setActivitiesShown(Math.min(activitiesShown + 5, member.activities.length))}>
                <OffsetShowMoreInner>
                  See More
                </OffsetShowMoreInner>
              </OffsetShowMore>
            }
          </OffsetActivities>
        </TeamMemberDashContainer>
      </OffsetInner>
    </OffsetStep>
  )
}

export default TeamMemberDash;
