import React, { Component } from "react";
import PropTypes from "prop-types";
import anime from "animejs";

export class ByLetter extends Component {
  state = {
    animated: false
  }

  componentDidMount() {
    var animation = anime.timeline({ loop: false });
    setTimeout(() => {
      animation
        .add({
          targets: `.by-letter:not(.animated) .letter`,
          translateY: ["1.1em", 0],
          translateX: ["0em", 0],
          translateZ: 0,
          duration: 750,
          easing: "easeOutExpo",
          opacity: 1,
          delay: (el, i) => 25 * i
        })
      this.setState({
        animated: true
      });
    }, this.props.delay || 0);
  }

  render() {
    return (
      <div className={this.state.animated ? "by-letter animated": "by-letter"}>
      {this.props.text.split("").map((v, index) => (
        v == ' ' ?
          <span className="letter" key={index}>&nbsp;</span> :
          <span className="letter" key={index}>{v}</span>
      ))}
      </div>
    );
  }
}

export class ByWord extends Component {
  state = {
    animated: false
  }

  componentDidMount() {
    var animation = anime.timeline({ loop: false });
    setTimeout(() => {
      animation
        .add({
          targets: `.by-word:not(.animated) .word`,
          translateY: ["1.1em", 0],
          translateX: ["0em", 0],
          translateZ: 0,
          duration: 750,
          easing: "easeOutExpo",
          opacity: 1,
          delay: (el, i) => 50 * i
        });
      this.setState({
        animated: true
      });
    }, this.props.delay || 0);
  }

  render() {
    return (
      <div className={this.state.animated ? "by-word animated" : "by-word"}>
        {this.props.text.split(" ").map((v, index) => (
          <span className="word" key={index}>{v}&nbsp;</span>
        ))}
      </div>
    );
  }
}
