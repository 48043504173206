import React, { useEffect, useState } from 'react';
import ProgressRing from '../progressring/ProgressRing';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import {
  TeamMemberContainer,
  TeamMemberPicture,
  TeamMemberInfo,
  TeamMemberName,
  TeamMemberDetails,
  TeamMemberEmail,
  TeamMemberEmissions,
  OffsetActivityDate,
  OffsetActivityChecked,
  TeamMemberOptions,
  TeamMemberMenu,
  TeamMemberMenuItem,
  TeamMemberAdmin,
  OffsetProgress,
  OffsetProgressRing,
  OffsetProgressStat,
  OffsetProgressCopy
} from './OffsetStyles';


class TeamMember extends React.Component {

  state = {
    show: false,
    showMenu: false,
    emissions: 0,
    progress: 0
  }

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.checkParse();

    setTimeout(() => {
      this.setState({
        show: true
      })
    }, 100);

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  checkParse() {
    if (!('is_parsing' in this.props.user) || this.props.user.is_parsing) {
      this.setState({
        progress: (this.props.user['total_messages'] - this.props.user['remaining_messages'])/this.props.user['total_messages'] * 100
      });
      setTimeout(() => {
        this.props.getUserData();
        this.checkParse();
      }, 1000);
    }
  }

  handleClickOutside(event) {
    if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {
      if (this.state.showMenu) {
        this.setState({showMenu: false})
      }
    }
  }

  getDate() {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const date = new Date(this.props.activity.start)
    return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear()
  }

  makeAdmin(e) {
    e.stopPropagation();
    this.props.user.admin = true;
    this.props.onMakeAdmin();
    this.setState({showMenu: false});
  }

  removeAdmin(e) {
    e.stopPropagation();
    this.props.user.admin = false;
    this.props.onRemoveAdmin();
    this.setState({showMenu: false});
  }

  removeMember(e) {
    e.stopPropagation();
    this.props.onRemoveMember();
    this.setState({
      show: false
    });
  }

  render() {
    return (
        <TeamMemberContainer className={(this.state.show ? "show": "") + (this.state.showMenu ? " showMenu" : "")} onClick={this.props.onClick.bind(this)}>
          <TeamMemberPicture style={{backgroundImage: `url(${this.props.user.picture})`}}>
            <OffsetProgressRing className={this.props.user.is_parsing ? 'show picture' : 'picture'}>
              <ProgressRing
                radius={ 32 }
                stroke={ 4 }
                progress={ this.state.progress }
                color="#684DB9"
              />
            </OffsetProgressRing>
          </TeamMemberPicture>

          <TeamMemberInfo>
            <TeamMemberName>
              {this.props.user.name}
            </TeamMemberName>
            <TeamMemberDetails>
              <TeamMemberEmissions>
                {Math.ceil(this.props.user.emissions).toLocaleString()}kg
              </TeamMemberEmissions>
            </TeamMemberDetails>
          </TeamMemberInfo>
          {!this.props.self && !this.props.user.admin &&
            <TeamMemberOptions onClick={(e) => {e.stopPropagation(); this.setState({showMenu: !this.state.showMenu})}}>
              <TeamMemberMenu ref={this.wrapperRef}>
                <TeamMemberMenuItem onClick={this.makeAdmin.bind(this)}>
                  Make Admin
                </TeamMemberMenuItem>
                <TeamMemberMenuItem className="remove" onClick={this.removeMember.bind(this)}>
                  Remove Member
                </TeamMemberMenuItem>
              </TeamMemberMenu>
            </TeamMemberOptions>
          }
          {this.props.user.admin &&
            <TeamMemberAdmin onClick={(e) => {e.stopPropagation(); this.setState({showMenu: !this.state.showMenu})}}>
              {this.props.admins > 1 &&
                <TeamMemberMenu ref={this.wrapperRef}>
                  <TeamMemberMenuItem onClick={this.removeAdmin.bind(this)}>
                    Remove Admin
                  </TeamMemberMenuItem>
                </TeamMemberMenu>
              }
            </TeamMemberAdmin>
          }
        </TeamMemberContainer>
      )
    }
}

export default TeamMember;
