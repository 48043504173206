  import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { fonts, fontWeights, H1, h1, H2, h2, H3, H4, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import type from '../../assets/images/logos/type.svg';
import appstore from '../../assets/images/appstore.svg';
import downArrow from '../../assets/images/down.svg';
import Blob from '../blob/Blob'
import AnimeText from '../text/animetext';
import ReactPlayer from 'react-player';


export const HeroContainer = styled.div`

`;

export const HeroOuter = styled.div`
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  background: ${colors.light};

  @media ${breakpoints.iPhone} {
    margin-bottom: 0;
  }
`;

export const HeroFixed = styled.div`
  position: absolute;
  width: 100%;
  height: 80vh;
  top: 0;
  left: 0;
  margin-top: 80px;

  &.fixed {
    margin-top: 0;
    position: fixed;
  }

  @media ${breakpoints.iPhone} {
    margin-top: 0;
    &.fixed {
      position: relative;
    }
  }
`;

export const HeroContentContainer = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: height 0.3s ease;
  background: ${gradients.soft};
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
`;

export const HeroMask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.light};
  mix-blend-mode: lighten;

  @media ${breakpoints.iPhone} {
    display: none;
  }
`;

export const HeroMaskInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
  overflow: hidden;
  background: black;
  transition: all 0.3s ease;

  ${HeroOuter}.scrolled & {
      border-radius: 32px;
      width: calc(100% - 160px);
      height: calc(100% - 160px);

      @media ${breakpoints.iPadW} {
        width: calc(100% - 128px);
        height: calc(100% - 128px);
      }
  }
`;

export const HeroVideoBackground = styled(ReactPlayer)`
  video {
    min-width: 100%;
    min-height: 100%;
    width: auto !important;
    height: auto !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    overflow: hidden;
  }

  ${HeroContainer}.onload-appear & {
    opacity: 0;
  }
  ${HeroContainer}.onload-appear-done & {
    opacity: 1;
  }

  &.mobile {
    display: none;
    video {
      width: 100% !important;
      min-width: 100% !important;

      @media ${breakpoints.iPhone8} {
        width: 100% !important;
        height: auto !important;
        min-height: 100%;
      }
    }
  }

  @media ${breakpoints.iPhone} {
    &.desktop {
      display: none;
    }

    &.mobile {
      display: block;
    }
  }
`;

export const HeroContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 65%;
  max-width: 1200px;

  @media ${breakpoints.laptop} {
    width: calc(100% - 240px);
  }

  @media ${breakpoints.iPadW} {
    width: calc(100% - 160px);
  }

  @media ${breakpoints.iPhone} {
    width: 100%;
  }
`;

export const HeroType = styled.div`
  background-image: url(${type});
  height: 40px;
  width: 100px;
  margin-top: 8px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity .3s ease, filter 1s ease;
  transition-delay: 0.4s;

  ${HeroContainer}.onload-appear & {
    opacity: 0;
    filter: blur(4px);
  }
  ${HeroContainer}.onload-appear-done & {
    opacity: 1;
    filter: blur(0);
  }

  @media ${breakpoints.iPhone} {
    width: 100px;
    height: 40px;
  }
`;


export const HeroStatement = styled.div`
 div {
  ${h1}
  font-size: 48px;
  color: white;
  margin: 0;
  margin-bottom: 16px;
  transition: opacity .3s ease, filter 1s ease;
  transition-delay: 0.6s;

  span {
    background: linear-gradient(rgba(108, 82, 251, 1), rgba(108, 82, 251, 0.5));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media ${breakpoints.iPadW} {
    ${h2};
  }

  @media ${breakpoints.iPhone} {
    ${h2}
  }
}

  padding-bottom: 10px;

  ${HeroContainer}.onload-appear & {
    opacity: 0;
    filter: blur(4px);
  }
  ${HeroContainer}.onload-appear-done & {
    opacity: 1;
    filter: blur(0);
  }

  @media ${breakpoints.iPhone} {
    text-align: center;
    padding: 0 40px;
  }
`;

export const HeroIntro = styled(H4)`
  font-size: 20px;
  line-height: 1.4em;
  color: white;
  transition: all .6s ease, filter 1s ease, transform 0.6s ease;
  transition-delay: 0.9s;
  margin: 0;
  max-width: 390px;
  text-align: left;
  opacity: 0;
  position: relative;
  transform: translate(0, 10%);
  background: linear-gradient(rgba(108, 82, 251, 0.75), rgba(108, 82, 251, 0.35));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  ${HeroContainer}.onload-appear & {
    opacity: 0;
    transform: translate(0, 10%);
  }
  ${HeroContainer}.onload-appear-done & {
    opacity: 1;
    filter: blur(0);
    transform: translate(0, 0);

  }

  @media ${breakpoints.iPhone} {
    display: none;
  }
`;


export const HeroAppstore = styled.div`
  background-image: url(${appstore});
  width: 150px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;

  transition: opacity .3s ease, filter 1s ease;
  transition-delay: 0.9s;
  ${HeroContainer}.onload-appear & {
    opacity: 0;
    filter: blur(4px);
  }
  ${HeroContainer}.onload-appear-done & {
    opacity: 1;
    filter: blur(0);
  }

  &:after {
    content: "COMING SOON";
    font-size: 12px;
    font-weight: ${fontWeights.bold};
    position: absolute;
    top: 100%;
    color: white;
    text-align: center;
    width: 100%;
    padding-top: 8px;
    letter-spacing: 0.125em;
  }
`;

export const HeroArrow = styled.div`
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 30px;
  background: ${colors.light};
  width: 60px;
  height: 60px;
  box-shadow: 0 2px 5px 1px rgba(0, 0, 0, .08);
  opacity: 0.65;
  transition: opacity .3s ease, bottom .3s ease;
  cursor: pointer;

  &:after {
    content: "";
    background-image: url(${downArrow});
    width: 24px;
    height: 24px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${HeroOuter}.scrolled & {
    opacity: 0;
  }

  &:hover {
    bottom: 32px;
    opacity: 1;
  }

  @media ${breakpoints.iPhone} {
    opacity: 1;
  }
`;
