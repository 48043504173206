import styled from 'styled-components';
import { colors, gradients } from '../../styles/Colors';
import { skewIn } from '../../styles/Animations';
import { fonts, fontWeights, H2, H3, H4, H5, P } from '../../styles/Typography';
import { breakpoints } from '../../styles/Breakpoints';
import illo from '../../assets/images/intro-illo.png';
import { Parallax } from 'react-scroll-parallax';
import { Link } from 'react-router-dom';
import ReactPlayer from 'react-player';
import forward from '../../assets/images/icons/forward-purple.svg';


export const IntroContainer = styled.div`
  width: 100%;
  overflow: hidden;
  transition: opacity .3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  perspective: 1000px;

  @media ${breakpoints.wide} {
    padding: 80px 0;
  }

  @media ${breakpoints.iPadW} {
    padding: 64px 0;
  }

  @media ${breakpoints.iPhone} {
    padding: 32px;
    width: auto;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(253, 250, 255, 1), rgba(253, 250, 255, .8));
    transition: opacity 1s ease;
    pointer-events: none;
  }

  &.appear {
    &:after {
      opacity: 0;
    }
  }
`;

export const IntroContent = styled.div`
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: 1s;

  @media ${breakpoints.laptop} {
    max-width: initial;
    margin: 0 80px;
  }

  @media ${breakpoints.iPadW} {
    margin: 0 64px;
  }

  @media ${breakpoints.iPhone} {
    flex-direction: column;
    margin: 0;
  }

  ${IntroContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const IntroLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${breakpoints.iPadW} {
    width: 60%;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const IntroRight = styled.div`
  width: 50%;

  @media ${breakpoints.iPadW} {
    width: 40%;
  }

  @media ${breakpoints.iPhone} {
    width: auto;
  }
`;

export const IntroAssetContainer = styled.div`
  width: 100%;
  padding-top: 66%;
  border-radius: 32px;
  position: relative;
  overflow: hidden;
  perspective: 1000px;
`;

export const IntroVideo = styled(ReactPlayer)`

  video {
    position: absolute;
    height: 120% !important;
    width: auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const IntroParallax = styled(Parallax)`
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;

    .parallax-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
`;

export const IntroTitleContainer = styled.div`

`;


export const IntroTitle = styled(H2)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  text-align: center;
  max-width: 900px;

  /* opacity: 0; */
  transform: translate(0, 10%);
  transition: opacity 1s ease, transform 1s ease;
  margin: 32px 0;

  ${IntroContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPadW} {
    margin-top: 0;
    margin-bottom: 16px;
  }

  @media ${breakpoints.iPhone} {
    text-align: center;
    margin: 32px 0;
  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;

  ${IntroContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const IntroCopy = styled(P)`
  color: ${colors.midgray};
  margin: auto;
  text-align: center;
  max-width: 800px;

  ${IntroContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }

  @media ${breakpoints.iPhone} {

  }

  opacity: 0;
  transform: translate(0, 20px);
  transition: opacity 1s ease, transform 1s ease;
  transition-delay: .2s;

  ${IntroContainer}.appear & {
    opacity: 1;
    transform: translate(0, 0);
  }
`;

export const IntroButton = styled(Link)`
  background: ${gradients.heavy};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  text-align: center;
  font-weight: ${fontWeights.bold};
  font-size: 16px;
  border: 2px solid ${colors.purple};
  border-radius: 36px;
  padding: 16px 24px;
  margin: auto;
  display: flex;
  margin-top: 32px;

  &:after {
    content: "";
    background-image: url(${forward});
    width: 16px;
    height: 16px;
    margin-left: 8px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
  }
`;
